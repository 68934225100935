<template>
  <v-app :style="colorVapp">
    <!-- @advancesearch="fn_advancesearch" -->
    <onebox_toolbar
      :aspectSearch="aspectData"
      :quicksearch="true"
      :parentfolder="parentfolder"
      :permissionfolder="permission_currentfolder"
      :departmentid="''"
      :heardertextsearch="heardertextsearch"
      @countoffset="fn_newsearch()"
      @search_advanced_new="fn_advancesearch_new"
      @fulltextsearch="fulltextsearch"
      @inputdata="filteredItems"
      @loadfile="fn_loadfileandfolder_scrolling_child(),fn_reload()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
      @open_file_office="fn_open_file_office_myfile"
      @open_folder="fn_open_folder_myfile"
    ></onebox_toolbar>
    <div v-if="resolutionScreen < 500">
      <floatingbutton
        :departmentid="departmentid"
        @loadfile="loadstorage(), fn_loadfileandfolder_scrolling_child()"
      />
    </div>
    <v-content>
      <!-- <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay> -->
      <!-- Add loaderprocress -->
      <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular
          indeterminate
          size="64"
          :color="color.theme"></v-progress-circular>
        <br />
        <br />
        <!-- <span :style="colorProgress_biz">&nbsp; &nbsp; loading</span> -->
      </v-overlay>
      <!-- /// -->
      <v-overlay :value="loaddataprogress_print" z-index="2" color="black" dark>
        <v-progress-circular
          indeterminate
          size="64"
          color="white"
        ></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-overlay :value="processloader_ocr" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress">loading</span>
        </v-progress-circular>
      </v-overlay>
      <v-card class="elevation-0">
        <!-- Header Page -->
        <v-list nav :color="color.BG" v-if="resolutionScreen >= 500">
          <v-list-item class="text-left" v-if="heardertextsearch === false">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">cloud</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pt-1 pb-1">
                <v-btn
                  rounded
                  text
                  @click.stop="fn_loadfileandfolder_scrolling_child()"
                >
                  <span :style="headerPage">{{ $t("toolbar.mydrive") }}</span>
                </v-btn>
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action
              class="pa-0 text-center"
            >
              <v-layout>
                <v-flex xs7 lg7 class="text-right" v-if="resolutionScreen < 500 ? false : true">
                <v-chip
                  class="ma-2"
                  :color="color.theme"
                  :text-color="color.chipText"
                >
                  <v-icon left>folder</v-icon>
                  {{ totaldata["totalfolder"] }}
                  {{
                    totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder")
                  }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  :color="color.theme"
                  :text-color="color.chipText"
                >
                  <v-icon left>mdi-file</v-icon>
                  {{ totaldata["totalfile"] }}
                  {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                </v-chip>
                <v-btn
                  @mouseover="show_grid = true"
                  @mouseleave="show_grid = false"
                  @click="
                    resetFile(),
                      changepage(),
                      changeLocalStorage('false'),
                      (statusmutipledownload = false)
                  "
                  :text="!changeUI"
                  fab
                  small
                  v-if="resolutionScreen < 500 ? false : true"
                >
                  <v-icon :color="color.theme">mdi-view-grid-outline</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-60px;"
                    :value="show_grid"
                    :content="$t('balloon.grid')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index:10000;bottom:-45px;left:-25px;"
                    :value="show_grid"
                    :content="$t('balloon.grid')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  @mouseover="show_list = true"
                  @mouseleave="show_list = false"
                  @click="resetFile(), changepage(), changeLocalStorage('true')"
                  :text="changeUI"
                  fab
                  small
                  v-if="resolutionScreen < 500 ? false : true"
                >
                  <v-icon :color="color.theme">mdi-view-list</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-75px;"
                    :value="show_list"
                    :content="$t('balloon.list')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index:10000;bottom:-45px;left:-25px;"
                    :value="show_list"
                    :content="$t('balloon.list')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                </v-flex>
              </v-layout>
              <!-- Sort File ใหม่ -->
                  <v-layout
                    row
                    wrap
                    class="pl-3 pr-0"
                    v-if="resolutionScreen >= 500"
                  >
                      <v-list nav :color="color.BG" class="py-0">
                        <v-list-item>
                          <v-layout>
                            <v-flex class="text-right" lg12>
                              <v-menu offset-y rounded="lg">
                                <template v-slot:activator="{ attrs, on }">
                                  <v-btn
                                    small
                                    rounded
                                    class="white--text"
                                    :color="color.theme"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon small>mdi-sort</v-icon>
                                    {{ $t("sorting.sort") }}:
                                    <div class="font-weight-bold">
                                      {{
                                        $t(
                                          sorting["sort"].find(
                                            (x) => x.id === sort_by
                                          ).text
                                        )
                                      }},
                                      {{
                                        $t(
                                          sorting["direction"].find(
                                            (x) => x.id === direction
                                          ).text
                                        )
                                      }}
                                    </div>
                                  </v-btn>
                                </template>
                                <v-list dense nav>
                                  <v-list-item-group
                                    v-model="sort_by"
                                    active-class="border"
                                    :color="color.theme"
                                    mandatory
                                  >
                                    <template
                                      v-for="(item, i) in sorting['sort']"
                                    >
                                      <v-list-item
                                        :key="`item-${i}`"
                                        :value="item.id"
                                        @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                      >
                                        <template>
                                          <v-list-item-content>
                                            <v-list-item-title>{{
                                              $t(item.text)
                                            }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-list-item>
                                    </template>
                                  </v-list-item-group>
                                  <v-divider class="mt-2" />
                                  <v-list-item-group
                                    class="mt-4"
                                    v-model="direction"
                                    active-class="border"
                                    :color="color.theme"
                                    mandatory
                                  >
                                    <template
                                      v-for="(item, i) in sorting['direction']"
                                    >
                                      <v-list-item
                                        :key="`item-${i}`"
                                        :value="item.id"
                                        @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                      >
                                        <template>
                                          <v-list-item-content>
                                            <v-list-item-title>{{
                                              $t(item.text)
                                            }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-list-item>
                                    </template>
                                  </v-list-item-group>
                                </v-list>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-list-item>
                      </v-list>
                  </v-layout>
              <!-- Map drive -->
                  <!-- <v-layout
                    v-if="resolutionScreen >= 500 && this.dataAccountActive.type === 'Business' && feature_mapdrive === true"
                    class="pl-2 pr-2"
                  >
                  <v-btn rounded size="x-small" :color="color.theme" class="white--text"  @click="opendialogmapdrive = true">Map Drive</v-btn>
                  </v-layout> -->

            </v-list-item-action>
          </v-list-item>
          <v-list-item class="text-left" v-else>
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-magnify</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("tablefile.searchtext") }}</span
                >
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action class="pa-0 text-center">
              <v-layout>
                <v-btn
                  @click="
                    resetFile(), changepage(), changeLocalStorage('false')
                  "
                  :text="!changeUI"
                  fab
                  small
                  v-if="resolutionScreen < 500 ? false : true"
                >
                  <v-icon :color="color.theme">mdi-view-grid-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="resetFile(), changepage(), changeLocalStorage('true')"
                  :text="changeUI"
                  fab
                  small
                  v-if="resolutionScreen < 500 ? false : true"
                >
                  <v-icon :color="color.theme">mdi-view-list</v-icon>
                </v-btn>
              </v-layout>
              <v-layout >
                <v-chip class="mt-4 white--text" :color="color.theme" >{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
              </v-layout>
            </v-list-item-action>
          </v-list-item>
          <!-- Add Animation menu here -->
          <v-list-item  
            v-if="show_menumanage && changeUI === false" 
            style="overflow: visible; padding-right: 1.15rem;"  
            class="mt-2 mb-n2"
          >
            <v-layout column :class="animationlistmenu_v2" :style="headeranimationmenu">
              <v-layout justify-space-between>
                <v-flex style="max-width: max-content;">
                  <div :class="animationlistmenu">
                    <!-- ใส่ menu  -->
                    <v-btn
                      @mouseover="show_load = true"
                      @mouseleave="show_load = false"
                      fab
                      small
                      outlined
                      :color="color.theme"
                      class="ma-1 pa-0"
                      @click="multipledownload()">
                        <v-icon>mdi-download-outline</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-42px;"
                          :value="show_load"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-45px;"
                          :value="show_load"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"></v-badge>
                    </v-btn>
                    <v-btn
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        outlined
                        :color="color.theme"
                        class="ma-1 pa-0"
                        @click="fn_printPDF()"
                      >
                        <v-icon>print</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-40px;"
                          :value="show_print"
                          :content="$t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-42px;"
                          :value="show_print"
                          :content="$t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>
                    <v-btn
                        outlined
                        :color="color.theme"
                        fab
                        small
                        class="ma-1 pa-0"
                        @click="
                          setmultiupdate(filemultipledownload, 'S')
                        "
                        @mouseover="show_star = true"
                        @mouseleave="show_star = false"
                      >
                        <v-icon dark>star_border</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-45px;"
                          :value="show_star"
                          :content="$t('balloon.star')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-43px;"
                          :value="show_star"
                          :content="$t('balloon.star')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>

                    <v-btn
                        @mouseover="show_delete = true"
                        @mouseleave="show_delete = false"
                        outlined
                        fab
                        small
                        :color="color.theme"
                        class="ma-1 pa-0"
                        @click="multideletetotrash()"
                      >
                        <v-icon>delete_outline</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-22px;"
                          :value="show_delete"
                          :content="$t('balloon.delete')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-35px;"
                          :value="show_delete"
                          :content="$t('balloon.delete')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>
                    <v-btn
                        @mouseover="show_move = true"
                        @mouseleave="show_move = false"
                        outlined
                        fab
                        small
                        class="ma-1 pa-0"
                        :color="color.theme"
                        @click="appsbarmultimovefile($event)"
                      >
                        <v-icon>mdi-folder-swap-outline</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-60px;"
                          :value="show_move"
                          :content="$t('balloon.move')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-65px;"
                          :value="show_move"
                          :content="$t('balloon.move')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>
                    <v-btn
                        @mouseover="show_addhashtag = true"
                        @mouseleave="show_addhashtag = false"
                        outlined
                        fab
                        small
                        class="ma-1 pa-0"
                        :color="color.theme"
                        @click="apphashtag()"
                      >
                        <v-icon>mdi-pen-plus</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-45px;"
                          :value="show_addhashtag"
                          :content="$t('balloon.addhashtag')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-65px;"
                          :value="show_addhashtag"
                          :content="$t('balloon.addhashtag')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>
                    <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        color="error"
                        outlined
                        fab
                        small
                        class="ma-1 pa-0"
                        @click="
                          (statusmutipledownload = false),
                          (isCheckbox_sep_header = false),
                            clearmultipledownload(),
                            (show_menumanage = false),
                            (close_header = true),
                            (show_clear = false)
                        "
                      >
                        <v-icon>clear</v-icon>
                        <v-badge
                          v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-20px;"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge
                          v-else
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex style="max-width: min-content;">
                  <v-select
                    prepend-inner-icon="sort"
                    hide-details
                    outlined
                    solo
                    dense
                    rounded
                    v-model="size"
                    :items="listsize"
                    class="text-center pt-2 pr-10"
                    @change="selectedLimit()"
                  >
                    <template v-slot:item="{ item }">
                      <div class="text-center">
                        <span style="font-size: 17px;">{{ item }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="text-center">
                        <span style="font-size: 17px;">{{ item }}</span>
                      </div>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            <v-data-table
                :headers="headers"
                :hide-default-footer="true"
                class="custom-table"
                :style="headerdatatable"
                :items="[]"
                item-selected
                :show-select="statusmutipledownload"
              >
                <template v-slot:[`header.data-table-select`]="{ on }">
                <div>
                  <v-checkbox
                    hide-details
                    style="position: relative; bottom: 10px;"
                    v-model="checkbox_value"
                    :indeterminate="indeterminate_custom_checkbox"
                    v-on="on"
                    @change="Checkbox_custom_header"
                    color="primary"
                />
                </div>
                </template>
                <template v-slot:[`header.file_type`]="{ header }">
                  <span :style="headerTable">
                    {{ header.text }}
                  </span>
                </template>
                <template v-slot:[`header.file_name`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_owner`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_size`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_location`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'location'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_lastdtm`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                 <template v-slot:[`header.file_status`]="{ header }">
                  <span :style="headerTable">
                    {{ $t(header.text) }}
                  </span>
                </template>
                <template slot="no-data">
                  
                </template>
            </v-data-table>
            </v-layout>
          </v-list-item>
          <!-- ///// -->
        </v-list>
        <!-- Header Page xs -->
        <v-list nav :color="color.BG" class="mb-n2" v-else>
          <v-list-item class="text-left" v-if="heardertextsearch === true">
            <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center">
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-magnify</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <span :style="headerPageMobile">&nbsp;{{ $t("tablefile.searchtext") }}</span>
              </v-list-item-title>
            </v-chip>  
          </v-list-item>
          <v-list-item class="text-left" v-else>
            <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center">
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">cloud</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <span :style="headerPageMobile">{{ $t("toolbar.mydrive") }}</span>
              </v-list-item-title>
            </v-chip>
          </v-list-item>
        </v-list>
        <!-- <dialog_filter
          :aspect="aspect"
        ></dialog_filter>-->

        <!-- Sort File -->
        <!-- <v-list nav :color="color.BG" class="py-0">
          <v-list-item>
            <v-layout>                
              <v-flex class="text-right" lg12>
                <v-menu offset-y rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      text 
                      rounded
                      :color="color.theme" 
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("sorting.sort") }}: 
                      <div class="font-weight-bold">
                        {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                      </div>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item-group
                      v-model="sort_by"
                      active-class="border"
                      :color="color.theme"
                    >
                      <template v-for="(item, i) in sorting['sort']">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item.id"
                        >
                          <template>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                    <v-divider class="mt-2" />
                    <v-list-item-group
                      class="mt-4"
                      v-model="direction"
                      active-class="border"
                      :color="color.theme"
                    >
                      <template v-for="(item, i) in sorting['direction']">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item.id"
                        >
                          <template>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list> -->

        <!-- Container Page -->
        <!-- Add @scroll.passive  -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontainer_myfile" @scroll.passive="onScroll">
          <v-card-text class="pa-0">
            <v-container @contextmenu="show" fluid>
              <v-layout row wrap fill-height justify-center>
                <!-- ปิดหมุน -->
                <!-- <v-flex lg12  class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex> -->
                <!-- <v-layout
                  row
                  wrap
                  class="pl-3 pr-3"
                  v-if="resolutionScreen >= 500"
                > -->
                  <!-- <v-flex lg2 xs4> -->
                    <!-- ส่วนของตัว select size -->
                    <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                  <!-- </v-flex> -->
                  <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                  <!-- <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down">
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      :color="color.alertSort"
                      dark
                      v-show="clickSort"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon
                          :color="color.alertText"
                          v-if="multiSort === true"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          :color="color.alertText"
                          v-else-if="multiSort === false"
                          >mdi-chevron-down</v-icon
                        >
                        <span :style="colorSort" v-if="sortby === 'name'">{{
                          $t("Filter.name")
                        }}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'date'"
                          >{{ $t("Filter.date") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'size'"
                          >{{ $t("Filter.size") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'owner'"
                          >{{ $t("Filter.owner") }}</span
                        >
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="checkSort"
                        >
                          <v-icon :color="color.alertText"
                            >mdi-close-circle</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex> -->
                </v-layout>
                <!-- Sort File เก่า -->
                <!-- <v-flex lg12 xs12>
                  <v-layout
                    row
                    wrap
                    class="pl-3 pr-1"
                    v-if="resolutionScreen >= 500"
                  >
                    <v-flex lg8 xs8> </v-flex>
                    <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                      <v-list nav :color="color.BG" class="py-0">
                        <v-list-item>
                          <v-layout>
                            <v-flex class="text-right" lg12>
                              <v-menu offset-y rounded="lg">
                                <template v-slot:activator="{ attrs, on }">
                                  <v-btn
                                    small
                                    rounded
                                    class="white--text"
                                    :color="color.theme"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon small>mdi-sort</v-icon>
                                    {{ $t("sorting.sort") }}:
                                    <div class="font-weight-bold">
                                      {{
                                        $t(
                                          sorting["sort"].find(
                                            (x) => x.id === sort_by
                                          ).text
                                        )
                                      }},
                                      {{
                                        $t(
                                          sorting["direction"].find(
                                            (x) => x.id === direction
                                          ).text
                                        )
                                      }}
                                    </div>
                                  </v-btn>
                                </template>
                                <v-list dense nav>
                                  <v-list-item-group
                                    v-model="sort_by"
                                    active-class="border"
                                    :color="color.theme"
                                    mandatory
                                  >
                                    <template
                                      v-for="(item, i) in sorting['sort']"
                                    >
                                      <v-list-item
                                        :key="`item-${i}`"
                                        :value="item.id"
                                        @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                      >
                                        <template>
                                          <v-list-item-content>
                                            <v-list-item-title>{{
                                              $t(item.text)
                                            }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-list-item>
                                    </template>
                                  </v-list-item-group>
                                  <v-divider class="mt-2" />
                                  <v-list-item-group
                                    class="mt-4"
                                    v-model="direction"
                                    active-class="border"
                                    :color="color.theme"
                                    mandatory
                                  >
                                    <template
                                      v-for="(item, i) in sorting['direction']"
                                    >
                                      <v-list-item
                                        :key="`item-${i}`"
                                        :value="item.id"
                                        @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                      >
                                        <template>
                                          <v-list-item-content>
                                            <v-list-item-title>{{
                                              $t(item.text)
                                            }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-list-item>
                                    </template>
                                  </v-list-item-group>
                                </v-list>
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
                <v-flex lg12 class="ma-2">
                  <v-layout
                    v-if="resolutionScreen >= 500"
                    row
                    wrap
                    fill-height
                    class="pl-2 pr-2"
                  >
                    <!-- Multiple Download, Starred , Move, Delete  -->
                    <!-- Countfiles&Folders -->
                    <v-flex xs8 lg8 offset-lg4 offset-xs4 class="text-right">
                      <!-- OLD Countfiles -->
                      <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip> -->
                      <!-- New Countfiles by scrolling -->
                      <!--<v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ totaldata["totalfolder"] }} {{ totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder") }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ totaldata["totalfile"] }} {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                      </v-chip>-->
                    </v-flex>
                  </v-layout>
                  <!-- หน้าจอxs -->
                  <v-layout v-else row wrap class="mt-n2 mx-n6">
                    <!-- <v-flex xs8 lg8 class="text-right"> </v-flex> -->
                    <!-- <v-flex xs12 sm12 lg12 class="text-right">
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip>

                      New Countfiles by scrolling
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>folder</v-icon>
                        <span
                          >{{ totaldata["totalfolder"] }}
                          <span v-if="resolutionScreen >= 400">{{
                            totaldata["totalfolder"] > 1
                              ? $t("folders")
                              : $t("folder")
                          }}</span></span
                        >
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>mdi-file</v-icon>
                        <span
                          >{{ totaldata["totalfile"] }}
                          <span v-if="resolutionScreen >= 400">{{
                            totaldata["totalfile"] > 1
                              ? $t("folders")
                              : $t("folder")
                          }}</span></span
                        >
                      </v-chip>
                      <v-menu offset-y rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            small
                            rounded
                            class="white--text mr-2"
                            :color="color.theme"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small>mdi-sort</v-icon>
                            {{ $t("sorting.sort") }}:
                            <div class="font-weight-bold">
                              {{
                                $t(
                                  sorting["sort"].find((x) => x.id === sort_by)
                                    .text
                                )
                              }},
                              {{
                                $t(
                                  sorting["direction"].find(
                                    (x) => x.id === direction
                                  ).text
                                )
                              }}
                            </div>
                          </v-btn>
                        </template>
                        <v-list dense nav>
                          <v-list-item-group
                            v-model="sort_by"
                            active-class="border"
                            :color="color.theme"
                          >
                            <template v-for="(item, i) in sorting['sort']">
                              <v-list-item :key="`item-${i}`" :value="item.id">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t(item.text)
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                          <v-divider class="mt-2" />
                          <v-list-item-group
                            class="mt-4"
                            v-model="direction"
                            active-class="border"
                            :color="color.theme"
                          >
                            <template v-for="(item, i) in sorting['direction']">
                              <v-list-item :key="`item-${i}`" :value="item.id">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t(item.text)
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex> -->
                    <!-- Sort By -->
                    <v-flex xs3 class="text-left">
                      <v-menu offset-y rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn x-small rounded
                            :color="color.theme" 
                            v-bind="attrs"
                            v-on="on"
                            text
                            style="font-size:16px; letter-spacing: 0px;"
                            class="ml-3"
                          >
                            <span>{{ $t("sorting.sort") }} : </span>
                            <span class="font-weight-bold" :color="color.theme"> {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }} </span><v-icon size="20">{{ $t(sorting['direction'].find(x => x.id === direction).text) === $t('sorting.ascending') ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense nav :style="roundListSort">
                          <v-list-item-group
                            v-model="sort_by"
                            active-class="border"
                            :color="color.theme"
                            mandatory
                          >
                            <template v-for="(item, i) in sorting['sort']">
                              <v-list-item
                                :key="`item-${i}`"
                                :value="item.id"
                                @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                              >
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title :style="sort_by === item.id ? listOnBold : listNoBold" ><v-icon size="17" class="mr-2">{{item.icon}}</v-icon>{{ $t(item.text) }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                          <v-divider class="mt-2" />
                          <v-list-item-group
                            class="mt-4"
                            v-model="direction"
                            active-class="border"
                            :color="color.theme"
                            mandatory
                          >
                            <template v-for="(item, i) in sorting['direction']">
                              <v-list-item
                                :key="`item-${i}`"
                                :value="item.id"
                                @click="direction === item.id ? reloadSortByAndDirection() : ''"
                              >
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title :style="direction === item.id ? listOnBold : listNoBold">{{ $t(item.text) }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>
                    <v-flex xs9 class="text-right ml-n5" v-if="heardertextsearch === true">
                      <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                      <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip> -->
                      <!-- <v-btn fab small @click="backtopreventfolder()" class="ma-2" v-if="this.navigate.length > 1">
                        <v-icon :color="color.theme">keyboard_backspace</v-icon>
                      </v-btn> -->
                      <!-- New Countfiles by scrolling -->
                      <!-- <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                        <v-icon center size="18" class="mr-2">folder</v-icon>
                        <span style="font-weight: 700; font-size:16px;">{{ totaldata["totalfolder"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfolder"] > 1 ? $t('folders'): $t('folder')}}</span></span>
                      </v-chip>
                      <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                        <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                        <span style="font-weight: 700; font-size:16px;">{{ totaldata["totalfile"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfile"] > 1 ? $t('files'): $t('file')}}</span></span>
                      </v-chip> -->
                    </v-flex>
                    <!-- Total File&Folder -->
                    <v-flex xs9 class="text-right ml-n5" v-else>
                      <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                      </v-chip> -->
                      <!-- <v-btn fab small @click="backtopreventfolder()" class="ma-2" v-if="this.navigate.length > 1">
                        <v-icon :color="color.theme">keyboard_backspace</v-icon>
                      </v-btn> -->
                      <!-- New Countfiles by scrolling -->
                      <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                        <v-icon center size="18" class="mr-2">folder</v-icon>
                        <span style="font-weight: 700; font-size:16px;">{{ totaldata["totalfolder"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfolder"] > 1 ? $t('folders'): $t('folder')}}</span></span>
                      </v-chip>
                      <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                        <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                        <span style="font-weight: 700; font-size:16px;">{{ totaldata["totalfile"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfile"] > 1 ? $t('files'): $t('file')}}</span></span>
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 lg12 class="px-1 pb-4 pt-0">
                  <!-- ตรวจสอบขนาดหน้าจอ  -->
                  <div
                    v-if="
                      $vuetify.breakpoint.name === 'xl' ||
                        $vuetify.breakpoint.name === 'lg' ||
                        $vuetify.breakpoint.name === 'md'
                    "
                  >
                    <!-- ตรวจสอบว่า layout Grid หรือไม่ -->
                    <div v-if="changeUI === true">
                      <!-- โชว์ไฟล์ที่เปิดล่าสุด -->
                      <v-card
                        class="elevation-0"
                        :color="color.BG"
                        v-show="
                          filesLastView.length >= 1 &&
                            heardertextsearch === false
                        "
                      >
                        <v-card-title class="pb-2"
                          >{{ $t("recentfile") }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <br />
                        <template>
                          <v-layout align-center justify-center>
                            <v-flex lg11>
                              <swiper
                                class="swiper"
                                :options="swiperOption"
                                ref="mySwiper"
                              >
                                <swiper-slide
                                  v-for="(item, index) in filesLastView"
                                  :key="index"
                                >
                                  <v-card
                                    id="borderradius"
                                    class="pa-0 d-flex align-center"
                                    @dblclick="
                                      gotodirectory(
                                        item.file_id,
                                        item.file_type,
                                        item.file_department,
                                        item,
                                        filesLastView
                                      )
                                    "
                                    @contextmenu="rightclickfileandfolder($event, item)"
                                    hover
                                    outlined
                                    :title="item.file_name"
                                  >
                                    <v-layout>
                                      <v-col class="pa-0">
                                        <!-- <v-flex align-self-center> -->
                                        <v-img
                                          id="borderradius-img"
                                          :src="item.file_thumbnail"
                                          max-height="145px"
                                          height="145px"
                                          v-if="
                                            item.file_thumbnail != '' &&
                                              item.status_lock_data !== 'Y' &&
                                              item.editing_status !== 'Y'
                                          "
                                          ><v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <v-card-title
                                            class="white--text mt-12 pa-0"
                                          >
                                            <v-avatar size="56"
                                              ><v-icon
                                                :color="item.file_icon[1]"
                                                large
                                                >{{ item.file_icon[0] }}</v-icon
                                              >
                                            </v-avatar>
                                          </v-card-title>
                                        </v-img>
                                        <div
                                          v-else-if="
                                            item.status_lock_data === 'Y' ||
                                              item.editing_status === 'Y' ||
                                              item.editing_status === 'S'
                                          "
                                          id="borderradius-img"
                                        >
                                          <v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <v-card-title
                                            class="white--text pa-0"
                                          >
                                            <v-avatar class="mt-14" size="56"
                                              ><v-icon
                                                :color="item.file_icon[1]"
                                                large
                                                >{{ item.file_icon[0] }}</v-icon
                                              >
                                            </v-avatar>
                                            <div
                                              style="position: absolute; right: 0; left: 0; top: 25%; text-align: center;"
                                            >
                                              <v-icon
                                                color="#999999"
                                                size="50px"
                                                >mdi-lock-outline</v-icon
                                              >
                                            </div>
                                            <div
                                              style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                              v-show="
                                                item.editing_status === 'Y'
                                              "
                                            >
                                              <v-chip
                                                x-small
                                                color="primary"
                                                v-if="$t('default') === 'th'"
                                                >{{
                                                  item.last_update_name.name_th
                                                }}
                                                กำลังแก้ไข</v-chip
                                              >
                                              <v-chip
                                                x-small
                                                color="primary"
                                                v-else
                                                >{{
                                                  item.last_update_name.name_eng
                                                }}
                                                editting</v-chip
                                              >
                                            </div>
                                            <div
                                              style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                              v-show="
                                                item.editing_status === 'S'
                                              "
                                            >
                                              <v-chip
                                                x-small
                                                color="primary"
                                                v-if="$t('default') === 'th'"
                                                > กำลังซิงค์...</v-chip>
                                              <v-chip
                                                x-small
                                                color="primary"
                                                v-else
                                                >Syncing...</v-chip
                                              >
                                            </div>
                                          </v-card-title>
                                        </div>
                                        <div
                                          v-else
                                          class="text-center pa-0"
                                          id="borderradius-img"
                                        >
                                          <v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <!-- <v-card-title class="white--text pa-0"> -->
                                          <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                          <!-- <v-spacer></v-spacer>
                                            <v-chip
                                              x-small
                                              dark
                                              v-show="
                                                item.status_expired === '0' ||
                                                  item.status_expired === '1' ||
                                                  item.status_expired === '4'
                                              "
                                              :color="
                                                item.status_expired === '0'
                                                  ? 'red'
                                                  : item.status_expired === '1' || item.status_expired === '4'
                                                  ? '#fc9208e6'
                                                  : ''
                                              "
                                              >{{
                                                item.status_expired === "0"
                                                  ? $t("statusfileexpired.expired")
                                                  : item.status_expired === "1" || item.status_expired === "4"
                                                  ? $t("statusfileexpired.nearexpired")
                                                  : ""
                                              }}</v-chip
                                            >
                                             </v-card-title> -->
                                          <v-icon
                                            :color="item.file_thumbnail_icon[1]"
                                            size="100px"
                                            >{{
                                              item.file_thumbnail_icon[0]
                                            }}</v-icon
                                          >
                                        </div>
                                        <!-- </v-flex> -->
                                        <v-col
                                          id="borderradius-word"
                                          class="pt-3 col-auto"
                                          :style="word_grid"
                                        >
                                          <v-layout>
                                            <v-flex md9 lg9>
                                              <div
                                                style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;fontSize:16px;padding-left:8px"
                                                class="text-center"
                                              >
                                                <!-- <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon> -->
                                                <span
                                                  style="font-weight: 450; font-size:16px;"
                                                >
                                                  {{ item.file_name }}
                                                </span>
                                              </div>
                                            </v-flex>
                                            <v-flex
                                              lg3
                                              md3
                                              class="d-inline-flex justify-end"
                                            >
                                              <v-avatar
                                                title="ข้อมูลใหม่"
                                                v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                size="24"
                                                ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                              >
                                              <v-avatar
                                                title="แชร์ลิงก์"
                                                v-if="
                                                  item.file_status_sharelink ===
                                                    'Y' ||
                                                    item.file_status_sharelink ===
                                                      'P'
                                                "
                                                size="20"
                                                color="#F37958"
                                                ><v-icon dark small
                                                  >link</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="แชร์ไฟล์"
                                                v-if="
                                                  item.file_status_share === 'Y'
                                                "
                                                size="20"
                                                color="green"
                                                ><v-icon dark small
                                                  >group_add</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="อัพเดท"
                                                v-if="
                                                  item.status_updating === ''
                                                "
                                                size="20"
                                                color="primary"
                                                ><v-icon dark small
                                                  >check</v-icon
                                                ></v-avatar
                                              >

                                              <v-avatar
                                                title="ไฟล์หมดอายุ"
                                                v-if="
                                                  item.status_expired === '0'
                                                "
                                                size="20"
                                                color="#D50000"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="ไฟล์ใกล้หมดอายุ"
                                                v-if="
                                                  item.status_expired === '1' ||
                                                    item.status_expired === '4'
                                                "
                                                size="20"
                                                color="#fc9208e6"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="ตั้งค่าวันหมดอายุ"
                                                v-if="
                                                  item.status_expired === '2'
                                                "
                                                size="20"
                                                color="green"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                            </v-flex>
                                          </v-layout>
                                        </v-col>
                                      </v-col>
                                    </v-layout>
                                  </v-card>
                                </swiper-slide>
                                <div
                                  class="swiper-pagination"
                                  slot="pagination"
                                ></div>
                                <div
                                  class="swiper-button-prev"
                                  slot="button-prev"
                                ></div>
                                <div
                                  class="swiper-button-next"
                                  slot="button-next"
                                ></div>
                              </swiper>
                            </v-flex>
                          </v-layout>
                        </template>
                        <!-- <v-layout wrap row align-center justify-center>
                          <v-flex lg11>
                            <v-row>
                              <v-col>
                                <div style="width:100%;max-width:100%;overflow: hidden;">
                                  <slider ref="slider" :options="optionSlider">
                                    <slideritem v-for="(item, index) in filesLastView" :key="index" :style="styleSlider">
                                      <v-card class="elevation-0" 
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                                        hover
                                        outlined
                                        width="100%"
                                      >
                                        <v-layout justify-center column align-center wrap>
                                          <v-col>
                                            <v-img contain :src="item.file_thumbnail" max-height="75px" height="75px" v-if="item.file_thumbnail != ''"></v-img>
                                            <div v-else class="text-center">
                                              <v-icon :color="item.file_icon[1]" size="75px" >{{ item.file_icon[0] }}</v-icon>
                                            </div>
                                            <v-row class="pt-3">
                                              <v-flex  align-self-center >
                                                <div
                                                  style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;color:#000;fontSize:16px;padding-left:8px;"
                                                  class="justify flex-start"
                                                >
                                                  <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                                                  {{ item.file_name }}
                                                </div>
                                              </v-flex>
                                            </v-row>
                                          </v-col>
                                        </v-layout>
                                      </v-card>
                                    </slideritem>
                                  </slider>
                                </div>
                              </v-col>
                            </v-row>
                          </v-flex>
                        </v-layout> -->
                      </v-card>
                      <br />
                      <drag-selector
                        v-model="checkedList"
                        @change="fn_handleDragSelectorChange(checkedList)"
                        v-if="sort_by === 'size_file'"
                      >
                        <!-- แผนก / บริษัท -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="dataAccountActive['type'] === 'Business'"
                        >
                          <!-- <v-card-title class="pb-2"
                            >{{ $t("company") }}</v-card-title
                          ><v-divider></v-divider>
                          <br/> -->
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return item.file_status === 'M';
                                    }
                                  )"
                                  :key="index"
                                  cols="6"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center"
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <!-- <div>
                                        <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="
                                            item.file_status_sharelink === 'Y' ||
                                              item.file_status_share === 'Y' ||
                                              item.status_updating === ''
                                          "
                                        >
                                          <v-icon large :color="item.file_icon[1]">{{
                                            item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                          }}</v-icon>
                                        </v-badge>
                                        <v-icon v-else large :color="item.file_icon[1]">{{
                                          item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="selectColor(item.file_id, 'text')"
                                      >
                                        {{ item.file_name }}
                                      </div> -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                          <div class="text-center pa-0">
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-icon
                                              v-if="
                                                item.status_lock_data === 'Y'
                                              "
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder-lock</v-icon
                                            >
                                            <v-icon
                                              v-else
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                  >
                                                    {{ item.file_name }}
                                                  </span>
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- ไฟล์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfile'] !== 0"
                        >
                          <v-card-title class="pb-2">{{
                            totaldata["totalfile"] == 0 ? "" : $t("files")
                          }}</v-card-title>
                          <v-divider></v-divider>
                          <br />
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <!-- || item.type !== 'folder' -->
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return item.file_type !== 'folder';
                                    }
                                  )"
                                  :key="index"
                                  cols="6"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center"
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- Thumbnail กับ Icon -->
                                          <!-- ถ้าเปลี่ยนขนาด ต้องแก้ตำแหน่ง top ของติดดาวด้วย -->
                                          <!-- {{item.file_thumbnail}} -->
                                          <!-- v-if="item.file_thumbnail != '' && item.status_lock_data !== 'Y'" -->
                                          <v-img
                                            :src="item.file_thumbnail"
                                            max-height="145px"
                                            height="145px"
                                            id="borderradius-img"
                                            v-if="
                                              item.file_thumbnail != '' &&
                                                item.editing_status === 'N'
                                            "
                                            :style="selectCardBgColor(item)"
                                          >
                                            <v-app-bar
                                              dense
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-card-title
                                              class="white--text mt-12 pa-0"
                                            >
                                              <v-avatar size="56"
                                                ><v-icon
                                                  :color="item.file_icon[1]"
                                                  large
                                                  >{{
                                                    item.file_icon[0]
                                                  }}</v-icon
                                                >
                                              </v-avatar>
                                            </v-card-title>
                                            <v-spacer></v-spacer>
                                          </v-img>
                                          <!-- v-else-if="item.status_lock_data === 'Y'" -->
                                          <div
                                            v-else-if="
                                              item.status_lock_data === 'Y' ||
                                                item.editing_status === 'Y' ||
                                                item.editing_status === 'S'
                                            "
                                            id="borderradius-img"
                                          >
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-card-title
                                              class="white--text pa-0"
                                              :style="selectCardBgColor(item)"
                                            >
                                              <v-avatar class="mt-14" size="56"
                                                ><v-icon
                                                  :color="item.file_icon[1]"
                                                  large
                                                  >{{
                                                    item.file_icon[0]
                                                  }}</v-icon
                                                >
                                              </v-avatar>
                                              <div
                                                style="position: absolute; right: 0; left: 0; top: 25%; text-align: center;"
                                              >
                                                <v-icon
                                                  color="#999999"
                                                  size="50px"
                                                  >mdi-lock-outline</v-icon
                                                >
                                              </div>
                                              <div
                                                style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                                v-show="
                                                  item.editing_status === 'Y'
                                                "
                                              >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-if="$t('default') === 'th'"
                                                  >{{
                                                    item.last_update_name
                                                      .name_th
                                                  }}
                                                  กำลังแก้ไข</v-chip
                                                >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-else
                                                  >{{
                                                    item.last_update_name
                                                      .name_eng
                                                  }}
                                                  editting</v-chip
                                                >
                                              </div>
                                              <div
                                                style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                                v-show="
                                                  item.editing_status === 'S'
                                                "
                                              >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-if="$t('default') === 'th'"
                                                  > กำลังซิงค์...</v-chip
                                                >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-else
                                                  >Syncing...</v-chip
                                                >
                                              </div>
                                            </v-card-title>
                                          </div>
                                          <div
                                            v-else
                                            class="text-center pa-0"
                                            id="borderradius-img"
                                          >
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-icon
                                              :color="
                                                item.file_thumbnail_icon[1]
                                              "
                                              size="100px"
                                              :style="selectCardBgColor(item)"
                                              >{{
                                                item.file_thumbnail_icon[0]
                                              }}</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                              <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-badge> -->
                                              <!-- <div v-else> -->
                                              <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-flex> -->
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-if="item.status_hilightword === undefined"
                                                  >
                                                    {{ item.file_name }}
                                                  </span>
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                    v-html="fn_hilightWord(item.file_name)"
                                                  ></span>
                                                  <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                  <!-- <div> -->
                                                  <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                  <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                  <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                  <!-- </div> -->
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- โฟล์เดอร์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfolder'] > 0"
                        >
                          <v-card-title class="pb-2">{{
                            totaldata["total_folder"] == 0 ? "" : $t("folders")
                          }}</v-card-title>
                          <v-divider></v-divider>
                          <br />
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return (
                                        (item.file_type === 'folder' ||
                                          item.type === 'folder') &&
                                        item.file_status !== 'M'
                                      );
                                    }
                                  )"
                                  :key="index"
                                  cols="6"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center "
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <!-- @keyup.ctrl.67="test($event,item)" -->
                                      <!-- FILE ICON -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                          <div class="text-center pa-0 pb-5">
                                            <v-app-bar
                                              dense
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                dense
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                dense
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <!-- <v-card-title class="white--text mt-10 pa-0"> -->
                                            <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                            <!-- </v-card-title> -->

                                            <v-icon
                                              v-if="
                                                item.status_lock_data === 'Y'
                                              "
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder-lock</v-icon
                                            >
                                            <v-icon
                                              v-else
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >{{ item.file_icon[0] }}</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                              <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-badge> -->
                                              <!-- <div v-else> -->
                                              <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-flex> -->
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                  <span
                                                    v-if="item.status_hilightword === undefined"
                                                    style="font-weight: 450; font-size:16px;"
                                                  >
                                                    {{ item.file_name }}</span
                                                  >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                    v-html="fn_hilightWord(item.file_name)"
                                                  ></span>

                                                  <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                  <!-- <div> -->
                                                  <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                  <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                  <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                  <!-- </div> -->
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                      <!-- <v-spacer></v-spacer> -->
                                      <!-- icon lock -->
                                      <!-- <v-icon v-if="item.status_lock_folder === 'Y' || item.status_lock_data === 'Y'" color="error">lock</v-icon> -->
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>

                        <v-card
                          v-if="
                            rootfile.length === 0 &&
                              disablescrolling === true &&
                              loaddataprogress === false
                          "
                          class="pb-4 elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-center"
                                v-text="
                                  heardertextsearch === false
                                    ? $t('tablefile.empty')
                                    : $t('tablefile.emptysearch')
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </drag-selector>
                      <drag-selector
                        v-model="checkedList"
                        @change="fn_handleDragSelectorChange(checkedList)"
                        v-else
                      >
                        <!-- แผนก / บริษัท -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="dataAccountActive['type'] === 'Business'"
                        >
                          <!-- <v-card-title class="pb-2"
                            >{{ $t("company") }}</v-card-title
                          ><v-divider></v-divider>
                          <br/> -->
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return item.file_status === 'M';
                                    }
                                  )"
                                  :key="index"
                                  cols="6"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center"
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <!-- <div>
                                        <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="
                                            item.file_status_sharelink === 'Y' ||
                                              item.file_status_share === 'Y' ||
                                              item.status_updating === ''
                                          "
                                        >
                                          <v-icon large :color="item.file_icon[1]">{{
                                            item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                          }}</v-icon>
                                        </v-badge>
                                        <v-icon v-else large :color="item.file_icon[1]">{{
                                          item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="selectColor(item.file_id, 'text')"
                                      >
                                        {{ item.file_name }}
                                      </div> -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                          <div class="text-center pa-0">
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-icon
                                              v-if="
                                                item.status_lock_data === 'Y'
                                              "
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder-lock</v-icon
                                            >
                                            <v-icon
                                              v-else
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                  >
                                                    {{ item.file_name }}
                                                  </span>
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- โฟล์เดอร์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfolder'] > 0"
                        >
                          <v-card-title class="pb-2">{{
                            totaldata["total_folder"] == 0 ? "" : $t("folders")
                          }}</v-card-title>
                          <v-divider></v-divider>
                          <br />
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return (
                                        (item.file_type === 'folder' ||
                                          item.type === 'folder') &&
                                        item.file_status !== 'M'
                                      );
                                    }
                                  )"
                                  :key="index"
                                  cols="12"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center "
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <!-- @keyup.ctrl.67="test($event,item)" -->
                                      <!-- FILE ICON -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                          <div class="text-center pa-0 pb-0">
                                            <v-app-bar
                                              dense
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                dense
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                dense
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <!-- <v-card-title class="white--text mt-10 pa-0"> -->
                                            <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                            <!-- </v-card-title> -->

                                            <v-icon
                                              v-if="
                                                item.status_lock_data === 'Y'
                                              "
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >mdi-folder-lock</v-icon
                                            >
                                            <v-icon
                                              v-else
                                              :color="item.file_icon[1]"
                                              size="100px"
                                              >{{ item.file_icon[0] }}</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                              <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-badge> -->
                                              <!-- <div v-else> -->
                                              <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-flex> -->
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-if="item.status_hilightword === undefined"
                                                  >
                                                    {{ item.file_name }}</span
                                                  >
                                                  <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-html="fn_hilightWord(item.file_name)"
                                                    v-else
                                                  ></span>

                                                  <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                  <!-- <div> -->
                                                  <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                  <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                  <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                  <!-- </div> -->
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                      <!-- <v-spacer></v-spacer> -->
                                      <!-- icon lock -->
                                      <!-- <v-icon v-if="item.status_lock_folder === 'Y' || item.status_lock_data === 'Y'" color="error">lock</v-icon> -->
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- ไฟล์ -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                          v-if="totaldata['totalfile'] !== 0"
                        >
                          <v-card-title class="pb-2">{{
                            totaldata["totalfile"] == 0 ? "" : $t("files")
                          }}</v-card-title>
                          <v-divider></v-divider>
                          <br />
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <!-- || item.type !== 'folder' -->
                                <v-col
                                  v-for="(item, index) in rootfile.filter(
                                    (item) => {
                                      return item.file_type !== 'folder';
                                    }
                                  )"
                                  :key="index"
                                  cols="6"
                                  md="2"
                                  class="pa-2"
                                >
                                  <drag-selector-item
                                    class="drag-selector__item"
                                    :value="item"
                                  >
                                    <v-card
                                      id="borderradius"
                                      class="pa-0 d-flex align-center"
                                      @contextmenu="
                                        rightclickfileandfolder($event, item)
                                      "
                                      @dblclick="
                                        gotodirectory(
                                          item.file_id,
                                          item.file_type,
                                          item.file_department,
                                          item,
                                          rootfile
                                        )
                                      "
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="selectColor(item.file_id, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- v-on:keyup.ctrl="fn_checkkeyevent($event,item)" -->
                                      <v-layout
                                        justify-center
                                        column
                                        align-center
                                      >
                                        <v-col class="pa-0">
                                          <!-- Thumbnail กับ Icon -->
                                          <!-- ถ้าเปลี่ยนขนาด ต้องแก้ตำแหน่ง top ของติดดาวด้วย -->
                                          <!-- {{item.file_thumbnail}} -->
                                          <!-- v-if="item.file_thumbnail != '' && item.status_lock_data !== 'Y'" -->
                                          <v-img
                                            :src="item.file_thumbnail"
                                            max-height="145px"
                                            height="145px"
                                            id="borderradius-img"
                                            v-if="
                                              item.file_thumbnail != '' &&
                                                item.editing_status === 'N'
                                            "
                                            :style="selectCardBgColor(item)"
                                          >
                                            <v-app-bar
                                              dense
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-card-title
                                              class="white--text mt-12 pa-0"
                                            >
                                              <v-avatar size="50"
                                                ><v-icon
                                                  :color="item.file_icon[1]"
                                                  large
                                                  >{{
                                                    item.file_icon[0]
                                                  }}</v-icon
                                                >
                                              </v-avatar>
                                            </v-card-title>
                                            <v-spacer></v-spacer>
                                          </v-img>
                                          <!-- v-else-if="item.status_lock_data === 'Y'" -->
                                          <div
                                            v-else-if="
                                              item.status_lock_data === 'Y' ||
                                                item.editing_status === 'Y' ||
                                                item.editing_status === 'S'
                                            "
                                            id="borderradius-img"
                                          >
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="35px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-card-title
                                              class="white--text pa-0"
                                              :style="selectCardBgColor(item)"
                                            >
                                              <v-avatar class="mt-14" size="56"
                                                ><v-icon
                                                  :color="item.file_icon[1]"
                                                  large
                                                  >{{
                                                    item.file_icon[0]
                                                  }}</v-icon
                                                >
                                              </v-avatar>
                                              <div
                                                style="position: absolute; right: 0; left: 0; top: 25%; text-align: center;"
                                              >
                                                <v-icon
                                                  color="#999999"
                                                  size="50px"
                                                  >mdi-lock-outline</v-icon
                                                >
                                              </div>
                                              <div
                                                style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                                v-show="
                                                  item.editing_status === 'Y'
                                                "
                                              >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-if="$t('default') === 'th'"
                                                  >{{
                                                    item.last_update_name
                                                      .name_th
                                                  }}
                                                  กำลังแก้ไข</v-chip
                                                >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-else
                                                  >{{
                                                    item.last_update_name
                                                      .name_eng
                                                  }}
                                                  editting</v-chip
                                                >
                                              </div>
                                              <div
                                                style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%; text-align: center;"
                                                v-show="
                                                  item.editing_status === 'S'
                                                "
                                              >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-if="$t('default') === 'th'"
                                                  > กำลังซิงค์...</v-chip
                                                >
                                                <v-chip
                                                  x-small
                                                  color="primary"
                                                  v-else
                                                  >Syncing...</v-chip
                                                >
                                              </div>
                                            </v-card-title>
                                          </div>
                                          <div
                                            v-else
                                            class="text-center pa-0"
                                            id="borderradius-img"
                                          >
                                            <v-app-bar
                                              class="pa-0"
                                              flat
                                              color="rgba(0, 0, 0, 0)"
                                              height="45px"
                                              :disabled="disableByPackage"
                                              ><v-app-bar-nav-icon
                                                v-if="item.file_status === 'S'"
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'N',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  class="pa-0"
                                                  color="amber"
                                                  >star</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-app-bar-nav-icon
                                                v-else
                                                class="pa-0"
                                                color="white"
                                                @click="
                                                  setPermissionFileStar(
                                                    item,
                                                    'S',
                                                    'st'
                                                  ),
                                                    (display_grid = true)
                                                "
                                                :disabled="disableByPackage"
                                                ><v-icon
                                                  color="#D9D9D9"
                                                  class="pa-0"
                                                  >star_outline</v-icon
                                                ></v-app-bar-nav-icon
                                              >
                                              <v-spacer></v-spacer>

                                              <v-btn
                                                color="white"
                                                icon
                                                @click="
                                                  rightclickfileandfolder(
                                                    $event,
                                                    item
                                                  )
                                                "
                                              >
                                                <v-icon color="#D9D9D9"
                                                  >mdi-dots-vertical</v-icon
                                                >
                                              </v-btn>
                                            </v-app-bar>
                                            <v-icon
                                              :color="
                                                item.file_thumbnail_icon[1]
                                              "
                                              size="100px"
                                              :style="selectCardBgColor(item)"
                                              >{{
                                                item.file_thumbnail_icon[0]
                                              }}</v-icon
                                            >
                                          </div>

                                          <v-col
                                            id="borderradius-word"
                                            class="pt-3"
                                            :style="word_grid"
                                          >
                                            <v-layout>
                                              <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                              <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-badge> -->
                                              <!-- <div v-else> -->
                                              <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                              <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                              <!-- </div> -->
                                              <!-- </v-flex> -->
                                              <v-flex md9 lg9>
                                                <div
                                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                  :style="
                                                    selectColor(
                                                      item.file_id,
                                                      'text'
                                                    )
                                                  "
                                                >
                                                   <span
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-if="item.status_hilightword === undefined"
                                                  >
                                                    {{ item.file_name }}
                                                  </span>
                                                  <span
                                                    v-html="fn_hilightWord(item.file_name)"
                                                    style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                  >
                                                  </span>

                                                  <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                  <!-- <div> -->
                                                  <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                  <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                  <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                  <!-- </div> -->
                                                </div>
                                              </v-flex>
                                              <v-flex
                                                lg3
                                                md3
                                                :style="word_grid"
                                                class="d-inline-flex justify-end"
                                              >
                                                <v-avatar
                                                  title="ข้อมูลใหม่"
                                                  v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                  size="24"
                                                  ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ลิงก์"
                                                  v-if="
                                                    item.file_status_sharelink ===
                                                      'Y' ||
                                                      item.file_status_sharelink ===
                                                        'P'
                                                  "
                                                  size="20"
                                                  color="#F37958"
                                                  ><v-icon dark small
                                                    >link</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="แชร์ไฟล์"
                                                  v-if="
                                                    item.file_status_share ===
                                                      'Y'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >group_add</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="อัพเดท"
                                                  v-if="
                                                    item.status_updating === ''
                                                  "
                                                  size="20"
                                                  color="primary"
                                                  ><v-icon dark small
                                                    >check</v-icon
                                                  ></v-avatar
                                                >

                                                <v-avatar
                                                  title="ไฟล์หมดอายุ"
                                                  v-if="
                                                    item.status_expired === '0'
                                                  "
                                                  size="20"
                                                  color="#D50000"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ไฟล์ใกล้หมดอายุ"
                                                  v-if="
                                                    item.status_expired ===
                                                      '1' ||
                                                      item.status_expired ===
                                                        '4'
                                                  "
                                                  size="20"
                                                  color="#fc9208e6"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                                <v-avatar
                                                  title="ตั้งค่าวันหมดอายุ"
                                                  v-if="
                                                    item.status_expired === '2'
                                                  "
                                                  size="20"
                                                  color="green"
                                                  ><v-icon dark small
                                                    >mdi-timer</v-icon
                                                  ></v-avatar
                                                >
                                              </v-flex>
                                            </v-layout>
                                            <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                          </v-col>
                                        </v-col>
                                      </v-layout>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>

                        <v-card
                          v-if="
                            rootfile.length === 0 &&
                              disablescrolling === true &&
                              loaddataprogress === false
                          "
                          class="pb-4 elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="resetFile"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-center"
                                v-text="
                                  heardertextsearch === false
                                    ? $t('tablefile.empty')
                                    : $t('tablefile.emptysearch')
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </drag-selector>
                    </div>
                    <!-- ตรวจสอบว่า layout table -->
                    <div v-else class="pt-0">
                      <!-- ไฟล์ที่เปิดล่าสุด -->
                      <v-card
                        class="elevation-0 pt-0"
                        :color="color.BG"
                        v-show="
                          filesLastView.length >= 1 &&
                            heardertextsearch === false
                        "
                      >
                        <v-card-title class="pb-2 pt-0"
                          >{{ $t("recentfile") }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <br />
                        <template>
                          <v-layout align-center justify-center>
                            <v-flex lg12>
                              <swiper
                                class="swiper"
                                :options="swiperOption"
                                ref="mySwiper"
                              >
                                <swiper-slide
                                  v-for="(item, index) in filesLastView"
                                  :key="index"
                                >
                                  <v-card
                                    id="borderradius"
                                    class="pa-0 d-flex align-center"
                                    @dblclick="
                                      gotodirectory(
                                        item.file_id,
                                        item.file_type,
                                        item.file_department,
                                        item,
                                        filesLastView
                                      )
                                    "
                                    @contextmenu="rightclickfileandfolder($event, item)"
                                    hover
                                    outlined
                                    :title="item.file_name"
                                  >
                                    <v-layout>
                                      <v-col class="pa-0">
                                        <!-- <v-flex align-self-center> -->
                                        <v-img
                                          id="borderradius-img"
                                          :src="item.file_thumbnail"
                                          max-height="145px"
                                          height="145px"
                                          v-if="
                                            item.file_thumbnail != '' &&
                                              item.status_lock_data !== 'Y'
                                          "
                                          ><v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <v-card-title
                                            class="white--text mt-12 pa-0"
                                          >
                                            <v-avatar size="56"
                                              ><v-icon
                                                :color="item.file_icon[1]"
                                                large
                                                >{{ item.file_icon[0] }}</v-icon
                                              >
                                            </v-avatar>
                                          </v-card-title>
                                        </v-img>
                                        <div
                                          v-else-if="
                                            item.status_lock_data === 'Y'
                                          "
                                          id="borderradius-img"
                                        >
                                          <v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <v-card-title
                                            class="white--text pa-0"
                                          >
                                            <v-avatar class="mt-14" size="56"
                                              ><v-icon
                                                :color="item.file_icon[1]"
                                                large
                                                >{{ item.file_icon[0] }}</v-icon
                                              >
                                            </v-avatar>
                                            <div
                                              style="position: absolute;right: 80px;left: 00px;top: 55px;"
                                            >
                                              <v-icon
                                                class="ml-16 mb-7"
                                                color="#999999"
                                                size="50px"
                                                >mdi-lock-outline</v-icon
                                              >
                                            </div>
                                          </v-card-title>
                                        </div>
                                        <div
                                          v-else
                                          class="text-center pa-0"
                                          id="borderradius-img"
                                        >
                                          <v-app-bar
                                            class="pa-0"
                                            flat
                                            color="rgba(0, 0, 0, 0)"
                                            height="45px"
                                            :disabled="disableByPackage"
                                            ><v-app-bar-nav-icon
                                              v-if="item.file_status === 'S'"
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'N',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                class="pa-0"
                                                color="amber"
                                                >star</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-app-bar-nav-icon
                                              v-else
                                              class="pa-0"
                                              color="white"
                                              @click="
                                                setPermissionFileStar(
                                                  item,
                                                  'S',
                                                  'st'
                                                ),
                                                  (display_grid = true)
                                              "
                                              :disabled="disableByPackage"
                                              ><v-icon
                                                color="#D9D9D9"
                                                class="pa-0"
                                                >star_outline</v-icon
                                              ></v-app-bar-nav-icon
                                            >
                                            <v-spacer></v-spacer>

                                            <v-btn
                                              color="white"
                                              icon
                                              @click="
                                                rightclickfileandfolder(
                                                  $event,
                                                  item
                                                )
                                              "
                                            >
                                              <v-icon color="#D9D9D9"
                                                >mdi-dots-vertical</v-icon
                                              >
                                            </v-btn>
                                          </v-app-bar>
                                          <!-- <v-card-title class="white--text pa-0"> -->
                                          <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                          <!-- <v-spacer></v-spacer>
                                            <v-chip
                                              x-small
                                              dark
                                              v-show="
                                                item.status_expired === '0' ||
                                                  item.status_expired === '1' ||
                                                  item.status_expired === '4'
                                              "
                                              :color="
                                                item.status_expired === '0'
                                                  ? 'red'
                                                  : item.status_expired === '1' || item.status_expired === '4'
                                                  ? '#fc9208e6'
                                                  : ''
                                              "
                                              >{{
                                                item.status_expired === "0"
                                                  ? $t("statusfileexpired.expired")
                                                  : item.status_expired === "1" || item.status_expired === "4"
                                                  ? $t("statusfileexpired.nearexpired")
                                                  : ""
                                              }}</v-chip
                                            >
                                             </v-card-title> -->
                                          <v-icon
                                            :color="item.file_icon[1]"
                                            size="100px"
                                            >{{ item.file_icon[0] }}</v-icon
                                          >
                                        </div>
                                        <!-- </v-flex> -->
                                        <v-col
                                          id="borderradius-word"
                                          class="pt-3 col-auto"
                                          :style="word_grid"
                                        >
                                          <v-layout>
                                            <v-flex md9 lg9>
                                              <div
                                                style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;fontSize:16px;padding-left:8px"
                                                class="text-center"
                                              >
                                                <!-- <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon> -->
                                                <span
                                                  style="font-weight: 450; font-size:16px;"
                                                >
                                                  {{ item.file_name }}
                                                </span>
                                              </div>
                                            </v-flex>
                                            <v-flex
                                              lg3
                                              md3
                                              class="d-inline-flex justify-end"
                                            >
                                              <v-avatar
                                                title="ข้อมูลใหม่"
                                                v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                                                size="24"
                                                ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                              >
                                              <v-avatar
                                                title="แชร์ลิงก์"
                                                v-if="
                                                  item.file_status_sharelink ===
                                                    'Y' ||
                                                    item.file_status_sharelink ===
                                                      'P'
                                                "
                                                size="20"
                                                color="#F37958"
                                                ><v-icon dark small
                                                  >link</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="แชร์ไฟล์"
                                                v-if="
                                                  item.file_status_share === 'Y'
                                                "
                                                size="20"
                                                color="green"
                                                ><v-icon dark small
                                                  >group_add</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="อัพเดท"
                                                v-if="
                                                  item.status_updating === ''
                                                "
                                                size="20"
                                                color="primary"
                                                ><v-icon dark small
                                                  >check</v-icon
                                                ></v-avatar
                                              >

                                              <v-avatar
                                                title="ไฟล์หมดอายุ"
                                                v-if="
                                                  item.status_expired === '0'
                                                "
                                                size="20"
                                                color="#D50000"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="ไฟล์ใกล้หมดอายุ"
                                                v-if="
                                                  item.status_expired === '1' ||
                                                    item.status_expired === '4'
                                                "
                                                size="20"
                                                color="#fc9208e6"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                              <v-avatar
                                                title="ตั้งค่าวันหมดอายุ"
                                                v-if="
                                                  item.status_expired === '2'
                                                "
                                                size="20"
                                                color="green"
                                                ><v-icon dark small
                                                  >mdi-timer</v-icon
                                                ></v-avatar
                                              >
                                            </v-flex>
                                          </v-layout>
                                        </v-col>
                                      </v-col>
                                    </v-layout>
                                  </v-card>
                                </swiper-slide>
                                <div
                                  class="swiper-pagination"
                                  slot="pagination"
                                ></div>
                                <div
                                  class="swiper-button-prev"
                                  slot="button-prev"
                                ></div>
                                <div
                                  class="swiper-button-next"
                                  slot="button-next"
                                ></div>
                              </swiper>
                            </v-flex>
                          </v-layout>
                        </template>
                        <!-- <v-layout wrap row align-center justify-center>
                          <v-flex lg11>
                            <v-row>
                              <v-col>
                                <div style="width:100%;max-width:100%;overflow: hidden;">
                                  <slider ref="slider" :options="optionSlider">
                                    <slideritem v-for="(item, index) in filesLastView" :key="index" :style="styleSlider">
                                      <v-card class="elevation-0" 
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                                        hover
                                        outlined
                                        width="100%"
                                      >
                                        <v-layout justify-center column align-center wrap>
                                          <v-col>
                                            <v-img contain :src="item.file_thumbnail" max-height="75px" height="75px" v-if="item.file_thumbnail != ''"></v-img>
                                            <div v-else class="text-center">
                                              <v-icon :color="item.file_icon[1]" size="75px" >{{ item.file_icon[0] }}</v-icon>
                                            </div>
                                            <v-row class="pt-3">
                                              <v-flex  align-self-center >
                                                <div
                                                  style=" whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;color:#000;fontSize:16px;padding-left:8px;"
                                                  class="justify flex-start"
                                                >
                                                  <v-icon class large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                                                  {{ item.file_name }}
                                                </div>
                                              </v-flex>
                                            </v-row>
                                          </v-col>
                                        </v-layout>
                                      </v-card>
                                    </slideritem>
                                  </slider>
                                </div>
                              </v-col>
                            </v-row>
                          </v-flex>
                        </v-layout> -->
                      </v-card>
                      <br />
                      <v-card
                        outlined
                        id="borderradius"
                        class="elevation-0"
                        :style="
                          this.$vuetify.theme.dark
                            ? 'background: #1E1E1E;'
                            : 'background: #fafafa;'
                        "
                      >
                        <v-layout row wrap fill-height class="pl-2 pr-2" justify-space-between>
                          <!-- Button Multiple -->
                          <v-flex style="max-width: max-content;">
                            <div
                              v-if="statusmutipledownload === false"
                              class="pa-1 pl-10"
                            >
                              <v-btn
                                @mouseover="show_menu = true"
                                @mouseleave="show_menu = false"
                                :color="color.theme"
                                rounded
                                class="ma-1 pa-3 white--text"
                                @click="(filemultipledownload = []),
                                  (statusmutipledownload = true),
                                    (show_menu = false)
                                "
                                v-if="changeUI === false"
                              >
                                <v-icon>mdi-menu</v-icon>
                                <p
                                  class="ml-2"
                                  style="font-size:15px;margin-bottom:2px;"
                                >
                                  {{ $t("balloon.menu") }}
                                </p>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-40px;"
                                  :value="show_menu"
                                  :content="$t('balloon.menu')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-55px;"
                                  :value="show_menu"
                                  :content="$t('balloon.menu')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                            </div>
                            <div v-else class="pa-1 pl-10 darkmode">
                              <div v-if="close_header === true" >
                              <v-btn
                                @mouseover="show_load = true"
                                @mouseleave="show_load = false"
                                fab
                                small
                                outlined
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="multipledownload()"
                              >
                                <v-icon>mdi-download-outline</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-42px;"
                                  :value="show_load"
                                  :content="$t('balloon.download')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_load"
                                  :content="$t('balloon.download')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_print = true"
                                @mouseleave="show_print = false"
                                fab
                                small
                                outlined
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="fn_printPDF()"
                              >
                                <v-icon>print</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-40px;"
                                  :value="show_print"
                                  :content="$t('balloon.print')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-42px;"
                                  :value="show_print"
                                  :content="$t('balloon.print')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                outlined
                                :color="color.theme"
                                fab
                                small
                                class="ma-1 pa-0"
                                @click="
                                  setmultiupdate(filemultipledownload, 'S')
                                "
                                @mouseover="show_star = true"
                                @mouseleave="show_star = false"
                              >
                                <v-icon dark>star_border</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_star"
                                  :content="$t('balloon.star')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-43px;"
                                  :value="show_star"
                                  :content="$t('balloon.star')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>

                              <v-btn
                                @mouseover="show_delete = true"
                                @mouseleave="show_delete = false"
                                outlined
                                fab
                                small
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="multideletetotrash()"
                              >
                                <v-icon>delete_outline</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-22px;"
                                  :value="show_delete"
                                  :content="$t('balloon.delete')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-35px;"
                                  :value="show_delete"
                                  :content="$t('balloon.delete')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_move = true"
                                @mouseleave="show_move = false"
                                outlined
                                fab
                                small
                                class="ma-1 pa-0"
                                :color="color.theme"
                                @click="appsbarmultimovefile($event)"
                              >
                                <v-icon>mdi-folder-swap-outline</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-60px;"
                                  :value="show_move"
                                  :content="$t('balloon.move')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-65px;"
                                  :value="show_move"
                                  :content="$t('balloon.move')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_addhashtag = true"
                                @mouseleave="show_addhashtag = false"
                                outlined
                                fab
                                small
                                class="ma-1 pa-0"
                                :color="color.theme"
                                @click="apphashtag()"
                              >
                                <v-icon>mdi-pen-plus</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_addhashtag"
                                  :content="$t('balloon.addhashtag')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-65px;"
                                  :value="show_addhashtag"
                                  :content="$t('balloon.addhashtag')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_clear = true"
                                @mouseleave="show_clear = false"
                                color="error"
                                outlined
                                fab
                                small
                                class="ma-1 pa-0"
                                @click="
                                  (isCheckbox_sep_header = false),
                                  (statusmutipledownload = false),
                                    clearmultipledownload(),
                                    (show_clear = false),
                                    (close_header = true)
                                "
                              >
                                <v-icon>clear</v-icon>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-20px;"
                                  :value="show_clear"
                                  :content="$t('balloon.clear')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-45px;left:-30px;"
                                  :value="show_clear"
                                  :content="$t('balloon.clear')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                            </div>
                            </div>
                          </v-flex>
                          <v-flex style="max-width: min-content;">
                            <v-select
                              prepend-inner-icon="sort"
                              hide-details
                              outlined
                              solo
                              dense
                              rounded
                              v-model="size"
                              :items="listsize"
                              class="text-center pt-2 pr-10"   
                              @change="selectedLimit()"                           
                            >
                              <template v-slot:item="{ item }">
                                <div class="text-center">
                                  <span style="font-size: 17px;">{{ item }}</span>
                                </div>
                              </template>
                              <template v-slot:selection="{ item }">
                                <div class="text-center">
                                  <span style="font-size: 17px;">{{ item }}</span>
                                </div>
                              </template>
                            </v-select>
                          </v-flex>
                        </v-layout>

                        <v-data-table
                          v-model="filemultipledownload"
                          :headers="headers"
                          :items="rootfile"
                          item-key="file_id"
                          :single-select="false"
                          :search="search"
                          class="elevation-0 drag-selector pa-0"
                          :show-select="statusmutipledownload"
                          @toggle-select-all="checkfilelock"
                          item-selected
                          :no-data-text="
                            heardertextsearch === false
                              ? $t('tablefile.empty')
                              : $t('tablefile.emptysearch')
                          "
                          :items-per-page="rootfile.length"
                          :hide-default-footer="true"
                          id="mytable"
                        >
                          <!-- <template v-slot:top>
                                <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                            </template>-->
                          <template v-slot:[`header.file_type`]="{ header }">
                            <span :style="headerTable">{{ header.text }}</span>
                          </template>
                          <template v-slot:[`header.file_name`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'name'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template v-slot:[`header.file_owner`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'owner'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template v-slot:[`header.file_lastdtm`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'date'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template v-slot:[`header.file_size`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'size'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template v-slot:[`header.file_location`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                          </template>
                          <!-- หัวข้อจัดการไฟล์ -->
                          <template v-slot:[`header.file_status`]="{ header }">
                            <span :style="headerTable">{{
                              $t(header.text)
                            }}</span>
                          </template>
                          <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                          <template v-slot:item="props">
                            <tr
                              :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                              @contextmenu="
                                rightclickfileandfolder($event, props.item)
                              "
                              @dblclick="
                                gotodirectory(
                                  props.item.file_id,
                                  props.item.file_type,
                                  props.item.file_department,
                                  props.item,
                                  rootfile
                                )
                              "
                              @click="setSelectitemtable(props.item)"
                            >
                              <td
                                class="text-center"
                                v-show="statusmutipledownload"
                              >
                                <v-checkbox
                                  v-model="filemultipledownload"
                                  :value="props.item"
                                  v-if="props.item.file_status !== 'M' && props.item.status_lock_data !== 'Y' "
                                />
                              </td>
                              <td
                                width="7%"
                                class="text-center"
                                style="padding:0px;margin:0px;"
                              >
                                <v-badge
                                  color="orange"
                                  overlap
                                  icon="link"
                                  v-if="
                                    props.item.file_status_sharelink === 'Y' ||
                                      props.item.file_status_sharelink === 'P'
                                  "
                                >
                                  <v-icon
                                    large
                                    :color="props.item.file_icon[1]"
                                    >{{ props.item.file_icon[0] }}</v-icon
                                  >
                                </v-badge>
                                <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                </v-badge>
                                <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                  <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                </v-badge>
                                <v-icon
                                  v-else
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                                <div>
                                  <v-chip
                                    x-small
                                    dark
                                    v-show="
                                      props.item.status_expired === '0' ||
                                        props.item.status_expired === '1' ||
                                        props.item.status_expired === '4'
                                    "
                                    :color="
                                      props.item.status_expired === '0'
                                        ? 'red'
                                        : props.item.status_expired === '1' ||
                                          props.item.status_expired === '4'
                                        ? '#fc9208e6'
                                        : 'white'
                                    "
                                    >{{
                                      props.item.status_expired === "0"
                                        ? $t("statusfileexpired.expired")
                                        : props.item.status_expired === "1" ||
                                          props.item.status_expired === "4"
                                        ? $t("statusfileexpired.nearexpired")
                                        : ""
                                    }}</v-chip
                                  >
                                </div>
                              </td>
                              <td
                                width="43%"
                                :title="props.item.file_name"
                                style="padding:0px;margin:0px;"
                              >
                                <div
                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:540px"
                                >
                                  <v-icon
                                    v-if="props.item.status_lock_data === 'Y'"
                                    color="error"
                                    small
                                    >lock</v-icon
                                  >
                                  <div style="display: inline-block; margin-left: 5px;"
                                  v-if="props.item.status_hilightword === undefined">
                                  {{ props.item.file_name | subStr }}
                                  </div>
                                  <div v-html="fn_hilightWord(props.item.file_name)" style="display: inline-block; margin-left: 5px;" v-else>
                                  </div>
                                  <v-icon
                                    v-if="checkNewDataFormDate(props.item) === true && checkNewDataNoRefresh(props.item) === true && checkNewDataRefresh(props.item) === true"
                                    size="20"
                                    color="red"
                                    >mdi-new-box</v-icon
                                  >
                                  <v-icon
                                    v-if="props.item.status_expired === '2'"
                                    small
                                    color="green"
                                    >access_time</v-icon
                                  >
                                  <v-icon
                                    small
                                    color="orange"
                                    v-if="props.item.file_status_share === 'Y'"
                                    >group_add</v-icon
                                  >
                                  <div v-show="props.item.editing_status === 'Y'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                        >
                                      </div>
                                      <div v-show="props.item.editing_status === 'S'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >กำลังซิงค์...</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >Syncing...</v-chip
                                        >
                                      </div>
                                </div>
                              </td>
                              <td
                                class="text-center"
                                width="10%"
                                style="padding:0px;margin:0px;"
                                v-if="$t('default') === 'th'"
                              >
                                <div style="justify-content: center;">
                                  {{ props.item.file_owner_th }}
                                  <!-- <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon> -->
                                </div>
                              </td>
                              <td
                                class="text-center"
                                width="10%"
                                style="padding:0px;margin:0px;"
                                v-else
                              >
                                <div style="justify-content: center;">
                                  {{ props.item.file_owner_eng }}
                                  <!-- <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon> -->
                                </div>
                              </td>
                              <td class="text-center" width="10%" style="padding:0px;margin:0px;">
                                {{ formatdatetime(props.item.file_lastdtm) }}
                              </td>
                              <td class="text-center" width="10%" style="padding:0px;margin:0px;">
                                {{
                                  fn_calculatesize(props.item.file_size) ==
                                  "0.00 B"
                                    ? "-"
                                    : fn_calculatesize(props.item.file_size)
                                }}
                              </td>
                              <td v-if="isAdvancedSearch" class="cardDataTable dragSelectTable" width="10%" style="padding:0px;margin:0px;" :id="props.item.file_id">
                                <template v-if="props.item.file_location.length > 0">
                                  <v-menu
                                    rounded="xl"
                                    left
                                    open-on-hover
                                    @input="onToggleFileLocation($event, props.item)"
                                    content-class="menu-content"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn text rounded v-bind="attrs" v-on="on">
                                        <v-icon small color="grey">mdi-folder</v-icon>
                                        <span class="name-location">
                                          {{ props.item.file_location[props.item.file_location.length - 1].folder_name }}
                                        </span>
                                      </v-btn>
                                    </template>
                                    <v-list class="px-2 breadcrumbs-location" max-width="1000" height="52px" style="overflow-y: hidden;">
                                      <v-breadcrumbs 
                                        v-if="props.item.is_expand_file_location"
                                        :items="props.item.file_location" 
                                        divider=">" 
                                        class="pa-0"
                                        style="display: inline-block; overflow-x: auto; white-space: nowrap;"
                                      >
                                        <template v-slot:item="prop">
                                          <v-breadcrumbs-item
                                            style="cursor: pointer;"
                                            @click.stop="gotofolder(prop.item.folder_id)"
                                            :disabled="loaddataprogress"
                                          >
                                            <v-tooltip bottom content-class="tooltip-content">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn text rounded v-bind="attrs" v-on="on">
                                                  <v-icon small color="grey">{{ prop.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                                  <span class="path-name-location">{{ prop.item.folder_name }}</span>
                                                </v-btn>
                                              </template>
                                              <span style="font-size: 12px;">{{ prop.item.folder_name }}</span>
                                            </v-tooltip>
                                          </v-breadcrumbs-item>
                                        </template>
                                      </v-breadcrumbs>
                                      <v-breadcrumbs 
                                        v-else
                                        :items="props.item.display_file_location" 
                                        divider=">" 
                                        class="pa-0"
                                        style="display: inline-block; overflow-x: auto; white-space: nowrap;"
                                      >
                                        <template v-slot:item="prop">
                                          <v-breadcrumbs-item
                                            style="cursor: pointer;"
                                            @click.stop="prop.item.folder_name === '...' ? props.item.is_expand_file_location = true : gotofolder(prop.item.folder_id)"
                                            :disabled="loaddataprogress"
                                          >
                                            <v-btn v-if="prop.item.folder_name === '...'" text rounded>
                                              <v-icon small color="grey">mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                            <v-tooltip v-else bottom content-class="tooltip-content">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn text rounded v-bind="attrs" v-on="on">
                                                  <v-icon small color="grey">{{ prop.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                                  <span class="path-name-location">{{ prop.item.folder_name }}</span>
                                                </v-btn>
                                              </template>
                                              <span style="font-size: 12px;">{{ prop.item.folder_name }}</span>
                                            </v-tooltip>
                                          </v-breadcrumbs-item>
                                        </template>
                                      </v-breadcrumbs>
                                    </v-list>
                                  </v-menu>
                                </template>
                                <template v-else>
                                  <v-tooltip bottom content-class="tooltip-content">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn text rounded v-bind="attrs" v-on="on">
                                        <v-icon small color="grey">{{ props.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                        <span class="name-location">{{ props.item.file_name }}</span>
                                      </v-btn>
                                    </template>
                                    <span style="font-size: 12px;">{{ props.item.file_name }}</span>
                                  </v-tooltip>
                                </template>
                              </td>
                              <td
                                width="10%"
                                class="text-center"
                                v-if="props.item.file_status === 'M'"
                                style="padding:0px;margin:0px;"
                              ></td>

                              <!-- <v-icon small v-if="props.item.file_permission['admin_data'] === 'True'">mdi-crown</v-icon> -->
                              <!-- เปลี่ยนมาเช็คอันนี้ -->
                              <!-- ปุ่มจัดการไฟล์ -->
                              <td width="10%" v-else class="text-right">
                                <v-icon
                                  small
                                  v-if="props.item.file_permission_2 === '06'"
                                  >mdi-crown</v-icon
                                >
                                <v-btn
                                  fab
                                  class="elevation-0"
                                  text
                                  small
                                  @click="
                                    setPermissionFileStar(props.item, 'N', 'st')
                                  "
                                  v-if="props.item.file_status === 'S'"
                                >
                                  <v-icon color="amber">star</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  class="elevation-0"
                                  text
                                  small
                                  @click="
                                    setPermissionFileStar(props.item, 'S', 'st')
                                  "
                                  v-else
                                >
                                  <v-icon>star_border</v-icon>
                                </v-btn>
                                <v-btn
                                  class="elevation-0"
                                  outlined
                                  fab
                                  small
                                  @click="
                                    rightclickfileandfolder($event, props.item)
                                  "
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                  </div>

                  <!-- ตรวจสอบว่าเป็นจอ มือถือ -->
                  <v-card v-else class="mt-4" :style="displayMobile" elevation="0">
                    <v-list
                      two-line
                      class="elevation-0 px-2"
                      v-if="rootfile.length > 0"
                      :style="styleDisplayMobile"
                    >
                      <v-list-item
                        v-for="(item, index) in rootfile"
                        :key="index"
                        @contextmenu="rightclickfileandfolder($event, item)"
                        @click="
                          gotodirectory(
                            item.file_id,
                            item.file_type,
                            item.file_department,
                            item,
                            rootfile
                          ),
                            newpreviewfile(item)
                        "
                        :style="displayMobileForListItem"
                      >
                        <v-list-item-action>
                          <v-badge
                            color="orange"
                            overlap
                            icon="link"
                            v-if="
                              item.file_status_sharelink === 'Y' ||
                                item.file_status_sharelink === 'P'
                            "
                          >
                            <v-icon large :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-badge>
                          <v-badge
                              color="red"
                              overlap
                              icon="lock"
                              v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="mdi-sync-circle"
                              v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                          <v-icon v-else large :color="item.file_icon[1]">{{
                            item.file_icon[0]
                          }}</v-icon>
                          <!-- <v-chip
                                    x-small
                                    dark
                                    v-if="props.item.status_expired !== '3'&& props.item.status_expired !== undefined && props.item.status_expired !== '' && props.item.status_expired !== null"
                                    :color="props.item.status_expired === '0' ? 'red':props.item.status_expired === '1' ? '#fc9208e6':props.item.status_expired === '2' ? '#fc9208e6':'white'"
                                  >{{props.item.status_expired === '0' ? 'หมดอายุ': props.item.status_expired === '1' ? 'ใกล้หมดอายุ':props.item.status_expired === '2' ? 'ใกล้หมดอายุ':''}}</v-chip> -->
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px;">
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="error"
                              v-if="item.status_lock_data === 'Y'"
                              >mdi-lock</v-icon
                            >
                            <div style="display: inline-block;" 
                            v-if="item.status_hilightword === undefined">
                            {{ item.file_name }}
                            </div>
                            <div style="display: inline-block;" 
                             v-html="fn_hilightWord(item.file_name)" v-else>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px;">
                            {{fn_calculatesize(item.file_size) == '0.00 B'
                                ? '-'
                                : fn_calculatesize(item.file_size)
                            }}
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="orange"
                              v-if="item.file_status_share === 'Y'"
                              >group_add</v-icon
                            >
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="#D50000"
                              v-if="item.status_expired === '0'"
                              >mdi-timer</v-icon
                            >
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="#fc9208e6"
                              v-if="
                                item.status_expired === '1' ||
                                  item.status_expired === '4'
                              "
                              >mdi-timer</v-icon
                            >
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="green"
                              v-if="item.status_expired === '2'"
                              >mdi-timer</v-icon
                            >
                            <v-icon
                              dark
                              small
                              class="pr-1"
                              color="red"
                              v-if="item.relation_status === 0"
                              >device_hub</v-icon
                            >
                            <v-icon
                              dark
                              class="pr-1"
                              color="red"
                              size="20"
                              v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true"
                              >mdi-new-box</v-icon
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'">{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else>{{ item.last_update_name.name_eng }} editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            class="elevation-0"
                            icon
                            small
                            fab
                            @click.stop="rightclickfileandfolder($event, item)"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-card-text class="mt-4 mb-4" v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false">
                      <v-img
                        :src="require('../assets/img/icon_empty_data.png')"
                        max-width="132"
                        max-height="150"
                        class="mx-auto"
                      />
                      <v-list-item-title
                        :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                        class="text-center mt-8"
                      > {{heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')}} </v-list-item-title>
                    </v-card-text>
                    <!-- <v-list
                      v-if="
                        rootfile.length === 0 &&
                          disablescrolling === true &&
                          loaddataprogress === false
                      "
                      class="elevation-0"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-center"
                            v-text="
                              heardertextsearch === false
                                ? $t('tablefile.empty')
                                : $t('tablefile.emptysearch')
                            "
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list> -->
                  </v-card>
                </v-flex>
              <scroll-loader
                class="pa-0"
                :loader-method="fn_loadfileandfolder_scrolling"
                :loader-disable="disablescrolling"
              >
              </scroll-loader>
              <scroll-loader class="pa-0" :loader-method="looplistdatarootfile" :loader-disable="disablescrolling_advancedsearch">
                  <div class="text-center">
                    <v-progress-circular v-if="this.disablescrolling_advancedsearch === false" :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </scroll-loader>

              <div class="text-center" v-if="loaddataprogress === true">
                <v-progress-circular
                  :size="50"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-container>
            <previewfile
              :show="openpreviewfile"
              :filedata_1="currentfile"
              :arrayfile="arrayfile"
              :parentfolder="parentfolder"
              :param_preview="param_preview"
              @closepreviewfile="openpreviewfile = false"
              @openpreviewfile="openpreviewfile = true"
              @closeDrag="removeEvent(), cacheOpenNewfiles(currentfile.file_id)"
              @openDrag="addEvent()"
              @reload=" fn_loadfileandfolder_scrolling_child()"
            ></previewfile>
            <!-- <newpreviewfile
              :parentfolder="parentfolder"
              :show="opennewpreviewfile"
              :filedata="currentfile"
              @closenewpreviewfile="opennewpreviewfile = false"
              @opennewpreviewfile="opennewpreviewfile = true"
            ></newpreviewfile>-->
            <uploadfiles
              :show="openUploadfiles"
              :parentfolder="parentfolder"
              :departmentid="departmentid"
              @close="
                loadstorage(),
                  fn_loadfileandfolder_scrolling_child(),
                  (openUploadfiles = false)
              "
            ></uploadfiles>
            <!-- <uploadfolder
              :show="openUploadfolder"
              :parentfolder="parentfolder"
              :departmentid="departmentid"
              @close="openUploadfolder = false"
              @closeandreload="
                fn_loadfileandfolder_scrolling_child(),
                  (openUploadfolder = false)
              "
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
            ></uploadfolder> -->
            <mainrightclick
              :departmentid="''"
              :show="showMenu"
              :permissionfolder="permission_currentfolder"
              :parentfolder="parentfolder"
              :AreaX="x"
              :AreaY="y"
              @loaddatafrommainright="
                loadstorage(),
                  fn_loadfileandfolder_scrolling_child(),
                  (showMenu = false)
              "
              @closemainonly="showMenu = false"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
              @openuploadfolder="openUploadfolder = true"
              @open_file_office="fn_open_file_office_myfile"
              @open_folder="fn_open_folder_myfile"
            ></mainrightclick>
            <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
            <currentfilerightclick
              :confirm="confirmdelete"
              :parentfolder="parentfolder"
              :show="showcurrentMenu"
              :AreaX="x"
              :AreaY="y"
              @callfile="gotodirectory"
              @loadfolder="
                fn_loadfileandfolder_scrolling_child(),
                  (statusmutipledownload = false)
              "
              @callparantfolder="gotoparent"
              @callupdatestatusfile="setPermissionFileStar"
              @newmultidelete="multideletetotrash"
              @closecurrent="
                loadstorage(),
                  fn_loadfileandfolder_scrolling_child(),
                  (showcurrentMenu = false)
              "
              @closecurrentonly="showcurrentMenu = false"
              :file="currentfile"
              :arrayfile="[...rootfile, ...filesLastView]"
              :fileLength="filemultipledownload.length"
              :filemultipledownload="filemultipledownload"
              :permission_folder="permission_currentfolder_v2"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
              @reload="fn_loadfileandfolder_scrolling_child()"
              @loading_print="loaddataprogress_print = true"
              @disloading_print="loaddataprogress_print = false"
              @toggle_loading_ocr="processloader_ocr = !processloader_ocr"
            ></currentfilerightclick>
            <rightcheckpassword
              :show="opendialog_right"
              :filedata="newdata"
              @closedialog="opendialog_right = false"
              @callapi="
                loadstorage(),
                  fn_loadfileandfolder_scrolling_child(),
                  (opendialog_right = false)
              "
            />
            <confirmmovefile
              :dialog="open_confirmdialog"
              @closedialog="open_confirmdialog = false"
              @movefile="moveFileToFolder"
            ></confirmmovefile>
            <multifilerightclick
              :status_data="status_data"
              :show="rightclickmultifile"
              :AreaX="x"
              :AreaY="y"
              :filemultipledownload="filemultipledownload"
              :file="currentfile"
              :parentfolder="parentfolder"
              @closecurrentonly="rightclickmultifile = false"
              @reload="
                loadstorage(),
                  fn_loadfileandfolder_scrolling_child(),
                  (filemultipledownload = []),
                  (checkedList = [])
              "
              @clearparentvalue="clearparentvalue()"
              @closeappsbar="statusmutipledownload = false"
              @multideleteforbusinsess="multideletetotrash()"
            ></multifilerightclick>
            <multi_movefile
              :show="opendialogmultimove"
              :filemultipledownload="filemultipledownload"
              :parentfolder="parentfolder"
              @closedialog="opendialogmultimove = false"
              @reload="
                fn_loadfileandfolder_scrolling_child(),
                  (filemultipledownload = []),
                  (checkedList = [])
              "
              @closeappsbar="statusmutipledownload = false"
            ></multi_movefile>
            <dialogsnackbermultidowload
              :show="opensnackbarmultidownload"
              @closedialog="opensnackbarmultidownload = false"
              :percent="percent"
              :date="datetime"
            ></dialogsnackbermultidowload>
            <dialogsnackbarloading_print
              :show="opensnackbarloadingprint"
              @closedialog="opensnackbarloadingprint = false"
              :percent="percent"
              :date="datetime"
            ></dialogsnackbarloading_print>
            <checkmultidelete
              :show="openchekmultidelete"
              @closedialog="
                (openchekmultidelete = false), (filemultipledownload = []), (show_menumanage = false), (close_header = true)
              "
              @closedialogreload="
                (openchekmultidelete = false),
                  (filemultipledownload = []),
                  loadstorage(),
                  fn_loadfileandfolder_scrolling_child()
              "
              :filedata="filemultipledownload"
              :status_data="status_data"
              @closeDrag="removeEvent()"
              @openDrag="addEvent()"
              @closebuttonmulti="
                (statusmutipledownload = false), clearmultipledownload()
              "
            ></checkmultidelete>
            <dialogcheckmultidownload
              :show="opendialogcheckmultidownload"
              @closedialog="
                (opendialogcheckmultidownload = false),
                  (filemultipledownload = [])
              "
              :data="filemultipledownload"
            ></dialogcheckmultidownload>
            <dialogcheckdeleterightclick
              :show="opensdialogcheckdeleterightclick"
              @closedialog="opensdialogcheckdeleterightclick = false"
              @closedialogreload="
                (opensdialogcheckdeleterightclick = false),
                  loadstorage(),
                  fn_loadfileandfolder_scrolling_child()
              "
              :filedata="currentfile"
            ></dialogcheckdeleterightclick>
            <checkpassword
              :show="opendialog_checklog"
              @closedialog="backtopreventfolder()"
              @close_c_star="clickclick"
              @close="(opendialog_checklog = false), gotodirectory()"
              :filedata="newdata"
              :key_st="check_btn"
              @close_star="setPermissionFileStar(newdata, star_status)"
            />
            <dialogcheckrememberlogin
              :show="opendialogcheckrememberlogin"
              @closedialog="opendialogcheckrememberlogin = false"
            ></dialogcheckrememberlogin>
            <dialogconsentgetdataMed
              :show="opendialogconsentgetMed"
              @closedialog="opendialogconsentgetMed = false"
              @appovegetdata="fn_consent_getdata()"
            ></dialogconsentgetdataMed>
            <dialogeditdeletehashtag
              :show="opendialogeditdeletehashtag"
              :multihashtag="filemultipledownload"
              @close="opendialogeditdeletehashtag = false"
              @reload="loadstorage(), fn_loadfileandfolder_scrolling_child()"
              :filedata="{}"
              :listcheckhashtag="{}"
            ></dialogeditdeletehashtag>
            <dialogsnackbarmultidownload_new
              :show="opendialogmultidownload_new"
              @closedialog="opendialogmultidownload_new = false"
              :data="filemultipledownload"
            ></dialogsnackbarmultidownload_new>
            <dialogmapdrive
              :show="opendialogmapdrive"
              @closedialog="opendialogmapdrive = false"
            ></dialogmapdrive>
            <dialogsetting_select_biz
              :show="open_setting_select_biz"
              @close="open_setting_select_biz = false"
            ></dialogsetting_select_biz>
            <dialogalertevaluation
              :show="opendialogalertevaluation"
              @closedialog="opendialogalertevaluation = false"
              @reload="fn_checkevaluate()"
            ></dialogalertevaluation>
            <!-- <dialogdownloadvideo
              :show="opendialogdownloadvideo"
              ></dialogdownloadvideo> -->
          </v-card-text>
          <!-- <infinite-loading @infinite="fn_loadfileandfolder_scrolling_infinite"></infinite-loading> -->
        </v-card>
      </v-card>
    </v-content>
    <!-- Drop here -->
    <dragdropupload
      :removeevent="removeevent"
      :addevent="addevent"
      :departmentid="departmentid"
      @loadfile="loadstorage(), fn_loadfileandfolder_scrolling_child()"
      :parentfolder="parentfolder"
      @snackbarupload="removeevent = true"
      @detailupload="removeevent = true"
    ></dragdropupload>
    <uploadfolder
      :parentfolder="parentfolder"
      :enabled_inputfile="openUploadfolder"
      :departmentid="departmentid"
      @closewithoutload="openUploadfolder = false"
      @closeUpload="(openUploadfolder = false), fn_loadfileandfolder_scrolling_child()"
    ></uploadfolder>
    <div>
      <v-snackbar v-model="snackbardowload" bottom right>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbardowloadfile"
        bottom
        class="v-snack__content"
        color="white"
        :timeout="timeoutdowload"
      >
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar
            :color="color.theme"
            dense
            short
            :dark="color.darkTheme"
            flat
            fab
          >
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="snackbardowloadfile = false"
              fab
              x-small
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{ datetime }}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular
            >
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VueCookies from "vue-cookies";

// ของ version 6 ใช้ไม่ได้
// import 'swiper/swiper-bundle.css'
// ของ version < 6
import "swiper/css/swiper.css";
// Doc
// https://warpcgd.github.io/vue-concise-slider/demos/Multiple.html
import { slider, slideritem } from "vue-concise-slider";
import CryptoJS from "crypto-js";
import PDFMerger from "pdf-merger-js/browser";

// Import Component
const floatingbutton = () => import("../components/optional/dialog-floatingbutton")
// const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfolder = () => import("../components/upload/input-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const currentfilerightclick = () =>
  import("../components/contextmenu/currentfilerightclick");
const multifilerightclick = () =>
  import("../components/contextmenu/multifilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const previewfile = () => import("../components/optional/dialog-preview");
const confirmmovefile = () =>
  import("../components/optional/dialog-confirmmovefile");
const rightcheckpassword = () =>
  import("../components/optional/dialog-rightclickcheckpassword.vue");
const multi_movefile = () =>
  import("../components/optional/dialog-multimovefile");
const dialogsnackbermultidowload = () =>
  import("../components/optional/dialog-snackbarmultidowmload");
const dragdropupload = () =>
  import("../components/optional/dialog-dragdropupload-chunk");
const dialog_filter = () => import("../components/optional/dialog-filter");
const checkmultidelete = () =>
  import("../components/optional/dialog-checkmultidelete");
const dialogcheckmultidownload = () =>
  import("../components/optional/dialog-checkmultidownload");
const dialogcheckdeleterightclick = () =>
  import("../components/optional/dialog-checkdeleterightclick");
const checkpassword = () =>
  import("../components/optional/dialog-checkpassword");
const dialogcheckrememberlogin = () =>
  import("../components/optional/dialog-checkrememberlogin.vue");
const dialogsnackbarloading_print = () =>
  import("../components/optional/dialog-snackbarloadding-print.vue");
const dialogconsentgetdataMed = () =>
  import("../components/optional/dialog-consent-getdata-mohpromt.vue");
const dialogeditdeletehashtag = () => import("../components/optional/dialog-editdeletehashtag.vue");
// const dialogdownloadvideo = () => import("../components/optional/dialog-cannotpreviewvideo");
const dialogsnackbarmultidownload_new = () => import("../components/optional/dialog-snackbermultidownload-new.vue");
const dialogmapdrive = () => import("../components/optional/dialog-mapdrive.vue");
const dialogsetting_select_biz = () => import("../components/optional/dialog-setting-select-biz.vue");
const dialogalertevaluation = () => import("../components/optional/dialog-alert-evaluation.vue");
import onebox_toolbar from "../components/layout/layout-toolbar-new";

// Import GlobalFunction
import gb_advanceSearch from "@/globalFunctions/advanceSearch";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    floatingbutton,
    checkpassword,
    dragdropupload,
    dialog_filter,
    uploadfiles,
    InfiniteLoading,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    currentfilerightclick,
    previewfile,
    slider,
    slideritem,
    confirmmovefile,
    multifilerightclick,
    rightcheckpassword,
    multi_movefile,
    dialogsnackbermultidowload,
    checkmultidelete,
    dialogcheckmultidownload,
    Swiper,
    SwiperSlide,
    dialogcheckdeleterightclick,
    dialogcheckrememberlogin,
    dialogsnackbarloading_print,
    dialogconsentgetdataMed,
    dialogeditdeletehashtag,
    dialogsnackbarmultidownload_new,
    dialogmapdrive,
    dialogsetting_select_biz,
    dialogalertevaluation
    // dialogdownloadvideo
  },
  data: function() {
    return {
      isAdvancedSearch: false,
      statusevaluation: "",
      eva_expired: "",
      opendialog_eva:"",
      icon_eva:false,
      opendialogalertevaluation:false,
      listdatarootfile:[],
      // num_count:30,
      // index_loop:0,
      count_push:0,
      data_search:{},
      pagee:1,
      load_advanced:false,
      path_folder: "",
      path_folder_split: "",
      count_data_adv_:0,
      disablescrolling_advancedsearch:true,
      isCheckbox_sep_header: false,
      open_setting_select_biz:false,
      opendialogmapdrive:false,
      param_preview:"",
      opendialogmultidownload_new:false,
      taxbox_status:"",
      opendialogconsentgetMed: false,
      show_grid: false,
      show_list: false,
      show_back: false,
      opensnackbarloadingprint: false,
      opendialogcheckrememberlogin: false,
      // opendialogdownloadvideo:false,
      opendialogcheckrememberlogin: false,
      opendialogeditdeletehashtag: false,
      display_grid: false,
      show_menu: false,
      show_print: false,
      show_star: false,
      show_delete: false,
      show_move: false,
      show_addhashtag: false,
      show_load: false,
      show_clear: false,
      show_menumanage: false, //Add new
      status_animation: "",
      scrollY: 0,
      close_header: true,
      check_btn: "",
      star_status: "",
      checkdatacopy: [],
      choosecopy: [],
      heardertextsearch: false,
      listcopy: [],
      arrayfile: [],
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 16,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      opensdialogcheckdeleterightclick: false,
      departmentid: "",
      opendialogcheckmultidownload: false,
      status_data: "",
      openchekmultidelete: false,
      removeevent: false,
      addevent: false,
      opensnackbarmultidownload: false,
      percent: 0,
      disablescrolling: true,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      opendialogmultimove: false,
      parentfolderDD2: "",
      newdata: "",
      // sizeOnList: 30,
      opendialog_right: false,
      opendialog_checklog: false,
      rightclickmultifile: false,
      open_confirmdialog: false,
      target_folder: "",
      holdfile_drag: [],
      filelist: [],
      selectmulti: [],
      aspect: "",
      aspectData: [{ aspect: "False", aspect_key: [] }],
      permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload: "False",
        delete: "False",
      },
      checkCC: false,
      checkCCFolder: false,
      multiple: true,
      // changeUI: localStorage.getItem("changeStoreUI") === "true" ? true : false,
      changeUI:
        localStorage.getItem("changeStoreUI") === "false" ? false : true,
      checkedList: [],
      timeoutdowload: 60000000,
      percentCompleted: 0,
      datetime: "",
      timeout: 0,
      interval: {},
      value: 0,
      countfile: "",
      countfolder: "",
      sortby: "",
      clickSort: false,
      myDateDownload: new Date().toUTCString(),
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      overlay: true,
      page: 1,
      size: 30,
      listsize: [30, 50, 100],
      lists: [],
      pagination: {
        rowsPerPage: 100000,
        sortBy: "file_id",
        totalItems: 0,
        rowsPerPageItems: [1, 2, 4, 8],
      },
      openpreviewfile: false,
      loaddataprogress: true,
      loaddataprogress_print: false,
      processloader_ocr: false,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      showPrevMenu: false,
      triggered_rc_fileorfolder: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      // headers: [
      //   {
      //     text: "#",
      //     align: "center",
      //     value: "file_type",
      //     width: "7%",
      //     sortable: false,
      //     color: "powderblue",
      //   },
      //   {
      //     text: "tablefile.filename",
      //     align: "left",
      //     value: "file_name",
      //     width: "43%",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.owner",
      //     align: "center",
      //     value: "file_owner",
      //     width: "10%",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.lastmodified",
      //     value: "file_lastdtm",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.filesize",
      //     value: "file_size",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.location",
      //     value: "file_location",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //   // หัวข้อจัดการไฟล์
      //   {
      //     text: "tablefile.option",
      //     value: "file_status",
      //     width: "10%",
      //     align: "right",
      //     sortable: false,
      //   },
      // ],
      opendialog_checklog: false,
      listDataUpload: [],
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      confirmdelete: false,
      buff: [],
      listUpload: [],
      snackbar: false,
      snackbardowload: false,
      showStatus: "",
      hover: false,
      snackbardowloadfile: false,
      itemC: [],
      checkCe: false,
      newrootfile: [],
      fileCount: 0,
      filterFile: [],
      filterFolder: [],
      filesLastView: [],
      optionSlider: {
        currentPage: 0,
        infinite: 3,
        slidesToScroll: 1,
        loop: true,
        pagination: false,
        loopedSlides: 1,
        autoplay: "10000",
      },
      styleSlider: {
        background: "#fff",
        width: "32.3%",
        // 33.3
        "margin-right": "1%",
        "border-radius": "5px",
      },
      parentfolderDD: "",
      parentfolderDDfile: "",
      re1: "",
      checkpath: "",
      folderupload: [],
      rootname: "",
      totalcountt: 0,
      rootFolder: "",
      count: 0,
      total: 0,
      sorting: {
        sort: [{
            id: 'name',
            text: 'sorting.name',
            icon: 'mdi-file-document'
          }, {
            id: 'cre_dtm',
            text: 'sorting.date_created',
            icon: 'mdi-calendar-multiselect'
          }, {
            id: 'last_dtm',
            text: 'sorting.date_modified',
            icon: 'mdi-timer'
          }, {
            id: 'type',
            text: 'sorting.type',
            icon: 'mdi-text'
          },{
            id: 'size_file',
            text: 'sorting.size_file',
            icon: 'mdi-clipboard'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      sort_by: localStorage.getItem('sort_by') !== null ? localStorage.getItem('sort_by') : "cre_dtm",
      direction: localStorage.getItem('direction_by') !== null ? localStorage.getItem('direction_by') : "ASC",
      dataCredtmNewUpload: "",
      arrayCredtmNewUpload: [],
      arrayOpenNewfiles: [],
      permission_currentfolder_v2: "01",
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "feature_onlyoffice",
      "setting_select_biz"
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // Old
        // let l = this.rootfile.length;

        // New api
        // let l = this.fileCount;

        let l = this.heardertextsearch ? this.count_data_adv_ : this.totaldata["totalall"];
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return (
          item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      // console.log(this.color.theme);

      return (
        "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    word_grid() {
      return this.$vuetify.theme.dark
        ? "background: #464646;"
        : "background: #fafafa;";
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700; line-height: 24px;";
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);"
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    checkbox_value : {
      // getter
      get () {
        let resultCheckBox
        if(this.filemultipledownload.length === this.rootfile.length) {
          resultCheckBox = true
        } else {
          resultCheckBox = false
        }
        return resultCheckBox
      },
      // setter
      set (newValue) {
        if(newValue === false){
          this.isCheckbox_sep_header = false
        } else {
          this.isCheckbox_sep_header = true
        }
      }
    },
    
    indeterminate_custom_checkbox () {
      if(this.filemultipledownload.length > 0 && this.filemultipledownload.length < this.rootfile.length){
        return true
      } else {
        console.log("Why heree")
        return false
      }
    },
    //animation list menu 
    animationlistmenu() {
      if(this.show_menumanage === true){
        return `pa-1 pl-10 darkmode` 
      }
    },
    animationlistmenu_v2() {
      if(this.show_menumanage === true){
        return `${this.status_animation}` 
      }
    },
    headerdatatable () {
      return `borderLeft: 1px solid; borderRight: 1px solid;
              borderLeftColor: ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'};
              borderRightColor: ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'};`
    },
    headeranimationmenu() {
      return  `background: ${this.$vuetify.theme.dark ? '#1e1e1e' : '#fafafa'}; width: 100%; ` +
              `border: 1px solid ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'}; `+ 
              `borderTopLeftRadius: 12px 12px; borderTopRightRadius: 12px 12px;`
    },
    headers() {
      let _headers = [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
          color: "powderblue",
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "43%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "center",
          value: "file_owner",
          width: "10%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        },
      ]
      if (this.isAdvancedSearch) {
        _headers.push({
          text: "tablefile.location",
          value: "file_location",
          width: "10%",
          align: "center",
          sortable: false,
        });
      }
      _headers.push ({
        text: "tablefile.option",
        value: "file_status",
        width: "10%",
        align: "right",
        sortable: false,
      });
      return _headers
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 100) return string.substring(0, 100) + "...";
        else return string;
      }
    },
  },
  watch: {
    // checkedList(val){
    //   console.log('watch checkedlist:',val)
    // },
    rightclickmultifile(val) {
      if (val === false) this.checkedList = [];
    },
    permission_currentfolder(val) {
      if (val["upload"] == "False") {
        this.removeEvent();
      } else {
        this.addEvent();
      }
    },
    sort_by() {
      localStorage.setItem('sort_by', this.sort_by)
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    },
    direction() {

      localStorage.setItem('direction_by', this.direction)
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    },
    scrollY: { // ถ้าหากต้องการปิดการใช้งาน scroll menu 
      immediate: true,
      handler (value) {
        if(this.rootfile.length > 100){
          this.animatemenumanage(value, 1000, 400)
        } else {
          this.animatemenumanage(value, 600, 400)
        }
      }
    },
    changeUI(newValue) {
      // this.rootfile = [];
      if (newValue) {
        // this.sizeOnList = this.size;
        // reset limit
        this.size = 30
      } else {
        // this.size = this.sizeOnList;
        this.size = localStorage.getItem("limitLoadFileFolder") ? parseInt(localStorage.getItem("limitLoadFileFolder")) : 30;
      }
    },
  },
  methods: {
    onToggleFileLocation(open, item) {
      // console.log('open', open, item)
      if (!open) { // ตอนไม่ hover menu file location
        item.is_expand_file_location = false;
      }
    },
    fn_open_folder_myfile(data){
      let folder_data = {
        account_id: this.dataAccountId,
        account_reciever: "",
        account_sender: this.dataAccountId,
        countnum: 0,
        data_type: "r",
        editing_status: "N",
        expiration_time: "00000000000000",
        file_createdtm: data.cre_dtm,
        file_department: "",
        file_icon: [
                "mdi-folder",
                "#ffc107"
            ],
        file_id: data.folder_id,
        file_lastdtm: data.cre_dtm,
        file_linkshare: null,
        file_name: data.folder_name,
        file_owner_eng: "Me",
        file_owner_th: "ฉัน",
        file_parent_folder_id: this.$route.params.id,
        file_permission: {
                "admin_data": "False",
                "delete": "True",
                "download": "True",
                "edit": "True",
                "upload": "True",
                "view_only": "True"
            },
        file_permission_2: "05",
        file_permission_department_setting: "False",
        file_permission_setting: "False",
        file_size: "",
        file_status: "N",
        file_status_share: "N",
        file_status_sharelink: "N",
        file_thumbnail: "",
        file_thumbnail_icon: [
                 "mdi-folder",
                 "#ffc107"
        ],
        file_type: "folder",
        folder_id: this.$route.params.id,
        hashtag:[],
        last_update_name:{
                  "name_eng": "Me",
                  "name_th": "ฉัน"
        },
        last_view:"",
        permission_business_setting: "False",
        permispermission_department_setting: "False",
        permission_role_setting: "False",
        permission_setting: "False",
        priority: "0",
        relation_id: "",
        relation_name: "",
        relation_status: 2,
        starred_id: null,
        status_encrypt: "N",
        status_expired: "3",
        status_lock_data: "N",
        status_lock_file: "",
        status_lock_folder: "",
        status_updating: "Done",
        system: null,
        tag_id: "N",
        type: "folder",
        update_by: "",
        user_id: this.dataUsername,
        version_tag: "N",
        warning_expiration_time: "-"
      }
      this.checkgotodirectory(folder_data, this.rootfile)
    },
    selectedLimit() {
      localStorage.setItem("limitLoadFileFolder", this.size);
      if (this.heardertextsearch) {
        this.rootfile = [];
        this.disablescrolling_advancedsearch = false;
      } else {        
        this.fn_loadfileandfolder_scrolling_child('', { cal_offset: 0 });
      }
    },
    fn_open_file_office_myfile(data){
      // console.log("fn_open_file_office_myfile",data);
      let file_data = {
            account_reciever: "",
            account_sender: this.dataAccountId,
            account_id: this.dataAccountId,
            countnum: 0,
            file_createdtm: "",
            file_id: data.id,
            file_lastdtm: "",
            file_linkshare: null,
            file_name: data.filename,
            file_owner_eng: "Me",
            file_owner_th: "ฉัน",
            priority: 1,
            file_size: data.size_file,
            file_status: "N",
            file_status_share: "N",
            file_status_sharelink: "N",
            system: null,
            type: "file",
            file_icon: [
                "mdi-file-excel",
                "#00733b"
            ],
            file_type: data.filename.split(".")[data.filename.split(".").length -1],
            data_type: "file",
            user_id: this.dataUsername,
            starred_id: null,
            file_parent_folder_id: "",
            file_permission: {
                "admin_data": "False",
                "delete": "True",
                "download": "True",
                "edit": "True",
                "upload": "True",
                "view_only": "True"
            },
            file_permission_department_setting: "False",
            permission_department_setting: "False",
            permission_business_setting: "True",
            permission_setting: "True",
            permission_role_setting: "False",
            file_permission_setting: "True",
            file_department: "",
            relation_id: "",
            relation_name: "",
            relation_status: 2,
            file_permission_2: "05",
            status_updating: "Done",
            status_lock_data: "N",
            status_expired: "3",
            expiration_time: "00000000000000",
            version_tag: "False",
            tag_id: "N",
            hashtag: [],
            file_thumbnail: "",
            file_thumbnail_icon: [
                "mdi-file-excel",
                "#00733b"
            ],
            editing_status: "N",
            update_by: "",
            last_update_name: {
                "name_eng": "Me",
                "name_th": "ฉัน"
            },
            warning_expiration_time: "-",
            status_encrypt: "N",
            last_view: ""
        }
      this.checkgotodirectory(file_data, this.rootfile)
    },
    fn_hilightWord (text) {
      var regex = new RegExp(this.data_search.name, "gi")
      if(this.$vuetify.theme.dark){
        var response = text.replaceAll(regex, function(str) {
          return "<span style='background-color: #827917'>" + str + "</span>"
        })
      } else {
        var response = text.replaceAll(regex, function(str) {
          return "<mark>" + str + "</mark>"
        })
      }
      return response
      //return text.replaceAll(new RegExp(this.data_search.name,"gi"), `<mark>${this.data_search.name}</mark>`);
    },
    Checkbox_custom_header (value) {
      if(value){
        this.filemultipledownload = [...this.rootfile.filter((item) => {return item.file_status !== 'M'})]
        this.isCheckbox_sep_header = true 
      } else {
        this.filemultipledownload = []
        this.isCheckbox_sep_header = false
      }
    },
    checkfilelock (allfile) {
      if(allfile.value){
        this.filemultipledownload = allfile.items.filter((item) => item.status_lock_data !== 'Y')
      }else{
        this.filemultipledownload = []
      }
    },
    reloadSortByAndDirection() {
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    },
    async fn_check_consentgetdata_MED() {
      var idcard = VueCookies.get("id_card_num");
      let encode_idcard = btoa(idcard);

      console.log("idcard==", idcard);
      let payload = {
        cid: encode_idcard,
      };

      var actual = atob(encode_idcard);
      console.log("actual", actual);

      let auth = await gbfGenerate.generateToken();
      // console.log("auth", auth);

      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/getstatus/consent",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            console.log("res", response.data.data);
            if (response.data.data.length !== 0) {
              console.log("เข้า !== ");
              if (
                response.data.data[0].consent_status !== "Y" ||
                response.data.data[0].consent_status === undefined
              ) {
                this.opendialogconsentgetMed = true;
              } else {
                this.fn_getdata_Med();
              }
            } else {
              this.opendialogconsentgetMed = true;
            }
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    async fn_getdata_Med() {
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
      let payload = {
        oneid_user_id: user_id,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      let resp = await axios.post(
        process.env.VUE_APP_SERVICE_MED + "/api/moph/get/file",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      console.log(resp);
      try {
        if (resp.data.status === "OK") {
          // this.createprogress = false;
          this.loaddataprogress = false;
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: resp.data.errorMessage,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    //consent ข้อมูลหมอพร้อม
    async fn_consent_getdata() {
      let idcard = VueCookies.get("id_card_num");
      console.log("lllllll");
      let payload = {
        cid: idcard,
        consent_action: "pull_med_cert",
        consent_status: "Y",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      // console.log("auth",auth);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/register/consent",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.opendialogconsentgetMed = false;
            VueCookies.set("check_dialog_mohpromt", true);
            this.fn_getdata_Med();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    chektitle_icon(_item) {
      if (_item.file_status_sharelink === "Y") {
        return "แชร์ลิงก์";
      } else if (_item.file_status_share === "Y") {
        return "แชร์ไฟล์";
      } else if (_item.status_expired === "0") {
        return "ไฟล์หมดอายุ";
      } else if (_item.status_expired === "1") {
        return "ไฟล์ใกล้หมดอายุ";
      }
    },
    changeFile() {
      var file = input.files[0];
      var reader = new FileReader();
      reader.addEventListener("load", readFile);
      reader.readAsText(file);
    },

    async fn_printPDF() {
      if (this.filemultipledownload.length > 0) {
        this.opensnackbarloadingprint = true;
        // this.loaddataprogress_print = true;
        this.percent = 0;
        let listfile = "";
        let notpdf = false;
        let i;
        for (i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i]["data_type"] !== "pdf") {
            Toast.fire({
              icon: "error",
              title: "โปรดเลือกเฉพาะไฟล์ PDF",
            });
            notpdf = true;
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
          } else if (this.filemultipledownload[i]["status_lock_data"] === "Y") {
            Toast.fire({
              icon: "error",
              title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
            });
            notpdf = true;
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
          } else {
            listfile += "&list_file=" + this.filemultipledownload[i]["file_id"];
            //console.log(listfile);
          }
        }
        if (i === this.filemultipledownload.length && notpdf !== true) {
          let auth = await gbfGenerate.generateToken();

          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/merge_pdf?account_id=" +
            this.dataAccountId +
            "&user_id=" +
            this.dataUsername +
            "&Authorization=" +
            auth.code +
            listfile;
          this.axios
            .get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                console.log("progressEvent", progressEvent);
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                // this.loadingpercent = progresspercent;
                this.percent = progresspercent;
                // console.log("progresspercent",progresspercent);
              },
              responseType: 'blob',
            })
            .then((response) => {
              console.log(response.data.status);
              if (response.data.type === "application/pdf") {
                this.statusmutipledownload = false;
                this.filemultipledownload = [];
                this.opensnackbarloadingprint = false;
                // this.loaddataprogress_print = false;
                let url = window.URL.createObjectURL(response.data);
                window.open(url).print();
                // Add new
                this.show_menumanage = false
                this.close_header = true
              } else {
                this.opensnackbarloadingprint = false;
                // this.loaddataprogress_print = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถรวมไฟล์เสียได้",
                });
              }
            });
        }
      } else {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    clickclick() {
      this.opendialog_checklog = false;
    },
    //เช็ค ctrlkey
    fn_checkkeyevent(e) {
      // e.preventDefault();
      // e.stopPropagation();
      // if(this.$router.currentRoute.path === "/mydrive"){
      console.log("e", e);
      // console.log("item",item);
      this.checkdatacopy = [];

      if (e.ctrlKey == true && e.code === "KeyC") {
        console.log("choosecopy", this.choosecopy);
        this.checkdatacopy = Array.from(new Set(this.choosecopy));
        console.log("checkdatacopy", this.checkdatacopy);
        console.log("KeyC");
        //  for(let i; i < this.choosecopy.length; i++){
        //    if(this.choosecopy[i].file_type === 'folder'){
        //       Toast.fire({
        //       icon: "error",
        //       title: "ไม่สามารถคัดลอกโฟลเดอร์ได้",
        //     });
        //    }else{
        //      this.fn_copyfile(this.choosecopy)
        //    }
        //  }
        this.fn_copyfile(this.checkdatacopy);
      } else if (e.ctrlKey == true && e.code === "KeyV") {
        console.log("KeyV");
        this.fn_pastefile();
      }
      // }
    },
    //เก็บไฟล์ใน sessionStorage
    fn_copyfile(item) {
      console.log("fn_copyfile", item);
      this.listcopy = [];
      for (var i = 0; i < item.length; i++) {
        console.log("i*tem*", item[i]);
        // เช็คว่าเป็น folder หรือ ไฟล์ ถ้าเป็นไฟล์ push ใส่ listcopy
        if (item[i].file_type === "folder") {
          // } else if (item[i].file_permission.download === "True" && item[i].status_lock_data === "N") {
          //   this.listcopy.push(item[i]);
          // }
          // เปลี่ยนมาเช็คอันนี้
        } else if (
          item[i].file_permission_2 !== "01" &&
          item[i].status_lock_data === "N"
        ) {
          this.listcopy.push(item[i]);
        }
      }
      //เช็ค listcopy ว่าเท่ากับ 0 มั้ยถ้าเท่า(เลือกโฟลเดอร์) ให้แจ้งเตือนถ้ามากกว่า 0 (เลือกไฟล์) ให้เก็บใน sessionstorage
      if (this.listcopy.length == 0) {
        Toast.fire({
          icon: "error",
          title: "กรุณาเลือกไฟล์ที่ต้องการคัดลอก",
        });
      } else {
        console.log("listcopy", this.listcopy);
        sessionStorage.setItem("filecopy", JSON.stringify(this.listcopy));
        Toast.fire({
          icon: "success",
          title: "คัดลอกไฟล์",
        });
        console.log("************ ctrl+c *************");
      }
    },
    async fn_pastefile() {
      // this.rootfile = [];
      this.loaddataprogress = true;
      this.listfilecopy = [];
      // console.log("this.choosecopy",this.choosecopy);
      //เอาข้อมูลที่ลากไฟล์มา ที่ซ้ำออก
      this.checkdatapaste = Array.from(new Set(this.choosecopy));
      console.log("this.checkdatapaste", this.checkdatapaste);
      // getค่าจาก sessionstorage
      let data_copy = sessionStorage.getItem("filecopy");
      let data_paste = JSON.parse(data_copy);
      console.log("data_paste", data_paste);
      //วนเพื่อปั้นข้อมูลที่จะส่งไป api
      for (let i = 0; i < data_paste.length; i++) {
        this.listfilecopy.push({
          data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
          data_id: data_paste[i].file_id,
        });
      }
      console.log("this.listfilecopy***", this.listfilecopy);

      let payload = {
        account_id: this.dataAccountId,
        folder_id: this.dataAccountActive.directory_id,
        copied: this.listfilecopy,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      let resp = await axios.post(
        process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      console.log(resp);
      try {
        if (resp.data.status === "OK") {
          // this.createprogress = false;
          this.loaddataprogress = false;
          Toast.fire({
            icon: "success",
            title: "วาง",
          });
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: resp.data.errorMessage,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    // ตั้ง thumbnail icon
    fn_setThumbnailIcon(_type) {
      let dataicon;
      let parameter;
      if (typeof _type === "string") {
        parameter = _type.toLowerCase();
      } else {
        parameter = _type;
      }
      console.log("parameterparameter",parameter);
      if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
        // dataicon = ["mdi-file-excel", "green"];
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        // dataicon = ["mdi-file-powerpoint", "orange"];
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "docx" || parameter === "doc") {
        // dataicon = ["mdi-file-word", "primary"];
        dataicon = ["mdi-file-word", "#0263d1"];
      }else if (parameter === "html") {
        // dataicon = ["mdi-language-html5", "orange"];
        dataicon = ["mdi-language-html5", "#FF603F"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        //  dataicon = ["mdi-file-pdf", "red"];
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png" || parameter === "gif") {
        // dataicon = ["mdi-file-image", "blue-grey"];
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        // dataicon = ["mdi-folder", "#FDD361"];
        dataicon = ["mdi-folder", "#ffc107"];
      }
      else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
    // โหลดรูป thumbnail มาแสดง
    async fn_loadthumbnail(
      _file_id,
      _file_type,
      _file_size,
      _status_lock_data
    ) {
      // this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
      let auth = await gbfGenerate.generateToken();
      let getThumbnail = "";
      const extension_file = [
        "jpg",
        "jpeg",
        "bmp",
        "gif",
        "pdf",
        "png",
        "JPG",
        "JPEG",
        "BMP",
        "GIF",
        "PDF",
        "PNG"
      ];
      if (_status_lock_data !== "Y") {
        //  && parseInt(_file_size) <= 10485760
        if (
          extension_file.includes(_file_type) === true
        ) {
          // if (_file_type === "png" || _file_type === "jpg" || _file_type === "jpeg" || _file_type == "bmp" || _file_type === "gif") {
          this.axios
            .get(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/v1/thumbnail?file_id=" +
                _file_id,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              // console.log('response thumbnail',response.data)
              if (response.data.status === "OK") {
                let strThumbnail = response.data.base64_img;
                // console.log('thumbnail',"data:image/" + _file_type + ";base64," + strThumbnail)
                // let default_type = 'jpg'
                getThumbnail =
                  "data:image/" + _file_type + ";base64," + strThumbnail;
                this.rootfile
                  .filter((o) => o.file_id === _file_id)
                  .forEach((o) => (o.file_thumbnail = getThumbnail));
                this.filesLastView
                  .filter((o) => o.file_id === _file_id)
                  .forEach((o) => (o.file_thumbnail = getThumbnail));
              }
            });
        }
      }
    },
    //ฟังก์ชั่นกลับไปแหล่งที่มา
    gotoparent(parameter) {
      console.log("parameterparent", parameter);
      //parent folder
      if (this.dataAccountActive.type === "business") {
        this.parentidfile = parameter.folder_id;
        console.log(this.parentidfile);
        if (this.parentidfile === this.dataAccountActive["directory_id"]) {
          //  console.log("เข้า1");
          console.log("this.parentidfile ", this.parentidfile);
          console.log(
            "this.dataAccountActiv",
            this.dataAccountActive["directory_id"]
          );
          if(this.heardertextsearch){
            this.rootfile = [];
            this.buff = [];
            this.$router.replace("/mydrive");
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          } else {
            Toast.fire({ icon: "info", title: this.$t("toast.isSourceFileFolder") });
          }
        } else {
          if (
            this.parentidfile === "" ||
            this.parentidfile === null ||
            this.parentidfile === undefined
          ) {
            // console.log("เข้า2");
            console.log("this.parentidfile", this.parentidfile);
            this.noparent = this.$route.params.id;
            this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
            this.loadingdata = true;
            this.rootfile = [];
            this.buff = [];
            this.$router.push({
              path: "/directory/" + this.dataAccountActive["directory_id"],
            });
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          } else {
            console.log("เข้า3");
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.parentidfile });
            // this.parentfolder = this.$route.params.id;
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          }
        }
      } else {
        this.parentidfile = parameter.file_parent_folder_id;
        if (
          this.parentidfile === "" ||
          this.parentidfile === undefined ||
          this.parentidfile === null
        ) {
          this.parentidfile = parameter.folder_id;
        }
        if (this.parentidfile === this.dataAccountActive["directory_id"]) {
          // console.log("เข้า1");
          if(this.heardertextsearch){
            this.rootfile = [];
            this.buff = [];
            this.$router.replace("/mydrive");
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          } else {
            Toast.fire({ icon: "info", title: this.$t("toast.isSourceFileFolder") });
          }
        } else {
          if (
            this.parentidfile === "" ||
            this.parentidfile === null ||
            this.parentidfile === undefined
          ) {
            console.log("เข้า2");
            this.noparent = this.$route.params.id;
            this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
            this.loadingdata = true;
            this.rootfile = [];
            this.buff = [];
            this.$router.push({
              path: "/directory/" + this.dataAccountActive["directory_id"],
            });
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          } else {
            console.log("เข้า3");
            this.rootfile = [];
            this.buff = [];
            this.$router.push({ path: "/directory/" + this.parentidfile });
            // this.parentfolder = this.$route.params.id;
            this.fn_loadfileandfolder_scrolling_child();
            this.isAdvancedSearch = false;
          }
        }
      }
    },

    viewLog(message) {
      console.log("Message => ", message);
    },
    disableformulticlick(item) {
      // if(item === "Y"){
      //   if(this.filemultipledownload.length>1||this.statusmutipledownload==true){
      //     return true
      //   }
      // }else{
      //   return false
      // }
      // defalut
      return false;
    },
    clearparentvalue() {
      this.checkedList = [];
      this.filemultipledownload = [];
    },
    // changeLocalStorage(parameter) {
    //   if (parameter === localStorage.getItem("changeStoreUI")) {
    //     if (localStorage.getItem("changeStoreUI") === "true") {
    //       localStorage.setItem("changeStoreUI", "false");
    //       this.changeUI = false;
    //     } else if (localStorage.getItem("changeStoreUI") === "false") {
    //       localStorage.setItem("changeStoreUI", "true");
    //       this.changeUI = true;
    //     } else {
    //       console.log("localStore", localStorage.getItem("changeStoreUI"));
    //       localStorage.removeItem("changeStoreUI");
    //     }
    //   }
    // },
    changeLocalStorage(parameter) {
      if (parameter === this.changeUI.toString()) {
        if (this.changeUI === true) {
          localStorage.setItem("changeStoreUI", "false");
          this.changeUI = false;
          console.log("localStore", localStorage.getItem("changeStoreUI"));
        } else if (this.changeUI === false) {
          localStorage.setItem("changeStoreUI", "true");
          this.changeUI = true;
          console.log("localStore", localStorage.getItem("changeStoreUI"));
          // Add new
          this.close_header = true
          this.show_menumanage = false
        } else {
          console.log("localStore", localStorage.getItem("changeStoreUI"));
          localStorage.removeItem("changeStoreUI");
        }
      }
    },
    removeCheckedListItem(item) {
      if (
        item ==
        this.filemultipledownload.find((i) => {
          return i == item;
        })
      ) {
        let updateFileMultipleDownload = this.filemultipledownload.filter(
          (e) => e !== item
        );
        this.filemultipledownload = updateFileMultipleDownload;
        this.checkedList = updateFileMultipleDownload;
        console.log("UPDATE", this.filemultipledownload);
      } else {
        // ctrl+click เพิ่มค่า
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        if (
          item.status_lock_folder === "Y" ||
          item.status_lock_file === "Y" ||
          item.status_lock_data === "Y"
        ) {
          this.filemultipledownload.pop();
          this.checkedList.pop();
        }
        console.log("UPDATE ADD", this.filemultipledownload);
      }
    },
    vBadgeIcon(item) {
      let stringClass = "";
      if (item.file_status_sharelink === "Y") {
        stringClass = "link";
      } else {
        stringClass = "";
      }
      if (item.file_status_share === "Y") {
        if (stringClass == "") {
          stringClass = "group_add";
        } else {
          stringClass += " group_add";
        }
      }
      if (item.status_updating === "") {
        if (stringClass == "") {
          stringClass = "check";
        } else {
          stringClass += "check";
        }
      } else {
        stringClass += "";
      }
      return stringClass;
    },
    resetFile() {
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.filemultipledownload = [];
        this.checkedList = [];
      }
    },
    shiftSelect(item) {
      // คลิ๊ก item ที่ select ไว้ clear ค่าที่ไม่ได้ click ใน select ทั้งหมด
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.resetFile();
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        console.log("Clear", this.filemultipledownload);
      }
    },
    selectColor(file_id, type) {
      if (type === "card") {
        if (this.filemultipledownload.length < 1) {
        } else {
          console.log(this.display_grid);
          if (this.display_grid !== true) {
            for (let i = 0; i < this.filemultipledownload.length; i++) {
              // if (e === this.filemultipledownload[i].file_name) {
              //   return "#D6EAF8";
              // }
              if (file_id === this.filemultipledownload[i].file_id) {
                console.log("#D6EAF8");
                return "#D6EAF8";
              } else {
              }
            }
          }
        }
      } else if (type === "table") {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (file_id === this.filemultipledownload[i].file_id) {
              return "color:#2471A3 ";
            }
          }
        }
      } else {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            // if (e === this.filemultipledownload[i].file_name) {
            //   return "color:#2471A3 ";
            // }
            if (file_id === this.filemultipledownload[i].file_id) {
              return "color:#2471A3 ";
            }
          }
        }
      }
    },
    fn_handleDragSelectorChange(_checkedList) {
      console.log("Input:", _checkedList);
      this.choosecopy = [];
      // this.choosecopy = _checkedList;
      // เอาตัวซ้ำออก _checkedList เป็น parameter แค่รับมา filter ค่าซ้ำออก
      let newArrayFile = [...new Set(_checkedList)];
      // console.log('Output:',newArrayFile)
      // filter ครั้งแรกเอาพวกไฟล์บริษัทกับแผนกออก
      newArrayFile = newArrayFile.filter((element) => {
        return element.file_status != "M";
      });
      // fileter เอาค่าที่ไม่ต้องการออก
      if (newArrayFile.length > 1) {
        this.filemultipledownload = newArrayFile.filter((element) => {
          // return (
          //   element.status_lock_folder !== "Y" &&
          //   element.status_lock_file !== "Y" &&
          //   element.file_permission.edit === "True" &&
          //   element.status_lock_data !== "Y" &&
          //   element.editing_status !== "Y"
          // ); เปลี่ยนมาเช็คอันนี้
          return (
            element.status_lock_folder !== "Y" &&
            element.status_lock_file !== "Y" &&
            (element.file_permission_2 === "04" ||
              element.file_permission_2 === "05" ||
              element.file_permission_2 === "06") &&
            element.status_lock_data !== "Y" &&
            element.editing_status !== "Y"
          );
        });
      }
      this.choosecopy = this.filemultipledownload;
      // Toast แจ้งเตือน ------------------------------------------------------------------------------------------------------------------------------------------------------------
      // console.log("Output:", this.filemultipledownload);
      // // Toast ยิงไปบอกว่าบางไฟล์ที่คลุมไม่ขึ้น user นั้นไม่มีสิทธ์ หรือ ไฟล์มันล็อกอยู่
      // document.onmouseup=()=>{
      //   // Object.keys() ไว้เช็ค obj = {}
      //   if(Object.keys(this.filemultipledownload).length === 0 && this.checkedList.length > 1){
      //     console.log('CASE 1','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     // กรณี มีไฟล์แต่ติด permission ทั้งหมด
      //     // filemultipledownload = {} | newArrayFile > 1 (มากกว่า 0 ไม่ได้เพราะมีกรณีคลิ๊กไฟล์เดียว)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else if (Object.keys(this.filemultipledownload).length >= 1 && (Object.keys(this.filemultipledownload).length != newArrayFile.length) && newArrayFile.length != 0){
      //     // กรณีมีบางไฟล์ติด permission
      //     // filemultipledownload > 1 แต่มีบางตัว filter ออก | newArrayFile > 1
      //     // ดัก newArrayFile.length != 0 สำหรับเวลา dblclick เข้าโฟลเดอร์
      //     console.log('CASE 2','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else {
      //     console.log('else','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     // filemultipledownload 0 checkList 0
      //   }
      //   // console.log('filemultipledownload length:',Object.keys(this.filemultipledownload).length)
      //   // console.log('newArrayFile length:',Object.keys(_checkedList).length)
      //   // console.log('checkedList length:',this.checkedList.length)
      // }
    },
    multideletetotrash() {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        this.openchekmultidelete = true;
        // this.$store.dispatch("set_loading", true).then((res) => {});
        console.log("filemultipledownload", this.filemultipledownload);
        let datalist = [];
        this.status_data = "T";
      }
      // for (let i = 0; i < this.filemultipledownload.length; i++) {
      //   console.log(this.filemultipledownload[i]);
      //   if (this.filemultipledownload[i].file_type === "folder") {
      //     console.log("folder");
      //     datalist.push({
      //       data_id: this.filemultipledownload[i]["file_id"],
      //       data_type: this.filemultipledownload[i]["file_type"],
      //       data_status: "T",
      //     });
      //   } else {
      //     console.log("file");
      //     datalist.push({
      //       data_id: this.filemultipledownload[i]["file_id"],
      //       data_type: "file",
      //       data_status: "T",
      //     });
      //   }
      //}
      // let payload = {
      //   account_id: this.dataAccountId,
      //   user_id: this.dataUsername,
      //   data_list: datalist,
      //   // status_data:"T"
      // };
      // console.log("payload", payload);
      // this.axios
      //   .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload)
      //   .then((response) => {
      //     console.log(response);
      //     if (response.data.status === "OK") {
      //       this.$store.dispatch("set_loading", false).then((res) => {});
      //       Toast.fire({
      //         icon: "success",
      //         title: this.$t("toast.multiset.remove"),
      //       });
      //       this.statusmutipledownload = false;
      //       this.clearmultipledownload();
      //       // this.loadfolder();
      //       this.fn_loadfileandfolder_scrolling_child();
      //       this.loadstorage();
      //     } else {
      //       Toast.fire({
      //         icon: "error",
      //         title: response.data.errorMessage,
      //       });
      //     }
      //   });
    },
    // ติดดาว multi file/folder
    async setmultiupdate(filemultipledownload, status) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let count_item_nostar = 0;
        let count_item_star = 0;
        let status_star;
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (filemultipledownload[i].file_status !== status) {
            count_item_nostar++;
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          } else {
            count_item_star++;
            console.log("repeat", filemultipledownload[i]);
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          }
        }
        if (count_item_star == filemultipledownload.length) {
          console.log("nnnnnnnn");
          status_star = "N";
        } else if (count_item_nostar <= filemultipledownload.length) {
          console.log("ssssssss");
          status_star = "S";
        }
        payloadfolder = {
          list_folder: folderdata,
          account_id: this.dataAccountId,
          status_folder: status_star,
        };
        apifolder = "/api/multiple_update_status_folders";
        payloadfile = {
          list_file: filedata,
          account_id: this.dataAccountId,
          status_file: status_star,
        };
        apifile = "/api/multiple_update_status_files";

        console.log("folder", folderdata);
        console.log("file", filedata);
        try {
          let auth = await gbfGenerate.generateToken();
          let resultFile = await this.axios.post(
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile,
            payloadfile,
            {
              headers: { Authorization: auth.code },
            }
          );
          console.log("resultFile", resultFile);
          console.log("result =>", resultFile.data.result);
          auth = await gbfGenerate.generateToken();
          let resultFolder = await this.axios.post(
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder,
            payloadfolder,
            {
              headers: { Authorization: auth.code },
            }
          );
          console.log("resultFolder", resultFolder.status);
          console.log("result =>", resultFolder.data.result);
          if (
            resultFile.data.status == "OK" ||
            resultFolder.data.status == "OK"
          ) {
            if (status_star === "S") {
              // "ติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.star");
              // } else if (status === "T") {
              //   // "นำออกสำเร็จ";
              //   msgres = this.$t("toast.multiset.remove");
            } else {
              // "ยกเลิกติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.cancle");
            }
            Toast.fire({
              icon: "success",
              title: msgres,
            });
            // Add new
            this.close_header = true
            //OLD
            this.statusmutipledownload = false;
            this.clearmultipledownload();
            // this.loadfolder();
            this.fn_loadfileandfolder_scrolling_child();
            //NEW
            // this.statusmutipledownload = false;
            // this.clearmultipledownload();
            // this.loadfileandfolder();
          } else {
            console.log("ERROR");
            Toast.fire({
              icon: "error",
              title:
                resultFile.data.errorMessage || resultFolder.data.errorMessage,
            });
          }
        } catch (error) {
          console.log("result ERROR !!!", error);
          // แก้เลือกแต่ File await รอของ Folder ไม่โหลดค่าใหม่

          this.statusmutipledownload = false;
          this.clearmultipledownload();
          // this.loadfolder();
          this.fn_loadfileandfolder_scrolling_child();
          // ถ้าเอาออก มันจะไม่ update status ของตัว File
          if (status === "S") {
            // "ติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.star");
            // } else if (status === "T") {
            //   // "นำออกสำเร็จ";
            //   msgres = this.$t("toast.multiset.remove");
          } else {
            // "ยกเลิกติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.cancle");
          }
          Toast.fire({
            icon: "success",
            title: msgres,
          });
        }
      }
    },
    moveFileToFolder() {
      // โฟลเดอร์ปลายทาง
      // this.target_folder
      // ไฟล์ที่จะย้าย
      // this.holdfile_drag
      // payload and api
    },
    allowdrop(target) {
      // event.preventDefault()
    },
    drag(item) {
      // event.dataTransfer.setData("text", event)
      // console.log('drag ',event.dataTransfer.getData("text"))
      // ลากหลายไฟล์
      // this.checkedList
      // this.filemultipledownload
      // set file
      // this.holdfile_drag = []
      // if(this.filemultipledownload.length > 1){
      //   console.log('multi drag files',this.filemultipledownload)
      //   this.holdfile_drag = this.filemultipledownload
      // }else {
      //   console.log('Drag file',item.file_name)
      //   this.holdfile_drag.push(item)
      // }
      // let testrootfile = this.rootfile.filter((member) => {
      //   return member != item
      // })
      // console.log('move file to folder',testrootfile)
    },
    dropf(target) {
      // event.preventDefault()
      // console.log('Target folder',target)
      // console.log('before open dialog',this.open_confirmdialog)
      // // console.log('File drag',event.dataTransfer.getData('text'))
      // console.log('move file to',target,'\nitem',this.holdfile_drag)
      // this.target_folder = target
      // this.open_confirmdialog = true
    },
    dragenter(e) {
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
    },
    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    async drop(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
      //var filelist = document.querySelector('#filelist');
      // this.uploadFile(files);
      this.checkCCFolder = false;
      this.checkCC = false;
      // this.total = 0
      // this.count = 0
      let files = e.dataTransfer.files;
      this.folderupload = [];
      // console.log("eeeeeeeeeeeeeeeeeeeeee",e);
      console.log("Drop files:", files);
      //this.uploadfiles(files);
      //this.onDrop(files);
      // console.log(this.account_active["directory_id"]);
      var that = this;
      var that2 = await this;
      let parentfolder = "";
      let countt = 0;
      let total = 0;
      var beak = false;
      var traverseFileTreeCount = function self(item, path) {
        if (item.isFile) {
        } else if (item.isDirectory) {
          // Get folder contents
          var dirReader = item.createReader();
          dirReader.readEntries(async function(entries) {
            for (var i = 0; i < entries.length; i++) {
              that.total++;
              self(entries[i], path + item.name + "/");
            }
          });
        }
      };
      var traverseFileTree = function self(item, path) {
        if (that.checkCCFolder === true) {
          console.log("cancel");
          let objIndex = that.listDataUpload.findIndex(
            (obj) => obj.name === that.rootname
          );
          that.listDataUpload[objIndex]["value"] = 500;
          that.listDataUpload[objIndex]["status"] =
            "Operation canceled by the user.";
          that.listDataUpload[objIndex]["color"] = "red";
        } else {
          path = path || "";
          if (item.isFile) {
            // Get file
            item.file(function(file) {});
          } else if (item.isDirectory) {
            // Get folder contents
            var dirReader = item.createReader();
            dirReader.readEntries(async function(entries) {
              for (var i = 0; i < entries.length; i++) {
                console.log(entries[i]);
                if (that.checkCCFolder === true) {
                  console.log("breakkkkkkkkkkk");
                  let objIndex = that.listDataUpload.findIndex(
                    (obj) => obj.name === that.rootname
                  );
                  that.listDataUpload[objIndex]["value"] = 500;
                  that.listDataUpload[objIndex]["status"] =
                    "Operation canceled by the user. ";
                  that.listDataUpload[objIndex]["color"] = "red";

                  beak = true;
                  break;
                } else {
                  countt++;
                  if (that.parentfolderDD !== "") {
                    await that.uploadfolderDD(
                      "l",
                      entries[i],
                      files,
                      total,
                      countt
                    );
                  }
                  if (beak) {
                    console.log("breakkkkkkkkkkk");
                    break;
                  } else {
                    await self(entries[i], path + item.name + "/");
                  }
                }
              }
            });
          }
        }
      };
      let dataupload = [];
      var items = e.dataTransfer.items;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== "") {
          dataupload.push(files[i]);
        }
      }
      this.onDrop(dataupload, "", "Fi", "");
      for (var i = 0; i < items.length; ++i) {
        //console.log("itemmmmmm",items[i]);
        //if (items[i].kind == 'file') {

        let item = items[i].webkitGetAsEntry();
        console.log("itemssssssssssssss");
        await traverseFileTreeCount(item);
        if (item.isFile) {
        } else if (item.isDirectory) {
          if (this.checkCCFolder === true) {
          } else {
            await this.uploadfolderDD("r", item);
            await traverseFileTree(item);
            // Toast.fire({
            //   icon: "error",
            //   title: "ยังไม่รองรับการอัปโหลดโฟลเดอร์",
            //  });
          }
        }
      }

      // let item = items[i].webkitGetAsEntry(function(entry) {
      // displayEntry(entry.name + (entry.isDirectory ? ' [dir]' : ''));
      // });
      //console.log(this.traverseFileTree(item));
      // }
    },
    async getFile(fileEntry) {
      try {
        return await new Promise((resolve, reject) =>
          fileEntry.file(resolve, reject)
        );
      } catch (err) {
        console.log(err);
      }
    },
    char_count(str, letter) {
      var letter_Count = 0;
      for (var position = 0; position < str.length; position++) {
        if (str.charAt(position) == letter) {
          letter_Count += 1;
        }
      }
      return letter_Count;
    },
    async uploadfolderDD(status, item, files, totalfolder, countfolder) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex(
          (obj) => obj.name === this.rootname
        );
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] =
          "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";

        return;
      } else {
        console.log("totalfolder", this.total, countfolder);
        if (item.isFile) {
          let x = item.fullPath.search(item.name);
          let chpath = item.fullPath.slice(0, x - 1);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let filepath = "";
          for (let i = 0; i < res1.length - 1; i++) {
            filepath += res1[i] + "/";
          }
          console.log(filepath, "weeeeeeeeeeeeeeeee");
          for (let i = 0; i < this.folderupload.length; i++) {
            //console.log("tttttt================================================",this.folderupload[i]["folder_path"]);
            if (filepath === this.folderupload[i]["folder_path"] + "/") {
              if (this.checkCCFolder === true) {
                let objIndex = this.listDataUpload.findIndex(
                  (obj) => obj.name === this.rootname
                );
                source.cancel("Operation canceled by the user.");
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] =
                  "Cancel upload folder by user";
                this.listDataUpload[objIndex]["color"] = "red";

                //
              } else {
                await this.getFile(item).then((res) => {
                  this.onDrop(
                    res,
                    this.folderupload[i]["folder_id"],
                    "",
                    this.rootFolder,
                    totalfolder,
                    countfolder
                  );
                });
              }
              //  }
            }
          }
        } else if (item.isDirectory) {
          let x = item.fullPath.search(item.name);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let chpath1 = "";
          for (let i = 0; i < res1.length - 1; i++) {
            chpath1 += res1[i] + "/";
          }
          let chpath = item.fullPath.slice(0, x - 1);

          console.log("wwwwwwwwwwwwww", chpath, chpath1, this.folderupload);
          for (let i = 0; i < this.folderupload.length; i++) {
            console.log(this.folderupload[i]["folder_path"], "dddd", chpath1);
            if (chpath1 === this.folderupload[i]["folder_path"] + "/") {
              console.log(
                "checkpathhhhhh",
                chpath,
                chpath1,
                this.folderupload[i]["folder_path"]
              );
              this.parentfolderDD = this.folderupload[i]["folder_id"];
            }
          }
          if (status === "r") {
            let dataUpload = {};
            dataUpload["name"] = item.name;
            dataUpload["status"] = "กำลังอัปโหลด";
            dataUpload["value"] = 0;
            dataUpload["color"] = "green";
            dataUpload["type"] = "folder";
            dataUpload["icon"] = ["folder", "#FDD361"];
            dataUpload["showUpload"] = false;
            this.listDataUpload.push(dataUpload);
            // console.log(this.listDataUpload);

            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.account_active["directory_id"],
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
            //console.log("uploadheadfolder",payload);
            this.rootname = item.name;
          } else if (status === "l") {
            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.parentfolderDD,
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
          }
          let objIndex = this.listDataUpload.findIndex(
            (obj) => obj.name === this.rootname
          );
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();
          if (this.checkCCFolder === true) {
            let objIndex = this.listDataUpload.findIndex(
              (obj) => obj.name === this.rootname
            );
            source.cancel("Operation canceled by the user.");
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] =
              "Cancel upload folder by user";
            this.listDataUpload[objIndex]["color"] = "red";

            //
          }
          console.log("insertfolder", payload, this.parentfolderDD2);
          try {
            this.snackbar = true;
            let auth = await gbfGenerate.generateToken();
            let result = await this.axios({
              method: "POST",
              url:
                process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
                "/api/v2/insert_folder",
              cancelToken: source.token,
              data: payload,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);

                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;

                  this.listDataUpload[objIndex]["status"] =
                    "Operation canceled by the user.";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }

                // this.listDataUpload[objIndex]["value"] = 0;
                // this.listDataUpload[objIndex]["status"] = this.$t(
                //   "uploadFile.upload"
                // );
                // this.listDataUpload[objIndex]["color"] = "green";
              },
            });

            //this.axios.post(process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder", payload).then(response => {
            let a = item.fullPath.substring(1);
            let b = a.search("/");
            //  console.log("-----------------b----------------",b);
            if (result.data.status === "OK") {
              if (b === -1) {
                this.count++;
                //this.re1 = this.char_count(a,'/');
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                this.rootFolder = item.name;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);
                this.parentfolderDD = result.data.data.folder_id;
                let a = item.fullPath.substring(1);
                //console.log("reeeeeeeeeeeeeeeeee11111111111111",this.folderupload);
                //this.parentfolderDD2 = result.data.data.folder_id
                this.listDataUpload[objIndex]["value"] = parseInt(
                  (100 / this.total) * this.count
                );
                //this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                //this.listDataUpload[objIndex]["color"] = "green";
                console.log("========= root success ========", this.count);
              } else {
                this.count++;
                this.parentfolderDD2 = result.data.data.folder_id;
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);

                if (
                  this.total - this.count === 0 ||
                  this.total - this.count === 1
                ) {
                  //this.count++
                  this.listDataUpload[objIndex]["value"] = 100;
                  this.listDataUpload[objIndex]["status"] = "อัปโหลดสำเร็จ";
                  // this.listDataUpload[objIndex]["color"] = "green";
                  console.log("========= last success ========", this.count);
                  // this.loadfolder()
                  this.fn_loadfileandfolder_scrolling_child();
                } else {
                  this.listDataUpload[objIndex]["value"] = parseInt(
                    (100 / this.total) * this.count
                  );
                  // this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                  // this.listDataUpload[objIndex]["color"] = "green";
                  // this.count++;
                  console.log(
                    "========= other success ========",
                    this.count,
                    this.total,
                    this.folderupload
                  );
                }
              }
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] =
                result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
              // Toast.fire({
              //   icon: 'error',
              //   title: result.data.errorMessage
              // })
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              //this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
      }
    },

    removeEvent() {
      console.log("close");
      // var self = this;
      // self.closeDragDrop = true;
      // window.removeEventListener("dragenter", self.dragenter, true);
      // window.removeEventListener("dragleave", self.dragleave, true);
      // window.removeEventListener("dragover", self.dragover, true);
      // window.removeEventListener("drop", self.drop, true);
      this.removeevent = true;
      console.log(this.removeevent);
    },
    addEvent() {
      if (this.resolutionScreen >= 500) {
        console.log("open");
        // if (this.resolutionScreen >= 500) {
        //   var self = this;
        //   self.closeDragDrop = false;
        //   window.addEventListener("dragenter", self.dragenter, true);
        //   window.addEventListener("dragleave", self.dragleave, true);
        //   window.addEventListener("dragover", self.dragover, true);
        //   window.addEventListener("drop", self.drop, true);
        // }
        this.removeevent = false;
        console.log(this.removeevent);
      }
    },
    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      console.log("this.buff;", this.buff);
    },

    //เก็บ file_id ที่เวลาไม่เกิน 60 นาที
    sortCreDtmNewUpload() {
      // วันปัจจุบัน
      let today = new Date();
      this.dataCredtmNewUpload = [];
      this.arrayCredtmNewUpload = [];
      this.dataCredtmNewUpload = this.rootfile;
      console.log("this.dataCredtmNewUpload", this.dataCredtmNewUpload);
      for (let i = 0; i < this.dataCredtmNewUpload.length; i++) {
        // เช็คค่าว่าง
        if (
          this.dataCredtmNewUpload[i].file_createdtm === undefined ||
          this.dataCredtmNewUpload[i].file_createdtm === null ||
          this.dataCredtmNewUpload[i].file_createdtm === ""
        ) {
          continue;
        } else {
          // สร้างวันไว้คำนวณความต่างของเวลา
          let checkCre = new Date(
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[0] +
                this.dataCredtmNewUpload[i].file_createdtm[1] +
                this.dataCredtmNewUpload[i].file_createdtm[2] +
                this.dataCredtmNewUpload[i].file_createdtm[3]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[4] +
                this.dataCredtmNewUpload[i].file_createdtm[5]
            ) - 1,
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[6] +
                this.dataCredtmNewUpload[i].file_createdtm[7]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[8] +
                this.dataCredtmNewUpload[i].file_createdtm[9]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[10] +
                this.dataCredtmNewUpload[i].file_createdtm[11]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[12] +
                this.dataCredtmNewUpload[i].file_createdtm[13]
            )
          );
          let cal_differenceTime =
            Math.round(today.getTime() - checkCre.getTime()) / (1000 * 60);
          let differenceTime = cal_differenceTime.toFixed(0);
          if (differenceTime <= 60) {
            this.arrayCredtmNewUpload.push(this.dataCredtmNewUpload[i].file_id);
          } else {
            continue;
          }
        }
      }
    },

    // เช็คจากไฟล์ไม่เกิน 60 นาที
    checkNewDataFormDate(item) {
      let countNewDataFormDate = 0;
      for (let i = 0; i < this.arrayCredtmNewUpload.length; i++) {
        if (this.arrayCredtmNewUpload[i] === item.file_id) {
          countNewDataFormDate = 1;
          break;
        } else {
          continue;
        }
      }
      if (countNewDataFormDate === 0) {
        return false
      } else {
        return true
      }

    },

    // เนื่องจากการเปิดปิด preview file ไม่มีการรีเฟรชข้อมูล
    // จึงต้อสร้างตัวเก็บข้อมูลการเปิดปิดก่อนจะรีเฟรช
    // เช็คจากไฟล์ที่เปิดล่าสุดก่อนรีเฟรชหรือเปลี่ยนหน้า
    checkNewDataNoRefresh(item) {
      let countDataNoRefresh = 1;
      if (this.arrayOpenNewfiles.length === 0) {
        countDataNoRefresh = 1;
      } else {
        for (let i = 0; i < this.arrayOpenNewfiles.length; i++) {
          if (this.arrayOpenNewfiles[i] === item.file_id) {
            countDataNoRefresh = 0;
            break;
          } else {
            continue;
          }
        }
      }
      if (countDataNoRefresh === 0) {
        return false
      } else {
        return true
      }
    },

    // เช็คจากไฟล์ที่เปิดล่าสุดหลังรีเฟรชหรือเปลี่ยนหน้า
    checkNewDataRefresh(item) {
      let countDataRefresh = 0;
      for (let i = 0; i < this.rootfile.length; i++) {
        if (this.rootfile[i].file_id === item.file_id) {
          if (this.rootfile[i].last_view === null) {
            countDataRefresh = 1;
            break;
          } else {
            break;
          }
        } else {
          continue;
        }
      }
      if (countDataRefresh === 0) {
        return false
      } else {
        return true
      }
    },

    //ฟังก์ชันการเพิ่มข้อมูลการเปิดไฟล์ก่อนรีเฟรช
    cacheOpenNewfiles(item) {
      let countDupicateNewFile = 0;
      for (let i = 0; i < this.arrayOpenNewfiles.length; i++) {
        if (this.arrayOpenNewfiles[i] === item) {
          countDupicateNewFile = 1;
          break;
        } else {
          continue;
        }
      }
      if (countDupicateNewFile === 0) {
        this.arrayOpenNewfiles.push(item);
      } else {
        // console.log("this.arrayOpenNewfiles", this.arrayOpenNewfiles);
      }
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootfile = [];
    //   // this.buff = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }
    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       // var collator_folder = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    //       //console.log(collator_folder);
    //       // this.folderSort.sort(function(a, b) {
    //       //   return a.file_name.localeCompare(b.file_name);
    //       // });
    //       //this.compareObject(this.folderSort);
    //       this.folderSort = this.compareObject(this.folderSort, "1");
    //       //var collator_file = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    //       // this.fileSort.sort(function(a, b) {
    //       //   return a.file_name.localeCompare(b.file_name);
    //       // });
    //       //console.log(this.folderSort);
    //       this.fileSort = this.compareObject(this.fileSort, "1");
    //     } else {
    //       // this.folderSort.sort(function(a, b) {
    //       //   return b.file_name.localeCompare(a.file_name);
    //       // });
    //       this.folderSort = this.compareObject(this.folderSort, "2");
    //       // this.fileSort.sort(function(a, b) {
    //       //   return b.file_name.localeCompare(a.file_name);
    //       // });
    //       this.fileSort = this.compareObject(this.fileSort, "2");
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "owner") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootfile.push(this.sortdata[i][j]);
    //       //this.buff.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootfile;
    // },
    compareObject(item, status) {
      let arr_name = [];
      let arr_name_check = [];
      let arr_name_result = [];
      for (let i = 0; i < item.length; i++) {
        arr_name.push(item[i]["file_name"]);
      }
      var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      arr_name.sort(collator.compare);
      console.log("arr_name", arr_name, status);
      if (status === "2") {
        arr_name = arr_name.reverse();
        //console.log("222",arr_name);
      }

      for (let i = 0; i < arr_name.length; i++) {
        for (let j = 0; j < item.length; j++) {
          if (arr_name[i] === item[j]["file_name"]) {
            arr_name_result.push(item[j]);
          }
        }
      }
      console.log("arr_name_result", arr_name_result);

      return arr_name_result;
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
      this.checkedList = [];
      this.opendialogmultidownload_new = false;
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ดาวน์โหลดหลายไฟล์
    multipledownload() {
      // this.percentCompleted = 0;
      // var d = new Date();
      // this.datetime =
      //   d.toISOString().slice(0, 10) +
      //   " " +
      //   "at" +
      //   " " +
      //   this.zeroPadding(d.getHours(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getMinutes(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        console.log("ไฟล์ที่เลือก", this.filemultipledownload);
       // เก่า this.opendialogcheckmultidownload = true;
        this.opendialogmultidownload_new = true;
       
        // console.log("ไฟล์ที่เลือก",this.filemultipledownload);
        // let filedata = [];
        // for (let i = 0; i < this.filemultipledownload.length; i++) {
        //   if (this.filemultipledownload[i].file_type === "folder") {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: this.filemultipledownload[i]["file_type"],
        //     });
        //   } else {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: "file",
        //     });
        //   }
        // }
        // console.log("fileeeeeedataa", filedata);

        // let payload = {
        //   list: filedata,
        //   account_id: this.dataAccountId,
        // };
        // console.log("payyyyyloaaaad", payload);

        // // this.statusmutipledownload = true;
        // this.opensnackbarmultidownload = true;
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
        //     onDownloadProgress: (progressEvent) => {
        //       // console.log(progressEvent);
        //       let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        //       // console.log(progresspercent);
        //       if (progresspercent >= 95) {
        //         this.percentCompleted = 100;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //       // console.log("this.percentCompleted",this.percentCompleted);
        //       this.percent = this.percentCompleted;
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer",
        //   })
        //   .then((response) => {
        //     if (response.statusText === "OK") {
        //       this.percentCompleted = 100;
        //       console.log(response);
        //     }
        //     const blob = new Blob([response.data]);
        //     const content = response.headers["content-type"];
        //     saveAs(blob, this.datetime + ".zip");
        //     this.clearmultipledownload();
        //     // this.statusmutipledownload = false;
        //     this.opensnackbarmultidownload = false
        //   })
        //   .catch((error) => {
        //     this.opensnackbarmultidownload = false;
        //     // this.statusmutipledownload = false;
        //     this.$store.dispatch("show_loading", false);
        //     Toast.fire({
        //       icon: "error",
        //       title: this.$t("myinboxPage.msg.apierror"),
        //     });
        //     console.log(error);
        //   });
      }
    },

    filteredItems(search) {
      this.disablescrolling = true;
      if (search !== "") {
        this.rootfile = this.buff;
        let filldata = this.rootfile.filter((item) => {
          return (
            item.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
            item.file_type === "folder"
          );
        });
        this.rootfile = [];
        filldata.forEach((filedata) => {
          this.rootfile.push(filedata);
        });
      } else {
        this.rootfile = this.buff;
      }
    },

    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },

    onChildClick(value) {
      console.log(value);
    },

    sendParentfolder() {
      // if(this.$route.params.id === undefined){
      //   this.parentfolder = ''
      // }else{
      //   this.parentfolder = this.$route.params.id
      // }

       this.parentfolder = this.account_active["directory_id"];
    // console.log(this.parentfolder);
    },

    //ลบไฟล์ โฟลเดอร์
    async deletenewfunction(parameter) {
      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
        status_data: "T",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + 
          "/api/remove_file_folder_to_trash",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("delresponse", response);
            Toast.fire({
              icon: "success",
              title:
                this.$t("toast.text.remove") +
                parameter.file_name +
                " " +
                this.$t("toast.text.totrash"),
            });
            // this.loadfolder();
            this.fn_loadfileandfolder_scrolling_child();
            this.loadstorage();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },

    // ตั้งไฟล์ติดดาว
    async setPermissionFileStar(parameter, status, key) {
      if (parameter.status_lock_data === "Y" && key === "st") {
        console.log("setPermissionFileStar", parameter, status);
        this.opendialog_checklog = true;
        this.newdata = parameter;
        this.check_btn = key;
        this.star_status = status;
      } else {
        let api;
        let payload;
        let msgres;
        console.log("status", parameter);
        if (status === "T") {
          // this.opensdialogcheckdeleterightclick = true;
          this.deletenewfunction(parameter);
        } else {
          if (status === "S") {
            console.log("restore+++++++++++++++++");
            payload = {
              starred_id: [parameter.starred_id],
              data_id: [parameter.file_id],
              account_id: this.dataAccountId,
              process: "insert",
            };
            api = "/api/multiple_star";
          } else {
            payload = {
              starred_id: [parameter.starred_id],
              data_id: [parameter.file_id],
              account_id: this.dataAccountId,
              process: "delete",
            };
            api = "/api/multiple_star";
          }
          // if (parameter.file_type === "folder") {
          //   payload = {
          //     folder_id: parameter.file_id,
          //     folder_name: "",
          //     status_folder: status,
          //     move_to_id: "",
          //     account_id: parameter.account_sender,
          //     user_id: this.dataUsername,
          //   };
          //   api = "/api/v2/update_folder_id";
          // } else if (parameter.file_type !== "folder") {
          //   payload = {
          //     file_id: parameter.file_id,
          //     filename: "",
          //     folder_id: "",
          //     status_file: status,
          //     account_id: parameter.account_sender,
          //     user_id: this.dataUsername,
          //   };
          //   api = "/api/v2/update_file_id";
          // }

          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
              headers: { Authorization: auth.code },
            })
            .then((response) => {
              console.log(response);

              if (response.data.status === "OK") {
                this.display_grid = false;
                if (status === "S") {
                  // "ติดดาว " + parameter.file_name + " สำเร็จ"
                  msgres =
                    this.$t("toast.text.starred") +
                    parameter.file_name +
                    this.$t("toast.text.success");
                }
                // else if (status === "T") {
                //   // "นำ " + parameter.file_name + " ออกสำเร็จ"
                //   msgres =
                //     this.$t("toast.text.remove") +
                //     parameter.file_name +
                //     this.$t("toast.text.success");
                // }
                else {
                  // "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ"
                  msgres =
                    this.$t("toast.text.canclestart") +
                    parameter.file_name +
                    this.$t("toast.text.success");
                }

                Toast.fire({
                  icon: "success",
                  title: msgres,
                });
                // this.$emit('closecurrent')
                // this.loadfolder();
                this.fn_loadfileandfolder_scrolling_child();
                this.confirmdelete = false;

                // this.loadfileandfolder();
              } else {
                this.display_grid = false;
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            })
            .catch((error) => {
              this.display_grid = false;
              console.log(error);
              Toast.fire({
                icon: "error",
                // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
                title:
                  this.$t("toast.text.cannotstar") +
                  parameter.file_name +
                  this.$t("toast.text.textth"),
              });
            });
        }
      }
    },

    appsbarmultimovefile(e) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        e.preventDefault();
        this.x = e.clientX;
        this.y = e.clientY;
        this.sendParentfolder();
        this.opendialogmultimove = true;
      }
    },
    apphashtag() {
      if (this.filemultipledownload.length > 0) {
        this.opendialogeditdeletehashtag = true;
      } else {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    rightclickfileandfolder(e, file) {
      // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
      if (this.filemultipledownload.filter((item) => item.file_id === file.file_id).length === 0) {
        this.filemultipledownload = [file];
      }
      if (this.filemultipledownload.length > 1) {
        // console.log("filemultipledownload", this.filemultipledownload);
        // //เช็คว่า ล็อคอิน business หรือไม่
        // if (this.dataAccountActive.type == "Business") {
        //   if (this.navigate[0].text[1] == this.dataAccountActive["business_info"]["my_folder_id"] && this.role_level == "True") {
        //     // admin
        //     this.status_data = "TB";
        //   }
        // } else {
        this.status_data = "T";
        // }
        // เปิด multifilerightclick
        this.rightclickmultifile = true;
      }
      e.preventDefault();
      this.sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.triggered_rc_fileorfolder = true
      this.$nextTick(() => {
        this.currentfile = file;
        this.showcurrentMenu = true;
        if (this.showMenu === true) {
          this.showcurrentMenu = false;
        } else if (this.rightclickmultifile === true) {
          this.showcurrentMenu = false;
        } 
      });
    },
    gotofolder(_folderid) {
      if (!_folderid) {
        return Toast.fire({ icon: "error", title: this.$t("toast.folderPathInvalid") });
      }
      //console.log(this.navigate);
      if (_folderid === this.dataAccountActive["directory_id"]) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        // this.$router.replace("/mydrive");
        // this.$router.push("/mydrive");
        this.fn_loadfileandfolder_scrolling_child({ cal_offset: 0 });
        this.isAdvancedSearch = false;
      } else {
        this.filemultipledownload = [];
        this.checkedList = [];
        this.loadingdata = true;
        // this.$router.replace({ path: "/directory/" + _folderid });
        this.$router.push({ path: "/directory/" + _folderid });
        // this.fn_loadfileandfolder_scrolling_child();
        // this.parentfolder = this.$route.params.id;
        this.isAdvancedSearch = false;
      }
    },
    // เข้าไปที่ โฟลเดอร์
    gotodirectory(folderid, type, departmentid, file, item) {
      // ให้ reset ค่าของ filemultipledownload กับ checklist เวลากดเม้า Toast จะได้ไม่แจ้งเตือน
      this.resetFile();
      // if (file.file_type === "folder") {
      //   console.log("department_access_id", file.file_department);
      // this.$store.dispatch("department_access_id", file.file_department);
      // this.$router.push({ path: `/directory/` + `${file.file_id}` });
      // this.$router.replace({ path: "/directory/" + file.file_id });
      // const destination = file.file_id
      // this.$router.push({ name: 'directory', params: { destination  } })
      // this.loadfolder();
      // } else {
      //   this.currentfile = file;
      //   this.openpreviewfile = true;
      // }
      // console.log("folder",file);
      // if(file.status_lock_folder === 'Y'&& type === 'folder' || file.status_lock_file === 'Y' && type !== 'folder'){
      //   this.opendialog_checklog = true
      //   this.newdata = file
      //   console.log("newdata",this.newdata);
      // }else{
      this.checkgotodirectory(file, item);
      // }else{
      //   console.log("file",file);
      //   this.checkgotodirectory(file)
      // }
    },

    checkgotodirectory(file, item) {
      if (file.file_type === "folder") {
        this.$store.dispatch("department_access_id", file.file_department);
        VueCookies.set("taxbox_status_put",file.taxbox_status)
        this.$router.push({ path: "/directory/" + file.file_id });

        // this.loadfolder();
      } else {

        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
        // console.log("checkinet_jv",checkinet_jv);
        // if(checkinet_jv === -1){
      // let checkinet_jv = VueCookies.get("feature_onlyoffice")
      // if(checkinet_jv === 'false'){
        let checkinet_jv = this.feature_onlyoffice;
        if(checkinet_jv === false){
          this.param_preview = "preview";
          this.openpreviewfile = true;
          this.currentfile = file;
        }else{
        if(
          // (['04', '05', '06'].includes(file.file_permission_2)) &&
          (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
          file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()))
        ){
          this.param_preview = "editfileonline";
        }else{
          this.param_preview = "preview";
        }
        }

          this.currentfile = file;

          if(file.editing_status === 'S'){
              Toast.fire({
                  icon: "warning",
                  title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
                });
          }else if(file.editing_status === 'Y'){
            if(file.last_update_name.name_th === 'ฉัน'){
              this.openpreviewfile = true;
            }else{
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }
          }else{
            this.openpreviewfile = true;
          }


          
        // this.newdata = file
        // if(file.status_encrypt === 'N'){
        // this.openpreviewfile = true;
        // }else{
        //   this.opendialogdownloadvideo = true;
        // }
        //this.arrayfile_lastview = item
        this.arrayfile = item;
      }
    },

    // พรีวิวไฟล์
    newpreviewfile(file) {
      // console.log(file);
      if (file.file_type !== "folder") {
        this.currentfile = file;
        this.opennewpreviewfile = true;
        console.log("open", this.opennewpreviewfile);
      }
    },

    show(e) {
      e.preventDefault();
      this.sendParentfolder();
      this.showMenu = false;
      if(this.triggered_rc_fileorfolder === true){
        //do not work this 
        this.showPrevMenu = true
      }
      this.x = e.clientX;
      this.y = e.clientY;
       this.$nextTick(() => {
        if (
          this.showcurrentMenu === true ||
          this.rightclickmultifile === true
        ) {
          if(this.showPrevMenu === true){
            this.showMenu = false
          }else{
            this.showMenu = true
            this.showcurrentMenu = false
          }
        } else {
          // setTimeout(() => {
          this.showMenu = true;
          // }, 300);
        }
        this.showPrevMenu = false
        this.triggered_rc_fileorfolder = false
      });
      
    },

    cancelAll() {
      this.snackbar = false;
      this.checkCCFolder = true;
      this.checkCC = true;
      //console.log("test");
      this.total = 0;
      this.count = 0;
      // this.loadfolder();
      this.fn_loadfileandfolder_scrolling_child();
    },

    cancel(item) {
      this.itemC = item;
      console.log("this.itemC", this.itemC);
      var res = this.itemC["name"].split(".");
      if (res[1] === undefined) {
        this.checkCCFolder = true;
        //console.log("test");
        let objIndex = this.listDataUpload.findIndex(
          (obj) => obj.name === this.rootname
        );
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] =
          "Operation canceled by the user.";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        this.checkCC = true;
        console.log("this.checkCC", this.checkCC);
      }
    },

    onDrop(files, folderid, status, rootfolder, totalfolder, countfolder) {
      console.log("status", status);
      if (status === "Fi") {
        this.listDataUpload = [];
        for (let i = 0; i < files.length; i++) {
          let dataUpload = {};
          var res = files[i].name.split(".");
          console.log("files[i].name", files[i].name);
          console.log("res", res[1]);
          dataUpload["name"] = files[i].name;
          dataUpload["status"] = "กำลังอัปโหลด";
          dataUpload["value"] = 0;
          dataUpload["color"] = "green";
          dataUpload["icon"] = gb_setIconFiles.iconExtensionFilesOnly_new(
            res[1]
          );
          dataUpload["showUpload"] = false;
          this.listDataUpload.push(dataUpload);
          console.log("filesssssssssssssssssss");
          //this.listDataUpload = this.listDataUpload.filter(item => item !== this.itemC)
          this.uploadfiles(files[i]);
        }
        // console.log(this.listDataUpload);
      } else {
        this.uploadfilesFolder(
          files,
          folderid,
          rootfolder,
          totalfolder,
          countfolder
        );
      }
    },

    async uploadfilesFolder(
      files,
      folderid,
      rootfolder,
      totalfolder,
      countfolder
    ) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex(
          (obj) => obj.name === rootfolder
        );
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] =
          "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";
        return;
      } else {
        console.log("uploadfilesFolder", totalfolder, countfolder);

        let objIndex = this.listDataUpload.findIndex(
          (obj) => obj.name === rootfolder
        );

        const formData = new FormData();

        //formData.append("file", files[i]);
        formData.append("file", files);
        //formData.append("filname", files[i].name);
        formData.append("size_file", files.size);
        //formData.append("size_file", files[i].size);

        formData.append("folder_id", folderid);
        //console.log(folderid);

        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.dataDepartmentAccessId);
        formData.append("account_id", this.dataAccountId);
        //console.log("hover2",formData);
        // the request itself
        //if (files[i].size >= 500000000) {
        if (files.size >= 2147483648) {
          this.listDataUpload[objIndex]["value"] = 500;
          this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
          this.listDataUpload[objIndex]["color"] = "red";
        } else {
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();
          //  console.log("lklklklklkl", source);
          // console.log(this.checkCC);
          if (this.checkCCFolder === true) {
            source.cancel("Operation canceled by the user.");
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] =
              "Cancel upload folder by user";
            this.listDataUpload[objIndex]["color"] = "red";

            //
          }
          try {
            let auth = await gbfGenerate.generateToken();
            this.snackbar = true;
            let result = await this.axios({
              method: "POST",
              url:
                process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
              cancelToken: source.token,
              data: formData,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);
                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;
                  this.listDataUpload[objIndex]["status"] =
                    "Cancel upload folder by user";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }
              },
            });
            // console.log("result", result);
            if (result.data.status === "OK") {
              if (this.total - this.count === 0) {
                //this.count--;
                this.listDataUpload[objIndex]["value"] = 100;
                this.listDataUpload[objIndex]["status"] = result.data.message;
                this.listDataUpload[objIndex]["color"] = "green";
                // this.loadfolder()
                this.fn_loadfileandfolder_scrolling_child();
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt(
                  (100 / this.total) * this.count
                );
                // this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                // this.listDataUpload[objIndex]["color"] = "green";
                this.count++;
                //console.log(this.count);
              }
            } else if (result.data.status === 413) {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] =
                result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            } else if (result.data.errorCode === "ER999") {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = "Can not upload";
              this.listDataUpload[objIndex]["color"] = "red";
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] =
                result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              // this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
      }
      // }
      // this.loadfolder();
      // this.$store
      //   .dispatch("check_storage", this.dataAccountId)
      //   .then((res) => {});
      // console.log("this.dataAccountId------", this.dataAccountId);
    },

    async uploadfiles(files, folderid, rootfolder) {
      // console.log("hover",folderid,rootfolder);
      let parent = this.sendParentfolder();
      //console.log("process start",parent);
      // formData.append('file', file, file.name);
      // for (let i = 0; i < files.length; i++) {
      let objIndex = this.listDataUpload.findIndex(
        (obj) => obj.name === files.name
      );

      const formData = new FormData();

      //formData.append("file", files[i]);
      formData.append("file", files);
      //formData.append("filname", files[i].name);
      formData.append("size_file", files.size);
      //formData.append("size_file", files[i].size);
      if (folderid === undefined) {
        formData.append("folder_id", this.parentfolder);
        //console.log(this.parentfolderDD);
      } else {
        formData.append("folder_id", folderid);
        //console.log(folderid);
      }
      formData.append("user_id", this.dataUsername);
      formData.append("department_id", this.dataDepartmentAccessId);
      formData.append("account_id", this.dataAccountId);
      //console.log("hover2",formData);
      // the request itself
      //if (files[i].size >= 500000000) {
      if (files.size >= 2147483648) {
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        let CancelToken = this.axios.CancelToken;
        let source = new CancelToken.source();
        //  console.log("lklklklklkl", source);
        console.log("this.checkCC", this.checkCC);
        try {
          let auth = await gbfGenerate.generateToken();
          this.snackbar = true;
          let result = await this.axios({
            method: "POST",
            url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
            cancelToken: source.token,
            data: formData,
            headers: { Authorization: auth.code },

            onUploadProgress: (e) => {
              //console.log(this.checkCC);
              console.log("this.checkCC", this.checkCC);
              if (this.checkCC === true) {
                source.cancel("Operation canceled by the user.");
                //console.log(result.data.errorMessage);
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] =
                  "canceled by the user.";
                this.listDataUpload[objIndex]["color"] = "red";

                //
              }

              // this.listDataUpload[objIndex]["value"] = 0;
              // this.listDataUpload[objIndex]["status"] = this.$t(
              //   "uploadFile.upload"
              // );
              //this.listDataUpload[objIndex]["color"] = "green";
              if ((e.loaded / e.total) * 100 >= 95) {
                this.listDataUpload[objIndex]["value"] = 95;
                this.timeout = (e.loaded / e.total) * 100000;
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt(
                  (e.loaded / e.total) * 100
                );
              }
            },
          });
          console.log("result", result);
          if (result.data.status === "OK") {
            this.listDataUpload[objIndex]["value"] = 100;
            this.listDataUpload[objIndex]["status"] = result.data.message;
            this.listDataUpload[objIndex]["color"] = "green";
          } else if (result.data.status === 413) {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          } else if (result.data.errorCode === "ER999") {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Can not upload";
            this.listDataUpload[objIndex]["color"] = "red";
          } else {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          }
        } catch (err) {
          // check if the request was cancelled
          if (this.axios.isCancel(err)) {
            console.log("eeererrreerrrrorrr", err.message);
            this.listDataUpload[objIndex]["status"] = err.message;
            this.checkCC = false;
            // console.log(this.checkCC);
          }

          //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
        }
      }
      // }
      //  this.loadfolder();
      this.fn_loadfileandfolder_scrolling_child();
      // this.$store
      //   .dispatch("check_storage", this.dataAccountId)
      //   .then((res) => {});
      // console.log("this.dataAccountId------", this.dataAccountId);
    },

    async fulltextsearch(text) {
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      let payload = {
        text: text["keyword"],
        account_id: this.dataAccountId,
        type_search: text["type"],
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/full_text_search",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            for (let i = 0; i < response["data"]["data"].length; i++) {
              let datafolder = {};
              datafolder["editing_status"] =
                response.data.data[i]["editing_status"];
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["file_id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = "";
              datafolder["file_owner_th"] = "";
              datafolder["file_linkshare"] = "";
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["file_date"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_status_share"] = "";
              datafolder["file_status_sharelink"] = "";
              datafolder["file_type"] = response.data.data[i]["data_type"];
              datafolder["file_department"] = "";
              // datafolder[
              //   "file_icon"
              // ] = gb_setIconFiles.iconExtensionFilesOnly_new(
              //   response.data.data[i]["data_type"]
              // );
              datafolder[
                "file_icon"
              ] = this.fn_setThumbnailIcon(
                response.data.data[i]["data_type"]
              );
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
          } else {
            console.log(response.data);
          }
        });
    },

    //รับข้อมูลจาก toolbar
    // fn_advancesearch(_param) {
    //   this.heardertextsearch = true;
    //   this.disablescrolling = true;
    //   this.rootfile = [];
    //   this.buff = [];
    //   const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch(
    //     _param,
    //     this.dataAccountId
    //   );
    //   console.log("datafolder", datafolder);
    //   let count_file = datafolder.filter((item) => {
    //     return item.file_type === "file";
    //   });
    //   let count_folder = datafolder.filter((item) => {
    //     return item.file_type === "folder";
    //   });
    //   this.totaldata = {
    //     totalall: count_file.length + count_folder.length,
    //     totalfile: count_file.length,
    //     totalfolder: count_folder.length,
    //   };
    //   this.rootfile = datafolder;
    //   for (let index = 0; index < this.rootfile.length; index++) {
    //     const element = this.rootfile[index];
    //     element.file_thumbnail = "";
    //     // let typeFile = element.file_name.split(".");
    //     let typefile = response.data.data[i].data_type === "folder" ? "folder" : element.file_name.split(".");
    //     this.fn_loadthumbnail(
    //       element.file_id,
    //       typeFile[typeFile.length - 1],
    //       element.file_size,
    //       element.status_lock_data
    //     );
    //   }
    //   this.buff = datafolder;
    //   this.filemultipledownload = [];
    // },

    async fn_advancesearch_new(_param){
 
    this.path_folder_split = this.$router.currentRoute.path.split("/");
    this.path_folder = "";
    if (this.path_folder_split[1] === "directory") {
      this.path_folder = this.path_folder_split[2];
    } else if (this.path_folder_split[1] === "directorysharefile") {
      this.path_folder = this.path_folder_split[2];
    } else {
      if (this.dataAccountActive["type"] === "Business") {
        this.path_folder = this.dataAccountActive["business_info"][
          "my_folder_id"
        ];
      } else {
        this.path_folder = this.dataAccountActive["directory_id"];
      }
    }
    let cal_limit = this.size;
    let cal_offset = this.size * (this.pagee++ - 1);

    console.log("cal_offset",typeof(cal_offset));
    console.log("cal_offset",cal_offset);
    let payload;
    // console.log(cal_offset)
    this.heardertextsearch = true;
    if (cal_offset == 0) {
      this.disablescrolling = true;
      this.disablescrolling_advancedsearch = true;
      this.loaddataprogress = true;
      this.listdatarootfile = [];
      this.rootfile = [];
      this.buff = [];
      this.count_data_adv_ = 0;
    }

    if(cal_offset == 0){
      const data_param = JSON.stringify(_param);
      sessionStorage.setItem("advancedsearch_new", data_param);
      let _param2_ = sessionStorage.getItem("advancedsearch_new");
      let _param2 = JSON.parse(_param2_);
      this.data_search = _param2;
      // console.log("page",this.page);
      // console.log("cal_offset",typeof(cal_offset));
      // console.log("cal_offset",cal_offset);

      console.log("เข้า1");
      console.log("_param",_param);
      console.log("data_search",this.data_search);


      if (this.path_folder_split[1] === "directorysharefile") {
        payload = {
          account_id: this.dataAccountId,
          cre_dtm: this.data_search.cre_dtm,
          to_dtm: this.data_search.to_dtm,
          type_search: this.data_search.type_search,
          name: this.data_search.name,
          cctv:this.data_search.cctv,
          folder_id: this.data_search.folder_id,
          dept_id: this.data_search.dept_id,
          limit: cal_limit,
          offset: cal_offset,
        };
        console.log("fake payload", payload);
        // this.processdelete = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              `/api/shared/advanced_search`,
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then(async (response) => {
            console.log("advance search", response.data);
            if (response.data.status === "OK") {
              this.loaddataprogress = false;
              for(let i=0; i < response.data.data.length; i++){
                let datafolder = {};
                let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
                  datafolder["account_reciever"] = "";
                  datafolder["account_sender"] = this.dataAccountId;
                  if ("file_id" in response.data.data) {
                    datafolder["file_id"] = response.data.data[i]["file_id"];
                  } else {
                    datafolder["file_id"] = response.data.data[i]["id"];
                  }
                  datafolder["file_name"] = response.data.data[i]["name"];
                  datafolder["file_size"] = response.data.data[i]["size"];
                  datafolder["file_status"] = response.data.data[i]["status"];
                  datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
                  datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                  datafolder["file_linkshare"] = "";
                  datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                  datafolder["file_date"] = "";
                  datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
                  datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
                  if ("permission_account" in response.data.data) {
                    datafolder["file_permission"] = response.data.data[i]["permission_account"];
                  } else {
                    datafolder["file_permission"] = {
                      admin_data: "False",
                      delete: "False",
                      download: "False",
                      edit: "False",
                      upload: "False",
                      view_only: "True",
                    };
                  }

                  datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                  datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
                  datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
                  datafolder["file_status_share"] = response.data.data[i]["status_share"];
                  datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
                  datafolder["file_type"] = response.data.data[i]["data_type"];
                  datafolder["data_type"] = typefile[typefile.length - 1];
                  datafolder["status_updating"] = response.data.data[i]["status_updating"];
                  datafolder["file_department"] = "";
                  datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                  datafolder["status_updating"] = response.data.data[i]["status_updating"];
                  datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
                  datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
                  datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
                  datafolder["file_thumbnail"] = "";
                  datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                  datafolder["editing_status"] = response.data.data[i]["editing_status"];
                  datafolder["update_by"] = response.data.data[i]["update_by"];
                  datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
                  datafolder["version_tag"] = response.data.data[i]["version_tag"];
                  datafolder["tag_id"] = response.data.data[i]["tag_id"];
                  datafolder["hashtag"] = response.data.data[i]["hashtag"];
                  datafolder["status_expired"] = response.data.data[i]["status_expired"];
                  datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
                  datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

                  // this.rootfile.push(datafolder);
                  // this.buff.push(datafolder);
                  this.listdatarootfile.push(datafolder);
                  datafolder["status_hilightword"] = true
                
              }
              this.count_data_adv_ = response.data.total;
              // this.index_loop = 0;
              // this.num_count = 30;
              this.looplistdatarootfile();
              // for (let index = 0; index < this.rootfile.length; index++) {
              //   const element = this.rootfile[index];
              //   element.file_thumbnail = "";
              //   let typeFile = element.file_name.split(".");
              //   await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                
              //   if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
              //     await this.get_name_owner(this.rootfile[index])
              //   }
              // }
              // console.log("this.count_data_adv_",this.count_data_adv_);
              // this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
              // console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);


            } else {
              console.log("ERR");
              this.disablescrolling_advancedsearch = true;
              this.loaddataprogress = false;
              this.load_advanced = false;
            }
          });
      } else {
        payload = {
          account_id: this.dataAccountId,
          cre_dtm: this.data_search.cre_dtm,
          to_dtm: this.data_search.to_dtm,
          type_search: this.data_search.type_search,
          name: this.data_search.name,
          cctv:this.data_search.cctv,
          folder_id: this.data_search.folder_id,
          dept_id: this.data_search.dept_id,
          limit: cal_limit,
          offset: cal_offset,
        };
        console.log("fake payload", payload);
        // this.processdelete = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              `/api/v2/advanced_search`,
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then(async (response) => {
            console.log("advance search cal_offset", response.data.data);
            if (response.data.status === "OK") {
              this.isAdvancedSearch = true;
              this.loaddataprogress = false;
              this.count_data_adv_ = response.data.total;
              // this.fn_advancesearch(response);
              for(let i=0; i < response.data.data.length; i++){
                let datafolder = {};
                let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
                  datafolder["account_reciever"] = "";
                  datafolder["account_sender"] = this.dataAccountId;
                  if ("file_id" in response.data.data) {
                    datafolder["file_id"] = response.data.data[i]["file_id"];
                  } else {
                    datafolder["file_id"] = response.data.data[i]["id"];
                  }
                  datafolder["file_name"] = response.data.data[i]["name"];
                  datafolder["file_size"] = response.data.data[i]["size"];
                  datafolder["full_path"] = response.data.data[i]["full_path"];
                  datafolder["full_path_id"] = response.data.data[i]["full_path_id"];
                  let fullPathName = datafolder["full_path"] ? datafolder["full_path"].split('/') : [];
                  let fullPathId = datafolder["full_path_id"] ? datafolder["full_path_id"].split('/') : [];
                  datafolder["file_location"] = fullPathName.map((name, index) => {
                    let fileExtensions = [".jpeg", ".jpg", ".png", ".gif", ".pdf", ".xlsx", ".pptx", ".xls", ".ppt", ".doc", ".docx", ".mp4", ".mov", ".txt", ".xml", ".xlsb", ".html", ".csv"];
                    let fileType = fileExtensions.some(ext => name.toLowerCase().endsWith(ext)) ? "file" : "folder";
                    return {
                      folder_id: fullPathId[index],
                      folder_name: name,
                      file_type: fileType
                    };
                  });
                  datafolder["file_location"].pop();
                  if (datafolder["file_location"].length <= 3) {
                    datafolder["display_file_location"] = datafolder["file_location"];
                  } else {
                    datafolder["display_file_location"] = [
                      datafolder["file_location"][0], {
                        folder_id: '',
                        folder_name: '...',
                        file_type: ''
                      }, 
                      datafolder["file_location"][datafolder["file_location"].length - 1]
                    ];
                  }
                  datafolder["is_expand_file_location"] = false;

                  datafolder["file_status"] = response.data.data[i]["status"];
                  datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
                  datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                  datafolder["file_linkshare"] = "";
                  datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                  datafolder["file_date"] = "";
                  datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
                  datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
                  if ("permission_account" in response.data.data) {
                    datafolder["file_permission"] = response.data.data[i]["permission_account"];
                  } else {
                    datafolder["file_permission"] = {
                      admin_data: "False",
                      delete: "False",
                      download: "False",
                      edit: "False",
                      upload: "False",
                      view_only: "True",
                    };
                  }

                  datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                  datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
                  datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
                  datafolder["file_status_share"] = response.data.data[i]["status_share"];
                  datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
                  datafolder["file_type"] = response.data.data[i]["data_type"];
                  datafolder["data_type"] = typefile[typefile.length - 1];
                  datafolder["status_updating"] = response.data.data[i]["status_updating"];
                  datafolder["file_department"] = "";
                  datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                  datafolder["status_updating"] = response.data.data[i]["status_updating"];
                  datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
                  datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
                  datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
                  datafolder["file_thumbnail"] = "";
                  datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                  datafolder["editing_status"] = response.data.data[i]["editing_status"];
                  datafolder["update_by"] = response.data.data[i]["update_by"];
                  datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
                  datafolder["version_tag"] = response.data.data[i]["version_tag"];
                  datafolder["tag_id"] = response.data.data[i]["tag_id"];
                  datafolder["hashtag"] = response.data.data[i]["hashtag"];
                  datafolder["status_expired"] = response.data.data[i]["status_expired"];
                  datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
                  datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
                  datafolder["status_hilightword"] = true

                  // this.rootfile.push(datafolder);
                  // this.buff.push(datafolder);
                  this.listdatarootfile.push(datafolder);
                
              }
              // this.index_loop = 0;
              // this.num_count = 30;
              this.looplistdatarootfile();
              // for (let index = 0; index < this.rootfile.length; index++) {
              //   const element = this.rootfile[index];
              //   element.file_thumbnail = "";
              //   let typeFile = element.file_name.split(".");
              //   await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                
              //   if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
              //     await this.get_name_owner(this.rootfile[index])
              //   }
              // }
              // console.log("this.count_data_adv_",this.count_data_adv_);
              // this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
              // console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);

              // this.processloader = false;
              // this.heardertextsearch = true;
              // this.disablescrolling = true;
              // this.disablescrollfilter = true;
              // this.disablescrolling_advancedsearch = true;
              // this.load_advanced = false;
            } else {
              console.log("ERR");
              // this.processloader = false;
              // this.heardertextsearch = true;
              // this.disablescrolling = true;
              // this.disablescrollfilter = true;
              this.disablescrolling_advancedsearch = true;
              this.loaddataprogress = false;
              this.isAdvancedSearch = false;
              // this.load_advanced = false;
            }
          });
      }
    }
    // else{
    //   // this.pagee++
    //   console.log("เข้า2");
    //   this.disablescrolling_advancedsearch = false;
    //   // this.loaddataprogress = false;
    //   let _param2_ = sessionStorage.getItem("advancedsearch_new");
    //   let _param2 = JSON.parse(_param2_);
    //   this.data_search = _param2;
    //   console.log("_param2222",this.data_search);
    //   if (this.path_folder_split[1] === "directorysharefile") {
    //     payload = {
    //       account_id: this.dataAccountId,
    //       cre_dtm: this.data_search.cre_dtm,
    //       to_dtm: this.data_search.to_dtm,
    //       type_search: this.data_search.type_search,
    //       name: this.data_search.name,
    //       cctv:this.data_search.cctv,
    //       folder_id: this.data_search.folder_id,
    //       dept_id: this.data_search.dept_id,
    //       limit: cal_limit,
    //       offset: cal_offset,
    //     };
    //     console.log("fake payload", payload);
    //     // this.processdelete = true;
    //     let auth = await gbfGenerate.generateToken();
    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //           `/api/shared/advanced_search`,
    //         payload,
    //         {
    //           headers: { Authorization: auth.code },
    //         }
    //       )
    //       .then(async (response) => {
    //         console.log("advance search", response.data);
    //         if (response.data.status === "OK") {
    //           this.loaddataprogress = false;
    //           // this.fn_advancesearch_loop(response);
    //           for(let i=0; i < response.data.data.length; i++){
    //             let datafolder = {};
    //               let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
    //               datafolder["account_reciever"] = "";
    //               datafolder["account_sender"] = this.dataAccountId;
    //               if ("file_id" in response.data.data) {
    //                 datafolder["file_id"] = response.data.data[i]["file_id"];
    //               } else {
    //                 datafolder["file_id"] = response.data.data[i]["id"];
    //               }
    //               datafolder["file_name"] = response.data.data[i]["name"];
    //               datafolder["file_size"] = response.data.data[i]["size"];
    //               datafolder["file_status"] = response.data.data[i]["status"];
    //               datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
    //               datafolder["file_owner_th"] = response.data.data[i]["name_th"];
    //               datafolder["file_linkshare"] = "";
    //               datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
    //               datafolder["file_date"] = "";
    //               datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
    //               datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
    //               if ("permission_account" in response.data.data) {
    //                 datafolder["file_permission"] = response.data.data[i]["permission_account"];
    //               } else {
    //                 datafolder["file_permission"] = {
    //                   admin_data: "False",
    //                   delete: "False",
    //                   download: "False",
    //                   edit: "False",
    //                   upload: "False",
    //                   view_only: "True",
    //                 };
    //               }

    //               datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
    //               datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
    //               datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
    //               datafolder["file_status_share"] = response.data.data[i]["status_share"];
    //               datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
    //               datafolder["file_type"] = response.data.data[i]["data_type"];
    //               datafolder["data_type"] = typefile[typefile.length - 1];
    //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
    //               datafolder["file_department"] = "";
    //               datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
    //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
    //               datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
    //               datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
    //               datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
    //               datafolder["file_thumbnail"] = "";
    //               datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
    //               datafolder["editing_status"] = response.data.data[i]["editing_status"];
    //               datafolder["update_by"] = response.data.data[i]["update_by"];
    //               datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
    //               datafolder["version_tag"] = response.data.data[i]["version_tag"];
    //               datafolder["tag_id"] = response.data.data[i]["tag_id"];
    //               datafolder["hashtag"] = response.data.data[i]["hashtag"];
    //               datafolder["status_expired"] = response.data.data[i]["status_expired"];
    //               datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
    //               datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

    //               this.rootfile.push(datafolder);
    //               this.buff.push(datafolder);
                
    //           }
    //           for (let index = 0; index < this.rootfile.length; index++) {
    //             const element = this.rootfile[index];
    //             element.file_thumbnail = "";
    //             let typeFile = element.file_name.split(".");
    //             await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                
    //             if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
    //               await this.get_name_owner(this.rootfile[index])
    //             }
    //           }
    //           console.log("this.count_data_adv_",this.count_data_adv_);
    //           this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    //           console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
    //           // this.processloader = true;
    //           // this.heardertextsearch = true;
    //           // this.disablescrolling = true;
    //           // this.disablescrollfilter = true;
    //           // this.disablescrolling_advancedsearch = true;
    //           // this.load_advanced = false;
    //         } else {
    //           console.log("ERR");
    //           // this.processloader = true;
    //           // this.heardertextsearch = true;
    //           // this.disablescrolling = true;
    //           // this.disablescrollfilter = true;
    //           this.disablescrolling_advancedsearch = true;
    //           this.loaddataprogress = false;
    //           // this.load_advanced = false;
    //           // this.$emit("advancesearch", response);
    //         }
        
    //       });
    //   } else {
    //     payload = {
    //       account_id: this.dataAccountId,
    //       cre_dtm: this.data_search.cre_dtm,
    //       to_dtm: this.data_search.to_dtm,
    //       type_search: this.data_search.type_search,
    //       name: this.data_search.name,
    //       cctv:this.data_search.cctv,
    //       folder_id: this.data_search.folder_id,
    //       dept_id: this.data_search.dept_id,
    //       limit: cal_limit,
    //       offset: cal_offset,
    //     };
    //     console.log("fake payload", payload);
    //     // this.processdelete = true;
    //     let auth = await gbfGenerate.generateToken();
    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //           `/api/v2/advanced_search`,
    //         payload,
    //         {
    //           headers: { Authorization: auth.code },
    //         }
    //       )
    //       .then(async (response) => {
    //         console.log("advance search", response.data.data);
    //         if (response.data.status === "OK") {
    //           this.loaddataprogress = false;
    //           // this.fn_advancesearch_loop(response);
    //           for(let i=0; i < response.data.data.length; i++){
    //             let datafolder = {};
    //             let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
    //               datafolder["account_reciever"] = "";
    //               datafolder["account_sender"] = this.dataAccountId;
    //               if ("file_id" in response.data.data) {
    //                 datafolder["file_id"] = response.data.data[i]["file_id"];
    //               } else {
    //                 datafolder["file_id"] = response.data.data[i]["id"];
    //               }
    //               datafolder["file_name"] = response.data.data[i]["name"];
    //               datafolder["file_size"] = response.data.data[i]["size"];
    //               datafolder["file_status"] = response.data.data[i]["status"];
    //               datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
    //               datafolder["file_owner_th"] = response.data.data[i]["name_th"];
    //               datafolder["file_linkshare"] = "";
    //               datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
    //               datafolder["file_date"] = "";
    //               datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
    //               datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
    //               if ("permission_account" in response.data.data) {
    //                 datafolder["file_permission"] = response.data.data[i]["permission_account"];
    //               } else {
    //                 datafolder["file_permission"] = {
    //                   admin_data: "False",
    //                   delete: "False",
    //                   download: "False",
    //                   edit: "False",
    //                   upload: "False",
    //                   view_only: "True",
    //                 };
    //               }

    //               datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
    //               datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
    //               datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
    //               datafolder["file_status_share"] = response.data.data[i]["status_share"];
    //               datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
    //               datafolder["file_type"] = response.data.data[i]["data_type"];
    //               datafolder["data_type"] = typefile[typefile.length - 1];
    //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
    //               datafolder["file_department"] = "";
    //               datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
    //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
    //               datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
    //               datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
    //               datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
    //               datafolder["file_thumbnail"] = "";
    //               datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
    //               datafolder["editing_status"] = response.data.data[i]["editing_status"];
    //               datafolder["update_by"] = response.data.data[i]["update_by"];
    //               datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
    //               datafolder["version_tag"] = response.data.data[i]["version_tag"];
    //               datafolder["tag_id"] = response.data.data[i]["tag_id"];
    //               datafolder["hashtag"] = response.data.data[i]["hashtag"];
    //               datafolder["status_expired"] = response.data.data[i]["status_expired"];
    //               datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
    //               datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

    //               this.rootfile.push(datafolder);
    //               this.buff.push(datafolder);
                
    //           }

    //           for (let index = 0; index < this.rootfile.length; index++) {
    //             const element = this.rootfile[index];
    //             element.file_thumbnail = "";
    //             let typeFile = element.file_name.split(".");
    //             await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                
    //             if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
    //               await this.get_name_owner(this.rootfile[index])
    //             }
    //           }
    //           console.log("this.count_data_adv_",this.count_data_adv_);
    //           this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    //           console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
    //           console.log("this.rootfile",this.rootfile);
    //           // this.processloader = true;
    //           // this.heardertextsearch = true;
    //           // this.disablescrolling = true;
    //           // this.disablescrollfilter = true;
    //           // this.disablescrolling_advancedsearch = true;
    //           // this.load_advanced = false;
    //         } else {
    //           console.log("ERR");
    //           // this.processloader = true;
    //           // this.heardertextsearch = true;
    //           // this.disablescrolling = true;
    //           // this.disablescrollfilter = true;
    //           this.disablescrolling_advancedsearch = true;
    //           this.loaddataprogress = false;
    //           // this.load_advanced = false;
    //         }
    //       });
    //   }
    // }
    },
    looplistdatarootfile(){
      this.loaddataprogress = false;
      // console.log('this.index_loop1',this.index_loop);
      if (this.changeUI) {
        this.size = 30;
      }
      let cal_limit = this.size;
      let cal_offset = this.rootfile.filter((item) => { 
        return item.file_status !== 'M';
      }).length;

      let count_push = 0;
      for(let i = cal_offset; i < this.count_data_adv_; i++){
        if (count_push >= cal_limit) break;
        // console.log('this.index_loop2',this.index_loop);
        this.rootfile.push(this.listdatarootfile[i])
        this.buff.push(this.listdatarootfile[i])
        const element = this.rootfile[i];
        element.file_thumbnail = "";
        let typeFile = element.file_name.split(".");
        this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
        if(this.rootfile[i].file_owner_th === '' || this.rootfile[i].file_owner_eng === ''){
          this.get_name_owner(this.rootfile[i])
        }
        count_push += 1;        
      }
      console.log('this.rootfile',this.rootfile);

      // if(this.num_count < this.count_data_adv_ ){
      //     this.num_count += 30;
      //   }
      // for (let index = 0; index < this.rootfile.length; index++) {
      // const element = this.rootfile[index];
      // element.file_thumbnail = "";
      // let typeFile = element.file_name.split(".");
      // await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
      
      // if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
      //   await this.get_name_owner(this.rootfile[index])
      // }
      // }
       this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    },
    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      // console.log("auth",auth);
      // console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    // โหลดข้อมูล โฟลเดอร์
    async loadfolder() {
      this.removeEvent();

      this.addEvent();
      this.rootfile = [];
      this.buff = [];

      if (this.dataAccountActive["type"] === "Business") {
        console.log("Business Login => ", this.dataAccountActive);
        // let datafolder = {};
        // datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
        // datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
        //   this.$store.state.account_active["type"],
        //   this.$t("default")
        // );
        // datafolder["file_lastdtm"] = "-";
        // datafolder["file_owner_eng"] = "-";
        // datafolder["file_owner_th"] = "-";
        // datafolder["file_size"] = "-";
        // datafolder["file_department"] = "";
        // datafolder["file_status"] = "M";
        // datafolder["file_type"] = "folder";
        // datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
        // this.rootfile.push(datafolder);
        // this.buff.push(datafolder);
      }
      if (this.clickSort === true) {
        this.rootfile.push(this.sortFile(this.rootfile));
      }
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_folder: "",
      };
      // console.log(payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/v2/search_folder_byid",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("Apifolder", response);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
            this.permission_currentfolder =
              response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            // console.log("api aspect data", this.aspectData);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            let orderfolder = response.data.folder;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] =
                response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] =
                response.data.folder[i]["status_share_link"];
              datafolder["file_size"] = "-";
              datafolder["file_date"] = "";
              datafolder["status_lock_folder"] =
                response.data.folder[i]["status_lock_folder"];
              datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"];
              datafolder["file_department"] = "";
              datafolder["file_owner_eng"] =
                response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["parent_folder_id"] =
                response.data.folder[i]["parent_folder_id"];
              datafolder["file_permission"] =
                response.data.folder[i]["permission_account"];
              datafolder["file_permission_2"] =
                response.data.folder[i]["permission_account_v2"];
              datafolder["permission_department_setting"] =
                response.data.folder[i]["permission_department_setting"];
              datafolder["permission_setting"] =
                response.data.folder[i]["permission_setting"];
              datafolder["file_status"] = response.data.folder[i]["status"];
              datafolder["file_type"] = "folder";
              // datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_icon"] = ["folder", "#ffc107"];
              datafolder["status_updating"] =
                response.data.folder[i]["status_updating"];
              datafolder["full_path"] = response.data.folder[i].full_path;
              datafolder["status_updating"] =
                response.data.folder[i].status_updating;
              datafolder["editing_status"] =
                response.data.data[i]["editing_status"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            // console.log("rootfoldermydrive",this.rootfile);
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loadfiles(this.account_active["directory_id"], "");
            this.countfolder = response.data.folder.length;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    // โหลดข้อมูลไฟล์
    async loadfiles(folder, status) {
      console.log("dd", this.dataAccountActive);
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder,
        status_file: status,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("apifile", response);
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              // let typefile = response.data.data[i]["filename"].split(".");
              let typefile = response.data.data[i].data_type === "folder" ? "folder" : response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["file_linkshare"] = response.data.data[i]["link"];
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["status_lock_file"] =
                response.data.data[i]["status_lock_file"];
              datafolder["file_date"] = "";
              datafolder["file_permission"] =
                response.data.data[i]["permission_account"];
              datafolder["file_permission_2"] =
                response.data.data[i]["permission_account_v2"];
              datafolder["permission_department_setting"] =
                response.data.data[i]["permission_department_setting"];
              datafolder["permission_setting"] =
                response.data.data[i]["permission_setting"];
              datafolder["file_status_share"] =
                response.data.data[i]["status_share"];
              datafolder["file_status_sharelink"] =
                response.data.data[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["status_updating"] =
                response.data.data[i]["status_updating"];
              // datafolder[
              //   "file_icon"
              // ] = gb_setIconFiles.iconExtensionFilesOnly_new(
              //   typefile[typefile.length - 1]
              // );
              datafolder[
                "file_icon"
              ] = this.fn_setThumbnailIcon(
                typefile[typefile.length - 1]
              );
              datafolder["full_path"] = response.data.data[i].full_path;
              datafolder["status_updating"] =
                response.data.data[i].status_updating;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            console.log("rootfilemydeive->", this.rootfile);
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loaddataprogress = false;
            this.countfile = response.data.data.length;
            console.log("this.countfile-*-*-*-*", this.countfile);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    //แปลงขนาดไฟล์
    fn_calculatesize(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },

    //โหลดจำนวน Storage ที่ใช้ไปทั้งหมด
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
      this.$store
        .dispatch("check_color", localStorage.getItem("color"))
        .then((res) => {});
    },

    changepage() {
      // this.loaddataprogress = true;
      // setTimeout(() => {
      //   this.loaddataprogress = false;
      // }, 1000);
    },

    async downloadfile(currentfile) {
      if (currentfile.file_type === "folder") {
        // Toast.fire({
        //   icon: "warning",
        //   title: "Coming Soon !!"
        // });
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v2/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" +
          currentfile.file_id;

        const link = document.createElement("a");
        link.setAttribute("href", url); //or any other extension
        link.setAttribute("download", currentfile.file_name + ".zip"); //or any other extension
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          // Cleanup the DOM
          document.body.removeChild(link);
        }, 500);
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id +
          "&account_id=" + this.dataAccountId;
        // saveAs(link,currentfile.file_name)
        // download(link,currentfile.file_name, "application/pdf")

        // const link = document.createElement("a");
        // link.setAttribute("href", url); //or any other extension
        // link.setAttribute("download", currentfile.file_name); //or any other extension
        // document.body.appendChild(link);
        // // document.body.removeChild(link);
        // setTimeout(() => {
        //   link.click();
        //   // Cleanup the DOM
        //   document.body.removeChild(link);
        // }, 500);
        let auth = await gbfGenerate.generateToken();

        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              let progresspercent = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
              // console.log(percentCompleted);
              // alert(percentCompleted)
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            console.log(percentCompleted);
            const blob = new Blob([response.data]);
            // const content = response.headers['content-type'];
            // saveAs(blob,currentfile.file_name)
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              // "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              title:
                this.$t("toast.cannotdownload") +
                currentfile.file_name +
                this.$t("toast.text.textth"),
            });
            console.log(error);
          });
      }

      // this.axios
      //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file", payload, {
      //     withCredentials: false,
      //     responseType: "blob",
      //   })
      //   .then(response => {
      //       const blob = new Blob([response.data]);
      //       const content = response.headers['content-type'];
      //       saveAs(blob,currentfile.file_name)
      //   })
      //   .catch(error => {
      //     Toast.fire({
      //       icon: "error",
      //       title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
      //     });
      //     console.log(error);
      //   });
      // }
    },
    async fn_loadfilelastview() {
      var payload = {
        starred_id: [this.starred_id],
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: 0,
        offset: 5,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder/last_view",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.filesLastView = [];
            for (let i = 0; i < response.data.data_last_view.length; i++) {
              // console.log("response++++++", response.data.data_last_view);
              let file_lastview = {};
              // let typefile = response.data.data_last_view[i].name.split(".");
              let typefile = response.data.data_last_view[i].data_type === "folder" ? "folder" : response.data.data_last_view[i].name.split(".");
              let type = response.data.data_last_view[i].types;

              file_lastview["account_reciever"] = "";
              file_lastview["account_sender"] = this.dataAccountId;
              file_lastview["account_id"] =
                response.data.data_last_view[i].account_id;
              file_lastview["countnum"] =
                response.data.data_last_view[i].countnum;
              file_lastview["file_createdtm"] =
                response.data.data_last_view[i].cre_dtm;
              file_lastview["folder_id"] =
                response.data.data_last_view[i].folder_id;
              file_lastview["file_id"] = response.data.data_last_view[i].id;
              file_lastview["file_lastdtm"] =
                response.data.data_last_view[i].last_dtm;
              file_lastview["file_linkshare"] =
                response.data.data_last_view[i].link;
              file_lastview["file_name"] = response.data.data_last_view[i].name;
              file_lastview["file_owner_eng"] =
                response.data.data_last_view[i].name_eng;
              file_lastview["file_owner_th"] =
                response.data.data_last_view[i].name_th;
              file_lastview["file_permission"] =
                response.data.data_last_view[i].permission_account;
              file_lastview["file_permission_2"] =
                response.data.data_last_view[i].permission_account_v2;
              file_lastview["permission_department_setting"] =
                response.data.data_last_view[i].permission_setting;
              file_lastview["permission_role_setting"] =
                response.data.data_last_view[i].permission_role_setting;
              file_lastview["priority"] =
                response.data.data_last_view[i].priority;
              file_lastview["file_size"] = response.data.data_last_view[i].size;
              file_lastview["status_lock_data"] =
                response.data.data_last_view[i].status_lock_data;
              file_lastview["status_lock_file"] =
                response.data.data_last_view[i].status_lock_file;
              file_lastview["file_status"] =
                response.data.data_last_view[i].status;
              file_lastview["file_status_share"] =
                response.data.data_last_view[i].status_share;
              file_lastview["file_status_sharelink"] =
                response.data.data_last_view[i].status_share_link;
              file_lastview["file_department"] = "";
              file_lastview["system"] = response.data.data_last_view[i].system;
              file_lastview["type"] = response.data.data_last_view[i].types;
              file_lastview["status_expired"] =
                response.data.data_last_view[i].status_expired;
              file_lastview["expiration_time"] =
                response.data.data_last_view[i].expiration_time;
              // file_lastview[
              //   "file_icon"
              // ] = gb_setIconFiles.iconExtensionFilesFolder_new(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              file_lastview[
                "file_icon"
              ] = this.fn_setThumbnailIcon(
                typefile[typefile.length - 1]
              );
              file_lastview["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              file_lastview["user_id"] =
                response.data.data_last_view[i].user_id;
              file_lastview["file_thumbnail"] = "";
              file_lastview["file_thumbnail_icon"] = this.fn_setThumbnailIcon(
                typefile[typefile.length - 1]
              );
              file_lastview["editing_status"] =
                response.data.data_last_view[i].editing_status;
              file_lastview["update_by"] =
                response.data.data_last_view[i].update_by;
              file_lastview["last_update_name"] =
                response.data.data_last_view[i].last_update_name;
              file_lastview["warning_expiration_time"] =
                response.data.data_last_view[i].warning_expiration_time;
              file_lastview["status_encrypt"] =
                response.data.data_last_view[i].status_encrypt;
              file_lastview["starred_id"] =
                response.data.data_last_view[i].starred_id;

              // file_lastview["hashtag"] = response.data.data_last_view[i].hashtag;

              this.filesLastView.push(file_lastview);
              this.fn_loadthumbnail(
                response.data.data_last_view[i].id,
                typefile[typefile.length - 1],
                response.data.data_last_view[i].size,
                response.data.data_last_view[i].status_lock_data
              );
            }
          }
        });
    },
    // ดึงข้อมูลโฟลเดอร์และไฟล์ แบบ Scroll โดยจะดึงแบบ limit offset
    async fn_loadfileandfolder_scrolling() {
      //console.log("jjj",this.$route);
      this.disablescrolling_advancedsearch = true;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.rootfile.filter((item) => { 
        return item.file_status !== 'M';
      }).length;
      // let cal_offset = this.size * (this.page++ - 1);
      console.log("cal_offset", cal_offset);
      console.log("cal_limit", cal_limit);
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        // if (cal_offset == 0) {
        //   let datafolder = {};
        //   datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
        //   datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
        //     this.$store.state.account_active["type"],
        //     this.$t("default")
        //   );
        //   datafolder["file_lastdtm"] = "-";
        //   datafolder["file_owner_eng"] = "-";
        //   datafolder["file_owner_th"] = "-";
        //   datafolder["file_size"] = "-";
        //   datafolder["file_department"] = "";
        //   datafolder["file_status"] = "M";
        //   datafolder["file_type"] = "folder";
        //   datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
        //   this.rootfile.push(datafolder);
        //   this.buff.push(datafolder);
        // }
      }

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            this.fn_checkevaluate();
            this.fn_loadfilelastview();

            //console.log("File last view", this.filesLastView);
            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              // let typefile = response.data.data[i].name.split(".");
              let typefile = response.data.data[i].data_type === "folder" ? "folder" : response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id;
              // console.log("full_path_id your folder",response.data.your_folder[0].full_path_id);
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path === this.dataAccountActive.directory_id
                    ? {
                        admin_data: "False",
                        delete: "True",
                        download: "True",
                        edit: "True",
                        upload: "True",
                        view_only: "True",
                      }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              dataFileAndFolder["file_permission_2"] =
                response.data.data[i].permission_account_v2;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_business_setting"] =
                response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              // dataFileAndFolder[
              //   "file_icon"
              // ] = gb_setIconFiles.iconExtensionFilesFolder_new(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder[
                "file_icon"
              ] = this.fn_setThumbnailIcon(
                typefile[typefile.length - 1]
              );
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["data_type"] = typefile[typefile.length - 1];
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] =
                response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_data"] =
                response.data.data[i]["status_lock_data"];
              dataFileAndFolder["version_tag"] =
                response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["status_expired"] =
                response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] =
                response.data.data[i]["expiration_time"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder[
                "file_thumbnail_icon"
              ] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] =
                response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] =
                response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] =
                response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] =
                response.data.data[i]["warning_expiration_time"];
              dataFileAndFolder["status_encrypt"] =
                response.data.data[i]["status_encrypt"];
              dataFileAndFolder["starred_id"] =
                response.data.data[i].starred_id;
              dataFileAndFolder["last_view"] = response.data.data[i].last_view;
              dataFileAndFolder["taxbox_status"] = response.data.data[i].taxbox_status;

              // this.taxbox_status = response.data.data[i].taxbox_status;

              this.rootfile.push(dataFileAndFolder);
              this.buff.push(dataFileAndFolder);
              this.fn_loadthumbnail(
                response.data.data[i].id,
                typefile[typefile.length - 1],
                response.data.data[i].size,
                response.data.data[i].status_lock_data
              );
            }
            this.sortCreDtmNewUpload();
            // console.log("root",this.rootfile);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder =
              response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            console.log("this.departmentid", this.departmentid);
            // if(VueCookies.get("check_dialog_mohpromt") === "false" || VueCookies.get("check_dialog_mohpromt") === null ){
            //   if(VueCookies.get("check_mohpromt") === "true"){
            //     console.log("check_mohpromt === true");
            //     this.opendialogconsentgetMed = true;
            //   }
            // }
            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ ในกรณีที่ต้องการดึงใหม่ตั้งแต่ต้น ใช้สำหรับให้ child component เรียกข้อมูล
    async fn_loadfileandfolder_scrolling_child(options={}) {
      this.disablescrolling_advancedsearch = true;
      this.show_menumanage = false
      this.isCheckbox_sep_header= false
      this.filemultipledownload = []
      this.status_animation = ""
      this.close_header = true
      localStorage.setItem("scrollHistory", 0);
      this.heardertextsearch = false;
      console.log("fn_loadfileandfolder_scrolling_child  first load");
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.rootfile = [];
    
      this.page = 1;
      let {
        cal_limit = this.size,
        cal_offset = this.rootfile.filter((item) => { 
          return item.file_status !== 'M'
        }).length,
      } = options
      // let cal_offset = this.size * (this.page++ - 1);

      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        // if (cal_offset == 0) {
        //   let datafolder = {};
        //   datafolder["file_id"] = this.dataAccountActive["business_info"]["my_folder_id"];
        //   datafolder["file_name"] = this.$store.getters.dataTypeusercurrent(
        //     this.$store.state.account_active["type"],
        //     this.$t("default")
        //   );
        //   datafolder["file_lastdtm"] = "-";
        //   datafolder["file_owner_eng"] = "-";
        //   datafolder["file_owner_th"] = "-";
        //   datafolder["file_size"] = "-";
        //   datafolder["file_department"] = "";
        //   datafolder["file_status"] = "M";
        //   datafolder["file_type"] = "folder";
        //   datafolder["file_icon"] = ["mdi-folder-network", "blue darken-4"];
        //   this.rootfile.push(datafolder);
        //   this.buff.push(datafolder);
        // }
      }

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          this.loaddataprogress = false;
          console.log("response myfile", response);
          if (response.data.status === "OK") {
            this.fn_checkevaluate();
            this.permission_currentfolder =
              response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.fn_loadfilelastview();
            // this.filesLastView = [];
            // for (let i = 0; i < response.data.data_last_view.length; i++) {
            //   let file_lastview = {};
            //   let typefile = response.data.data_last_view[i].name.split(".");
            //   let type = response.data.data_last_view[i].data_type;

            //   file_lastview["account_reciever"] = "";
            //   file_lastview["account_sender"] = this.dataAccountId;
            //   file_lastview["account_id"] = response.data.data_last_view[i].account_id;
            //   file_lastview["countnum"] = response.data.data_last_view[i].countnum;
            //   file_lastview["file_createdtm"] = response.data.data_last_view[i].cre_dtm;
            //   file_lastview["folder_id"] = response.data.data_last_view[i].folder_id;
            //   file_lastview["file_id"] = response.data.data_last_view[i].id;
            //   file_lastview["file_lastdtm"] = response.data.data_last_view[i].last_dtm;
            //   file_lastview["file_linkshare"] = response.data.data_last_view[i].link;
            //   file_lastview["file_name"] = response.data.data_last_view[i].name;
            //   file_lastview["file_owner_eng"] = response.data.data_last_view[i].name_eng;
            //   file_lastview["file_owner_th"] = response.data.data_last_view[i].name_th;
            //   file_lastview["file_permission"] = response.data.data_last_view[i].permission_account;
            //   file_lastview["permission_department_setting"] = response.data.data_last_view[i].permission_setting;
            //   file_lastview["priority"] = response.data.data_last_view[i].priority;
            //   file_lastview["file_size"] = response.data.data_last_view[i].size;
            //   file_lastview["file_status"] = response.data.data_last_view[i].status;
            //   file_lastview["status_lock_data"] = response.data.data_last_view[i].status_lock_data;
            //   file_lastview["file_status_share"] = response.data.data_last_view[i].status_share;
            //   file_lastview["file_status_sharelink"] = response.data.data_last_view[i].status_share_link;
            //   file_lastview["system"] = response.data.data_last_view[i].system;
            //   file_lastview["type"] = response.data.data_last_view[i].types;
            //   file_lastview["file_department"] = "";
            //   file_lastview["status_expired"] = response.data.data_last_view[i].status_expired;
            //   file_lastview["expiration_time"] = response.data.data_last_view[i].expiration_time;
            //   file_lastview["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder(type, typefile[typefile.length - 1]);
            //   file_lastview["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
            //   file_lastview["user_id"] = response.data.data_last_view[i].user_id;
            //   file_lastview["file_thumbnail"] = "";
            //   file_lastview["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
            //   file_lastview["editing_status"] = response.data.data_last_view[i].editing_status;
            //   file_lastview["update_by"] = response.data.data_last_view[i].update_by;
            //   file_lastview["last_update_name"] = response.data.data_last_view[i].last_update_name;

            //   // file_lastview["hashtag"] = response.data.data_last_view[i].hashtag;
            //   this.filesLastView.push(file_lastview);
            //   this.fn_loadthumbnail(response.data.data_last_view[i].id, typefile[typefile.length - 1]);
            // }
            // console.log("first load last view", this.filesLastView);
            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].data_type === "folder" ? "folder" : response.data.data[i].name.split(".");
              // let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id;

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path === this.dataAccountActive.directory_id
                    ? {
                        admin_data: "False",
                        delete: "True",
                        download: "True",
                        edit: "True",
                        upload: "True",
                        view_only: "True",
                      }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              dataFileAndFolder["file_permission_2"] =
                response.data.data[i].permission_account_v2;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_business_setting"] =
                response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;

              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].types;
              // dataFileAndFolder[
              //   "file_icon"
              // ] = gb_setIconFiles.iconExtensionFilesFolder_new(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder[
                "file_icon"
              ] = this.fn_setThumbnailIcon(
                typefile[typefile.length - 1]
              );
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["data_type"] = typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] =
                response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_data"] =
                response.data.data[i]["status_lock_data"];
              dataFileAndFolder["status_expired"] =
                response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] =
                response.data.data[i]["expiration_time"];
              dataFileAndFolder["version_tag"] =
                response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder[
                "file_thumbnail_icon"
              ] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] =
                response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] =
                response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] =
                response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] =
                response.data.data[i]["warning_expiration_time"];
              dataFileAndFolder["status_encrypt"] =
                response.data.data[i]["status_encrypt"];
              dataFileAndFolder["starred_id"] =
                response.data.data[i].starred_id;
              dataFileAndFolder["last_view"] = response.data.data[i].last_view;
              dataFileAndFolder["taxbox_status"] = response.data.data[i].taxbox_status;

              // this.taxbox_status = response.data.data[i].taxbox_status;

              this.rootfile.push(dataFileAndFolder);
              this.buff.push(dataFileAndFolder);
              this.fn_loadthumbnail(
                response.data.data[i].id,
                typefile[typefile.length - 1],
                response.data.data[i].size,
                response.data.data[i].status_lock_data
              );
            }
            this.sortCreDtmNewUpload();
            console.log("this.rootfile", this.rootfile);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder =
              response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            console.log("this.departmentid", this.departmentid);
            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      console.log("Finished Scrolling ")
    },
    keyup(event) {
      if (event.getModifierState("CapsLock")) {
        console.log("CapsLock");
      } else {
        console.log("UnCapsLock");
      }
    },
    addEventcopypaste() {
      window.addEventListener("keypress", this.fn_checkkeyevent, true);
    },
    removeEventcopypaste() {
      window.removeEventListener("keypress", this.fn_checkkeyevent, true);
    },
    // Add new
    onScroll(e) {
      this.scrollY = e.target.scrollTop;
      if (this.scrollY !== 0)
        localStorage.setItem("scrollHistory", this.scrollY);
    },
    animatemenumanage (valueScroll, from, to) {
      if(this.statusmutipledownload === true){
        if(valueScroll > from){
          this.close_header = false
          this.show_menumanage = true
          this.status_animation = "slideUp"
        } else if (valueScroll < to) {
          this.close_header = true
          this.show_menumanage = false 
        }
      }
    },
    // 
    selectCardBgColor(item) {
      return this.selectColor(item.file_id, "card")
        ? "opacity: 0.3"
        : "opacity: unset";
    },
    fn_reload(){
      this.pagee = 1,
      this.heardertextsearch = false;
      this.isAdvancedSearch = false;
    },
    fn_newsearch(){
      this.pagee = 1;
    },
    selectItemTable(file_id) {
      return this.selectColor(file_id, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    setSelectitemtable(file) {
      if (file.file_id) {
        if (this.statusmutipledownload === false) {
          this.filemultipledownload = [];
          if (file.status_lock_data === "N") {
            this.filemultipledownload = [file]
          }
        }
      }
    },
    fn_checkevaluate(){
      let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

      let payload = {
        one_id: one_id
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
          "/api/get_user_evaluate",
          payload,
          // {
          //   headers: { Authorization: auth.code },
          // }
        )
        .then((response) => {
          console.log("response",response);
          if (response.data.status === "OK") {
            this.statusevaluation = response.data.data.status_evaluate
            this.eva_expired = response.data.data.evaluate_expired
             if(this.statusevaluation === "N"){
              if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }else if(this.statusevaluation === "W"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                // this.opendialog_eva = false
                this.icon_eva = true
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }else if(this.statusevaluation === "Y"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                // this.opendialog_eva = false
                this.icon_eva = false
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }
            //  console.log("btn myfile",this.icon_eva);
             VueCookies.set("iconeva",this.icon_eva)
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    }
  },

  mounted() {
    this.fn_checkevaluate();
    // if (VueCookies.get("check_btn_login_new") === null) {
    //   if (VueCookies.get("checklogin") === null) {
    //     if (sessionStorage.getItem("checklogin") === null) {
    //       this.opendialogcheckrememberlogin = true;
    //     }
    //   }
    // }
    this.removeEvent();
    this.addEvent();
    
    if (VueCookies.get("check_mohpromt") == "true") {
      console.log("เข้าcheck");
      // this.fn_check_consentgetdata_MED();
    }

    if (localStorage.getItem("changeStoreUI") === "true") {
      this.size = 30;
    } else {
      if (localStorage.getItem("limitLoadFileFolder")) {
        this.size = parseInt(localStorage.getItem("limitLoadFileFolder"));
      }
    }

    // this.removeEventcopypaste();
    // this.addEventcopypaste();

    this.loaddataprogress = false;
    this.$store.dispatch("department_access_id", "");
    this.loadstorage();
    this.disablescrolling = false;
    // this.loadfolder();
    // this.loadfileandfolder();
    // localStorage.setItem("changeStoreUI", "true");
    // this.loadcolor();
    // เช็คเปิด dialog ตั้งค่าบริษัทเริ่มต้น
    if(this.setting_select_biz === true){
      // console.log("open_setting_select_biz = open");
      this.open_setting_select_biz = true;
    }else{
      // console.log("open_setting_select_biz = close");
      this.open_setting_select_biz = false;
    }
  },
  //ดักเมื่อมีการกด ctrl+c และ ctrl+v  
  created() {
    // this.removeEventcopypaste();
    // this.addEventcopypaste();
    // window.addEventListener('keypress', this.fn_checkkeyevent);
    // this.listcopy = this.filemultipledownload;
    //this.addEvent();
  },
  beforeDestroy() {
    //this.removeEvent();
    this.removeevent = true;
    this.removeEventcopypaste();
  },
};
</script>

<style scoped>
::v-deep .custom-table .v-data-table__empty-wrapper {
  display: none;
}
span.name-location {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap; 
  width: 100px; 
  text-align: left;
}
span.path-name-location {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap; 
  width: 100%;
  max-width: 150px;
  text-align: left;
}
.breadcrumbs-location::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.menu-content {
  right: 9% !important;
  left: auto !important;
  margin-top: -8px;
}
.tooltip-content {
  height: 25px;
  display: flex;
  align-items: center;
}
</style>
<style>
/* Add new   */
.custom-table{
  border-radius: 0px;
}
.animated-listmenu{
  transform: translateY(30px);
}
.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: .5s;	
	-webkit-animation-duration: .5s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}



@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	/* 50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			 */
	100% {
		-webkit-transform: translateY(0%);
	}	
}
.drag-selector {
  padding: 10px;
}
.drag-selector__item {
  align-items: center;
  /* padding: -1px; */
  /* height: 40px;
  padding-left: 16px;
  vertical-align: middle;
  display: flex;
  margin: 5px; */
  /* background-color: rebeccapurple; */
}

/* .drag-selector__item.selected { */
/* outline-offset: 4px;
  outline-width: 2px;
  outline-style: double;
  outline-color: rgb(28, 117, 235); */
/* } */
/* #cardItem {
  background-color: rebeccapurple;
} */
template td {
  padding: 0px;
  margin: 0px;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  display: none;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

/* #thiscontainer::-webkit-scrollbar {
width: 30em;
display: inline !important; 
} */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #thiscontainer_myfile {
  height: calc(100vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}
/* ของใหม่ๆ */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #thiscontainer_myfile {
  height: calc(100vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #thiscontainer_myfile {
  height: calc(100vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #thiscontainer_myfile {
  height: calc(100vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #thiscontainer_myfile {
    height: calc(100vh - 230px);
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow-x: hidden;
    /* max-width: 100%; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
}
/* ///////////////// */
/* ของเก่า */
/* #thiscontainer_myfile {
  height: calc(100vh - 180px);
  height: calc(var(--vh, 1vh) * 100);// เม้นตัวนนี้
  overflow-x: hidden;
  max-width: 100%; //เม้นตัวนี้
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
} */
.pointer {
  cursor: pointer;
}

.v-progress-circular {
  margin: 1rem;
}
#slideritem:hover {
  background-color: #d6eaf8;
  border-radius: 5px;
}
#borderradius {
  border-radius: 15px;
}
#borderradius-img {
  border-radius: 15px 15px 0px 0px;
}
#borderradius-word {
  border-radius: 0px 0px 15px 15px;
}
#borderradius_btn {
  border-radius: 5px;
}
#mytable table thead tr th:nth-child(1) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(2) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(3) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(4) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(5) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(6) {
  background: #fafafa;
}
#mytable table thead tr th:nth-child(7) {
  background: #fafafa;
}
div#borderradius-img {
  height: 145px;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #1e1e1e !important;
}
</style>
